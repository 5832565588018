import { ajax } from '@/utils/request'
import { debounce, throttle } from 'lodash'
import { Option } from './types'
import { formatSBJson } from './utils'

export const checkDataPermission = (projectId: string) => {
  return ajax({
    url: '/transactionPe/checkIsHaveDataRights',
    params: {
      projectId,
    },
  })
}

export const fetchAfterListByBeforeId = (id: string) => {
  return ajax({
    url: '/investedCompany/queryProjectByBeforeId',
    params: {
      id,
    },
  })
}

export const fetchByFullName = (data: { companyFullName: string; assetTypeId: string }) => {
  return ajax({
    url: '/investedCompany/queryByFullOrSimpleNameWithPermission',
    method: 'post',
    data,
  })
}

export const fetchAfterInfoById = (id: string) => {
  return ajax({
    url: '/investedCompany/queryShortInfo',
    params: {
      id,
    },
  })
}

export const checkDateAndCurrency = (data: {
  dataId: string
  relateAfterId: string
}): Promise<Record<string, string>> => {
  return ajax({
    method: 'post',
    url: '/investedCompany/checkAll',
    data,
  })
}

export const relateAfterProject = (data: { id: string; relateAfterId: string | null }) => {
  return ajax({
    url: '/beforeProject/relateAfterProject',
    method: 'post',
    data,
  })
}

export const checkCoinInvestDuplicate = (data: { projectId: string; investDate: number }) => {
  return ajax({
    url: '/transactionCoin/checkPreInvestDateIsUnique',
    method: 'post',
    data: {
      ...data,
      investDate: new Date(data.investDate).setHours(23, 59, 59, 999),
    },
  })
}

export const checkFofInvestDuplicate = (data: { projectId: string; investDate: number }) => {
  return ajax({
    url: '/transactionFof/checkPreInvestDateIsUnique',
    method: 'post',
    data: {
      ...data,
      investDate: new Date(data.investDate).setHours(23, 59, 59, 999),
    },
  })
}

export const judgeFofFirstRecord = (params: { projectId: string }) => {
  return ajax({
    url: '/transactionFof/queryIsFirstAdd',
    params,
  })
}

export const judgeFirstRecord = (params: { projectId: string }) => {
  return ajax({
    url: '/transactionCoin/queryIsFirstAdd',
    params,
  })
}

export const judgeEquityFirstRecord = (params: { projectId: string }, isBefore = false) => {
  return ajax({
    url: isBefore ? '/transactionEip/queryIsFirstAdd' : '/transactionPe/queryIsFirstAdd',
    params,
  })
}

export const fetchIPOInfoByDate = (data: {
  ticker: string,
  tradingDate: string,
  currency: string,
}) => {
  return ajax({
    url: '/secInfo/queryTotalSharesAndClosePrice',
    method: 'post',
    data: {
      ...data,
      tradingDate: new Date(Number(data.tradingDate)).setHours(0,0,0,0).toString()
    },
  })
}

export const fetchEquityFundList = async ({
  projectId,
  id,
  isBefore,
  investDate,
  relateAfterId,
}: {
  projectId: string
  id?: string
  isBefore: boolean
  investDate: string | number
  relateAfterId?: string
}) => {
  const res = await ajax({
    url: isBefore ? '/transactionEip/queryFundList' : '/transactionPe/queryFundList',
    params: {
      projectId,
      id,
      investDate,
      relateAfterId,
    },
  })

  return (res || []).map(
    (item: { fundName: string; id: string; shareholdingRatio: string; currencyUnit: string }) => {
      return {
        label: item.fundName,
        value: item.id,
        shareholdingRatio: Number(item.shareholdingRatio ?? 0),
        currency: item.currencyUnit,
      }
    },
  ) as (Option & { shareholdingRatio: number })[]
}

export const fetchFofFundList = async (projectId: string, investDate: string | number, id?: string) => {
  const res = await ajax({
    url: `/transactionFof/queryFundList`,
    method: 'get',
    params: {
      projectId,
      investDate,
      id,
    },
  })

  return (res || []).map((item: { fundName: string; id: string; shareholdingRatio: string }) => {
    return {
      label: item.fundName,
      value: item.id,
      shareholdingRatio: Number(item.shareholdingRatio ?? 0),
      currency: item.currencyUnit,
      // hold: item.hold,
    }
  }) as (Option & { shareholdingRatio: number })[]
}

export const fetchCoinFundList = async (id: string) => {
  const res = await ajax({
    url: `/transactionCoin/queryFundList?projectId=${id}`,
    method: 'get',
  })

  return (res || []).map(
    (item: { fundName: string; id: string; currencyUnit: string; hold: number }) => {
      return {
        label: item.fundName,
        value: item.id,
        currency: item.currencyUnit,
        hold: item.hold,
      }
    },
  ) as (Option & { hold: number })[]
}

export const fetchSubFundTransactionList = (data: Record<string, any>) => {
  return ajax({
    url: `/transactionFof/queryList?projectId=${data.id}`,
    data,
  })
}
export const fetchCoinTransactionList = (data: Record<string, any>) => {
  return ajax({
    url: `/transactionCoin/queryList?projectId=${data.coinId}`,
    data,
  })
}
export const fetchTransactionList = (params: Record<string, unknown>, isBefore = false) => {
  return ajax({
    url: isBefore ? '/transactionEip/queryRelateList' : '/transactionPe/queryList',
    params,
  })
}

export const getTargetCurrencyNum = (data: { source: string; target: string; num: string }) => {
  return ajax({
    url: '/currency/calculateNum',
    method: 'post',
    data,
  }).then(res => {
    return res ? Number(res).toFixed(2) : null
  })
}

export const fetchBeforeFundInfo = (
  beforeProjectId: string,
  fundId: string,
  investDate: number,
  id?: string,
) => {
  return ajax({
    url: '/beforeProject/plan/queryBeforeNewestFundInfo',
    method: 'post',
    data: {
      beforeProjectId,
      fundId,
      investDate,
      id,
    },
  })
}

export const deleteFofRecord = (id: string) => {
  return ajax({
    url: '/transactionFof/delete',
    params: {
      id,
    },
  })
}

export const deleteCoinRecord = (id: string) => {
  return ajax({
    url: '/transactionCoin/delete',
    params: {
      id,
    },
  })
}

export const deleteTransactionRecord = (id: string, isBefore = false) => {
  return ajax({
    url: isBefore ? '/transactionEip/delete' : '/transactionPe/delete',
    params: {
      id,
    },
  })
}

// 删除前判断是否可删除
export const CanDeleteTransactionRecord = (id: string, isBefore = false) => {
  return ajax({
    url: isBefore ? '/transactionEip/canDelete' : '/transactionPe/canDelete',
    params: {
      id,
    },
  })
}

export const fetchFundHoldValue = (data: Record<string, unknown>) => {
  return ajax({
    url: '/transactionRecord/queryChangeHoldValue',
    method: 'post',
    data,
  })
}
export const fetchRegisterCapitalByDate = (data: Record<string, unknown>) => {
  return ajax({
    url: '/transactionRecord/queryChangeRegisterCapital',
    method: 'post',
    data,
  })
}
export const fetchShareholdingRatioByDate = (data: Record<string, unknown>) => {
  return ajax({
    url: '/transactionRecord/queryChangeShareholdingRatio',
    method: 'post',
    data,
  })
}
export const fetchInvestAmount = (data: Record<string, unknown>) => {
  return ajax({
    url: '/transactionRecord/querySumInvestAmount',
    method: 'post',
    data,
  })
}

export const fetchNewestValuation = (data: { projectId: string; investDate: number | string }) => {
  return ajax({
    url: '/transactionRecord/queryNewestValuation',
    method: 'post',
    data: {
      ...data,
      investDate: new Date(data.investDate).setHours(23, 59, 59, 999),
    },
  })
}

export const fetchFundNewestByDate = (data: any) => {
  return ajax({
    url: '/transactionRecord/queryBeforeNewestFundInfo',
    method: 'post',
    data: {
      ...data,
      investDate: new Date(data.investDate).setHours(23, 59, 59, 999),
    },
  })
}

export const fetchFundListByDate = async (data: {
  investDate: string | number
  projectId: string | number
  id: string | undefined
}) => {
  const res = await ajax({
    url: '/transactionRecord/queryBeforeUsedFunds',
    method: 'post',
    data: {
      ...data,
      investDate: new Date(data.investDate).setHours(23, 59, 59, 999),
    },
  })

  return (res?.values ?? []).map((item: { fundName: string; id: string; currencyUnit: string }) => {
    return {
      label: item.fundName,
      value: item.id,
      currency: item.currencyUnit,
    }
  }) as (Option & { currency: string })[]
}

export const fetchFundList = async () => {
  const res = await ajax({
    method: 'get',
    url: '/fund/queryFundList',
  })

  return (res || []).map((item: { fundName: string; id: string; currencyUnit: string }) => {
    return {
      label: item.fundName,
      value: item.id,
      currency: item.currencyUnit,
    }
  }) as Option[]
}

export const fetchAllFundList = async () => {
  const res = await ajax({
    method: 'get',
    url: '/fund/queryAllFundList',
  })

  return (res || []).map((item: { fundName: string; id: string; currencyUnit: string }) => {
    return {
      label: item.fundName,
      value: item.id,
      currency: item.currencyUnit,
    }
  }) as Option[]
}

export const fetchFofPreShares = (data: {
  id?: string
  investDate?: number
  projectId: string
}) => {
  return ajax({
    url: '/transactionFof/queryBeforeInvestInfo',
    method: 'post',
    data,
  })
}

export const fetchEquityLatestInfoByDate = (
  data: {
    projectId: string
    investDate: number
    id?: string
    relateAfterId?: string
  },
  isBefore = false,
) => {
  return ajax({
    url: isBefore
      ? '/transactionEip/queryBeforeInvestInfo'
      : '/transactionPe/queryBeforeInvestInfo',
    method: 'post',
    data,
  })
}

export const fetchEquityFundLatestInfo = (
  data: {
    projectId: string
    fundIds?: string[]
    id?: string
    investDate: number | string
    relateAfterId?: string
    targetCurrencyUnit?: string
  },
  isBefore = false,
) => {
  return ajax({
    url: isBefore
      ? '/transactionEip/queryBeforeInvestFundInfo'
      : '/transactionPe/queryBeforeInvestFundInfo',
    method: 'post',
    data: {
      ...data,
      investDate: new Date(data.investDate).setHours(23, 59, 59, 999),
    },
  })
}

export const fetchFofFundLatestInfo = (data: {
  projectId: string
  fundIds?: string[]
  id?: string
  investDate: number | string
  targetCurrencyUnit?: string
}) => {
  return ajax({
    url: '/transactionFof/queryBeforeInvestFundInfo',
    method: 'post',
    data: {
      ...data,
      investDate: new Date(data.investDate).setHours(23, 59, 59, 999),
    },
  }).then((res) => {
    return res.map((i: any) => {
      return {
        ...i,
        holdValuation: i.holdValuation ? formatSBJson(i.holdValuation) : undefined,
        // investAmount: i.investAmount ? formatSBJson(i.investAmount) : undefined,
        // exitAmount: i.exitAmount ? formatSBJson(i.exitAmount) : undefined,
        // holdValue: i.holdValue ? formatSBJson(i.holdValue) : undefined,
        // sumInvestAmount: i.sumInvestAmount ? formatSBJson(i.sumInvestAmount) : undefined,
      }
    })
  })
}
export const fetchCoinFundLatestInfo = (data: {
  projectId: string
  fundIds?: string[]
  id?: string
  investDate: number | string
  targetCurrencyUnit?: string
}) => {
  return ajax({
    url: '/transactionCoin/queryBeforeInvestFundInfo',
    method: 'post',
    data: {
      ...data,
      investDate: new Date(data.investDate).setHours(23, 59, 59, 999),
    },
  }).then((res) => {
    return res.map((i: any) => {
      return {
        ...i,
        investAmount: i.investAmount ? formatSBJson(i.investAmount) : undefined,
        exitAmount: i.exitAmount ? formatSBJson(i.exitAmount) : undefined,
        holdValue: i.holdValue ? formatSBJson(i.holdValue) : undefined,
        sumInvestAmount: i.sumInvestAmount ? formatSBJson(i.sumInvestAmount) : undefined,
      }
    })
  })
}

export const fetchFundLatestInfo = (data: {
  projectId: string
  fundIds?: string[]
  investDate: number | string
}) => {
  return ajax({
    url: '/transactionRecord/queryBeforeInvestFundInfo',
    method: 'post',
    data: {
      ...data,
      investDate: new Date(data.investDate).setHours(23, 59, 59, 999),
    },
  })
}

export const fetchAmountByRound = async (
  data: { projectId: string; financingRound: string; id?: string; targetCurrencyUnit: string },
  isBefore = false,
) => {
  const res: any = await ajax({
    url: isBefore
      ? '/transactionEip/queryInvestInfoByRound'
      : '/transactionPe/queryInvestInfoByRound',
    method: 'post',
    data,
  })

  return res
}

export const fetchNewestRegisterCapitalByDate = (data: Record<string, unknown>) => {
  return ajax({
    url: '/transactionRecord/queryNewestRegisterCapital',
    method: 'post',
    data,
  })
}

export const fetchNewestInfoByDate = (data: {
  id?: string
  investDate: number | string
  projectId: string
}) => {
  // const fetchValuation = ajax({
  //   url: '/transactionRecord/queryChangeCompanyValuation',
  //   method: 'post',
  //   data,
  // })
  const fetchRegisterCapital = ajax({
    url: '/transactionRecord/queryChangeCompanyRegist',
    method: 'post',
    data,
  })

  return Promise.all([fetchRegisterCapital]).then(([registerCapital]) => {
    return {
      registerCapital,
    }
  })
}

export const fetchInvestmentNewestInfo = async (projectId: string) => {
  return ajax({
    url: '/transactionRecord/queryNewestInvestment',
    method: 'post',
    data: {
      projectId,
    },
  })
}

export const exportSubFundTransactionList = (params: { projectId: string }) => {
  return ajax({
    url: '/transactionFof/export',
    method: 'get',
    params,
  })
}

export const exportCoinTransactionList = (params: { projectId: string }) => {
  return ajax({
    url: '/transactionCoin/export',
    method: 'get',
    params,
  })
}

export const exportTransactionList = async (
  params: { projectId: string; relateAfterId?: string },
  isBefore = false,
) => {
  return ajax({
    url: isBefore ? '/transactionEip/export' : '/transactionPe/export',
    params,
  })
}

export const addFofTransaction = throttle((data: Record<string, unknown>) => {
  return ajax({
    url: '/transactionFof/save',
    method: 'post',
    data: {
      ...data,
      investDate: data.investDate
        ? new Date(Number(data.investDate)).setHours(23, 59, 59, 999)
        : undefined, // 时间统一处理成 23:59:59:999
    },
  })
})

export const addCoinTransaction = throttle(
  (data: Record<string, unknown>) => {
    return ajax({
      url: '/transactionCoin/save',
      method: 'post',
      data: {
        ...data,
        investDate: data.investDate
          ? new Date(Number(data.investDate)).setHours(23, 59, 59, 999)
          : undefined, // 时间统一处理成 23:59:59:999
      },
    })
  },
  2000,
  {
    trailing: false,
  },
)

export const addOrUpdateTransaction = throttle(
  (data: Record<string, unknown>) => {
    return ajax({
      url: '/transactionRecord/addOrUpdate',
      method: 'post',
      data: {
        ...data,
        investDate: data.investDate
          ? new Date(Number(data.investDate)).setHours(23, 59, 59, 999)
          : undefined, // 时间统一处理成 23:59:59:999
      },
    })
  },
  2000,
  {
    trailing: false,
  },
)

export const fetchInvestmentInfos = (data: {
  projectId: string
  fundIds: (string | number)[]
  id?: string | null
}) => {
  return ajax({
    url: '/transactionRecord/queryFundInvestInfos',
    method: 'post',
    data,
  })
}

export const duplicateTransactionChecking = (
  data: {
    projectId: string
    investDate: number
    id?: string
    relateAfterId?: string
  },
  isBefore = false,
) => {
  return ajax({
    url: isBefore
      ? '/transactionEip/checkPreInvestDateIsUnique'
      : '/transactionPe/checkPreInvestDateIsUnique',
    method: 'post',
    data: {
      ...data,
      investDate: new Date(data.investDate).setHours(23, 59, 59, 999),
    },
  })
}

export const duplicateChecking = (data: {
  projectId: string
  investDate: number
  transactionType: string | number
}): Promise<{ data: string | null } | string> => {
  return ajax({
    url: '/transactionRecord/checkPreInvestDateIsUnique',
    method: 'post',
    data: {
      ...data,
      investDate: new Date(data.investDate).setHours(23, 59, 59, 999),
    },
  })
}

// 查询权益变更是否有解锁方案
export const fetchTransactionUnlockScheme = (params: { id: string }) => {
  return ajax({
    url: '/transactionCoin/isHaveUnlockScheme',
    method: 'get',
    params,
  })
}

// 判断解锁数量和交易数量是否一致
export const fetchEqualTransactionUnlockNum = (params: { id: string }) => {
  return ajax({
    url: '/transactionCoin/isSameUnlockNum',
    method: 'get',
    params,
  })
}

export const fetchInvestmentUnlockScheme = (params: { detailId: string }) => {
  return ajax({
    url: '/transactionCoin/isHaveUnlockSchemeDetail',
    params,
  })
}

export const fetchRoundList = async (
  { businessCode, assetTypeId }: { businessCode: string; assetTypeId: string },
  isBefore: boolean = false,
) => {
  const selectOptions = await ajax({
    url: isBefore ? '/transactionEip/queryRoundField' : '/transactionPe/queryRoundField',
    params: {
      businessCode,
      assetTypeId,
    },
  })

  return (selectOptions ?? []) as Option[]
}

export const saveTransaction = async (data: Record<string, any>, isBefore = false) => {
  return ajax({
    url: isBefore ? '/transactionEip/save' : '/transactionPe/save',
    method: 'post',
    data,
  })
}

// 根据估值类型查询列表
export const valuationQueryByDataId = async (data: Record<string, any>) => {
  return ajax({
    url: '/valuation/queryByDataId',
    method: 'post',
    data,
  })
}
