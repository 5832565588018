export const dialog_add = {
  'tianjiaxiangmu': '添加项目',
  'tianjiajijin': '添加基金',
  'tianjiatouzizhe': '添加投资者',
  'gongkaixinxi_tip1': '投资者创建成功后，您可以在投资者详情中查看公开信息',
  'gongkaixinxi_tip2': '项目创建成功后，您可以在项目详情中查看公开信息',
  'zhutigongsi_empty': '请输入您想查找的公司 <br />或根据输入的内容创建主体公司',
  'zhutigongsi_notfund': '没有找到您查找的公司 <br />您也可以根据输入的内容创建主体公司',
  'touzizhezhuti_empty': '请输入您想查找的投资者主体 <br />若投资者为个人，请直接点击下一步',
  'touzizhezhuti_notfund': '没有找到您查找的投资者主体 <br />您也可以根据输入的内容创建投资者主体全称',
  'chuangjianxiangmu': '创建项目',
  'zhutigongsi_placeholder': '请输入要投资的公司主体全称',
  'zhutigongsi': '主体公司',
  'jibenxinxi': '基本信息',
  'xiangmuchengyuan': '项目成员',
  'chuangjianjijin': '创建基金',
  'touzizhezhuti_placeholder': '请输入投资者主体全称',
  'zhutiquancheng': '主体全称',
  'xiezuochengyuan': '协作成员',
  'zhutigongsi_toast': '请选择主体公司',
  'chuangjianshibai': '创建失败',
  'chuangjianchenggong': '创建成功',
  'tianjiachengyuan_tip1': '可添加成员或部门',
  'tianjiachengyuan_tip2': '添加部门后，该部门所有成员将具有相同的权限。人员加入或移出部门，则自动获取或失去数据对应权限',
  'tschuangjianqunliao': '同时创建群聊',
  'tschuangjianqunliao_tooltip': '同时创建包含所有项目成员的群聊，便于后续沟通。也可进入详情页点击"发起群聊"创建或进入群聊。',
  'fuzeren': '负责人',
  'fuzeren_miaoshu': '可编辑或操作，可调整协作人员为负责人',
  'kebianji': '可编辑',
  'kebianji_miaoshu': '可编辑或操作，可调整协作人员编辑权限',
  'kechakan': '可查看',
  'kechakan_miaoshu': '仅可查看数据，不可进行编辑或操作'
}

export const dialog_del = {
  'querenshanchu': '确定删除【{p1}】吗?',
  'quedingshanchu_btn':'确定删除',
  'quedindshanchu_tip1': '确定删除<span style="color: #3e74ca">{names}</span>吗？删除后无法恢复',
  'quedindshanchu_tip2': '确定删除 <span style="color: #3e74ca">{name}</span> 吗？',
  'quedingshanchu_tip3': '确定删除吗？删除后无法恢复',
  'quedingshanchu_tip4': '确定删除？',
  'wufashanchu_touzizhe': '无法删除投资者',
  'wufashanchu_tip1': '以下投资者存在召款分配记录，无法操作批量删除',
  'wufashanchu_tip2': '该投资者投资的以下基金，存在召款分配记录，无法删除!',
  'touzizhejiancheng': '投资者简称',
  'jijinjiancheng': '基金简称',
  'jijinfuzeren': '基金负责人',
  'shanchuqueren': '删除确认',
  
}

export const dialog_other = {
  'tishi': '提示',

}

export const dialog_staff = {
  'suoxuantouzizhe': '所选{length}个{name}',
  'dangqianfuzeren': '当前负责人',
  'biangengwei': '变更为',
  'sousuoyonghu': '搜索用户',
  'tongshibiangeng': '同时将原负责人变为',
  'kebianji': '可编辑',
  'kechakan': '可查看',
  'congxiangmuyichu': '从项目中移除',
  'sousuorenyuanbumen': '搜索人员或部门',
  'xuanzerenyuan': '选择人员',
  'tianjiachengyuan_tip': '人员或部门都可以作为协作成员，添加部门后，代表该部门的所有人员都有同样的使用权限',
  'xuanzerenyuan_tip': '请先选择人员或部门！',
  'zuzhijiegou': '组织架构',
  'sousuorenyuan': '搜索人员',
  'xiaji': '下级',
  'yixuangeshu': '已选{length}个',
  'quanbu': '全部',
  'chengyuan_total': '共{length}个成员',
  'chengyuan': '成员',
  'shenqingbianji': '申请编辑权限',
  'tianjiachengyuan': '添加成员',
  'qingxuanze_staff': '请选择人员或部门',
  'fuzerenbiangeng': '负责人变更',
  'querenbiangeng': '确认将负责人由 <span style="color: #3e74ca">{ ownerName }</span> 变更为<span style="color: #3e74ca">{ tagartPerson }</span>吗？变更后原负责人将自动调整为可编辑权限',
  'shanchuchengyuan': '删除成员',

}