import { ajax } from '@/utils/request'

// 查询分类
export async function followRecordTypeQuery(extra) {
  const result = await ajax({
    method: 'post',
    url: `/followRecordType/query`,
    ...extra,
  }, [], []);
  return result;
}
// 查询分类 带数量
export async function followRecordCountByType(extra) {
  const result = await ajax({
    method: 'post',
    url: `/followRecord/countByType`,
    ...extra,
  }, [], []);
  return result;
}

// 更新
export async function followRecordTypeUpdate(extra) {
  const result = await ajax({
    method: 'post',
    url: `/followRecordType/update`,
    ...extra,
  }, [], []);
  return result;
}
// 批量更新
export async function followRecordTypeBatchUpdate(extra) {
  const result = await ajax({
    method: 'post',
    url: `/followRecordType/batchUpdate`,
    ...extra,
  }, [], []);
  return result;
}
// 新增
export async function followRecordTypeInsert(extra) {
  const result = await ajax({
    method: 'post',
    url: `/followRecordType/insert`,
    ...extra,
  }, [], []);
  return result;
}
// 删除
export async function followRecordTypeDelete(extra) {
  const result = await ajax({
    method: 'post',
    url: `/followRecordType/delete`,
    ...extra,
  }, [], []);
  return result;
}
