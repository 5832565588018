<template>
  <div ref="tagListModule" :class="['tag-list-module', from]">
    <template v-if="from == 'filter'">
      <div
        :class="{
          'filter-list': true,
          placeholder: !filterList?.length,
        }"
      >
        <span v-if="!filterList?.length" class="placeholder-text">
          {{ placeholder || $t('plat_c.app_c.form.qingxuanze') }}
        </span>
        <span
          v-for="(item, index) in filterList"
          :class="['tag-item', { more: item.cType == 'more' }]"
          :key="item.id"
          :style="
            'background:' +
            (item.color || item.colour) +
            (item.cType == 'more' ? ';width:' + item.cWidth : '')
          "
        >
          <!-- :style="{
            background: (item.color || item.colour) || 'transparent',
            width: item.cType === 'more' ? item.cWidth : ''
          }" -->
          <a-tooltip v-if="item.displayedLabel != item.label" :content="item.label">
            <span class="label">{{ item.displayedLabel }}</span>
          </a-tooltip>
          <span v-else class="label">{{ item.displayedLabel }}</span>
          <i
            v-if="showDeleteIcon"
            class="icon-del iconfont icon-icon_close_med"
            @click.stop="handleDel(index, $event)"
          />
        </span>
      </div>
      <!-- <i class="icon-arrow iconfont icon-icon_d_arrow"/> -->
      <i class="icon-tmp icon-2x10 filter-arrow-icon" />
    </template>
    <template v-else-if="Boolean(state)">
      <div class="show-list">
        <span
          v-for="(item, index) in computedShowList"
          :class="['tag-item', { more: item.cType == 'more' }]"
          :key="item.id"
          :style="
            'background:' +
            (item.color || item.colour) +
            (item.cType == 'more' ? ';width:' + item.cWidth : '')
          "
        >
          <a-tooltip v-if="item.displayedLabel != item.label" :content="item.label">
            <span class="label">{{ item.displayedLabel }}</span>
          </a-tooltip>
          <span v-else class="label">{{ item.displayedLabel }}</span>
        </span>
      </div>
    </template>
    <template v-else>
      <div class="edit-list">
        <div class="item-list">
          <template v-if="editList.length">
            <div
              class="tag-item"
              v-for="(item, index) in editList"
              :key="item.id"
              :style="'background:' + (item.color || item.colour)"
            >
              <a-tooltip v-if="item.displayedLabel != item.label" :content="item.label">
                <span class="label">{{ item.displayedLabel }}</span>
              </a-tooltip>
              <span v-else class="label">{{ item.displayedLabel }}</span>
              <i
                class="icon-del iconfont icon-icon_close_med"
                @click.stop="handleDel(index, $event)"
              />
            </div>
          </template>
          <template v-else>
            <span class="tag-placeholder">请选择</span>
          </template>
        </div>
        <i class="icon-arrow iconfont icon-icon_d_arrow" />
      </div>
    </template>
  </div>
</template>
<script>
import _ from 'lodash'

const W_FULL = 12 //汉字宽度，跟font-size大小一样
const W_HALF = 10 //字母数字符号宽度, 字符从7~10不等，字母约为8.7，数字约为7.2 不再精确计算
const W_PLUS = 8 //+号宽度7.2
const W_PAD = 8 //颜色块左右padding宽度
const W_MAR = 4 //tag之间宽度
const W_DOT = 10 //3个点省略号宽度
const W_XX = 10 //x号宽度
const W_ARROW = 10 //下拉箭头宽度
const NUM_COLOR = '#DBE0E3'

export default {
  name: 'TagList',
  props: {
    from: String, //组件使用来源，filter筛选，normal
    tags: Array,
    state: Number, //0为展示态，1为编辑态
    containerWidth: {
      type: Number,
      default: null,
    },
    workerComputedData: {
      type: Object,
      default: () => ({}),
    },
    labelKey: {
      type: String,
      default: 'label',
    },
    placeholder: {
      type: String,
    },
    showDeleteIcon: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fullWidth: 0,
      rawTags: [], //存储原始数组
      filterList: [], //筛选类型的选项列表
      showList: [], //展示态的选项列表
      editList: [], //编辑态选项列表
    }
  },
  watch: {
    tags: {
      immediate: true,
      deep: true,
      handler(v) {
        if (this.workerComputedData?.showTagList?.length) {
          // this.showList = this.workerComputedData.showTagList || []
          // this.editList = this.workerComputedData.editTagList || []
        } else {
          this.rawTags = _.cloneDeep(v)
          this.formatTags()
        }
      },
    },
  },
  computed: {
    computedShowList() {
      if (this.workerComputedData?.showTagList?.length) {
        return this.workerComputedData.showTagList
      } else {
        return this.showList || []
      }
    },
  },
  methods: {
    handleDel(index, evt) {
      evt.preventDefault()
      evt.stopPropagation()

      this.$emit('delVal', index)
    },
    //添加宽度数组
    addWidth(tag) {
      let str = tag.label
      //添加3个辅助key
      tag.charArr = Array.from(str)
      tag.widthArr = Array.from(str).map((cur) => {
        if (/[\u4e00-\u9fa5]/.test(cur)) {
          return W_FULL
        } else {
          return W_HALF
        }
      })
      let allLen = tag.widthArr.reduce((pre, cur) => {
        return pre + cur
      }, 0)
      tag.showWidth = W_PAD + allLen + W_PAD
      tag.editWidth = W_PAD + allLen + 6 + W_XX + W_PAD //8为tag颜色padding, 4为tag之间的间距
    },
    //获取+num这个tag的宽度, 样式上同样做个矫正
    getNumWidth(len) {
      //暂时不考虑大于100个选项
      let width
      if (len < 10) {
        width = W_PAD + W_PLUS * 2 + W_PAD
      } else {
        width = W_PAD + W_PLUS * 3 + W_PAD
      }
      // console.log('【NumWidth】', width);
      return width
    },
    //添加点点点, fWidth只算文字+点点点长度，其他padding, icon宽度等都不算
    fitLabel(tag, fWidth) {
      // console.log('【FitLabelWidth】', fWidth - W_DOT);
      let str = ''
      let curW = 0
      tag.widthArr.find((ii, index) => {
        if (curW + ii <= fWidth - W_DOT) {
          //如果宽度小于总宽度-3个点省略号宽度
          str += tag.charArr[index]
          curW += ii
          return false
        } else {
          return true
        }
      })
      tag.displayedLabel = str + '...' //重新拼凑
    },
    //获得数字标签的label, 从index开始算label
    formatMoreLabel(list, index) {
      let str = []
      for (let len = list.length; index < len; index++) {
        str.push(list[index].label)
      }
      return str.join('、')
    },
    formatTags() {
      if (this.fullWidth == 0) return
      if (!this.rawTags || this.rawTags.length < 1) {
        this.filterList = []
        this.editList = []
        this.showList = []
        return
      }

      if (this.from == 'filter') {
        // console.log(this.rawTags)
        let tmpList = _.cloneDeep(this.rawTags)
        tmpList.forEach((ii) => {
          ii.displayedLabel = ii.label
        })
        this.filterList = Object.freeze(tmpList)
        return
      }

      this.rawTags.forEach((ii) => {
        this.addWidth(ii) //先计算每个tag整体宽度
      })

      let showList = _.cloneDeep(this.rawTags) //展示态列表
      let editList = _.cloneDeep(this.rawTags) //编辑态列表
      let fullWidth = this.fullWidth
      let editFullWidth = fullWidth - W_ARROW - W_MAR

      // console.log('【editFullWidth】', editFullWidth);

      //如果只有单个选项
      if (this.rawTags.length == 1) {
        //先处理展示态情况
        if (showList[0].showWidth > fullWidth) {
          //如果超出总长度
          let tag = showList[0]
          this.fitLabel(tag, fullWidth - W_PAD * 2)
        } else {
          showList[0].displayedLabel = showList[0].label
        }
        this.showList = Object.freeze(showList)

        //处理编辑态情况
        if (editList[0].editWidth > editFullWidth) {
          //如果大于整个宽度
          let tag = editList[0]
          this.fitLabel(tag, editFullWidth - W_PAD * 2 - 6 - W_XX) //x号左侧margin 6px
        } else {
          editList[0].displayedLabel = editList[0].label
        }
        this.editList = Object.freeze(editList)
      } else {
        //大于1个选项的情况
        let allNumWidth = this.getNumWidth(showList.length - 1)
        /*** 处理展示态情况 *****/
        //如果第一个选项宽度就大于总宽度(总宽度-数字宽度)
        if (showList[0].showWidth > fullWidth - allNumWidth - W_MAR) {
          let tag = showList[0]
          this.fitLabel(tag, fullWidth - allNumWidth - W_MAR - W_PAD * 2)
          showList = showList.slice(0, 1)
          showList.push({
            displayedLabel: `+${this.rawTags.length - 1}`,
            color: NUM_COLOR,
            cWidth: allNumWidth + 'px',
            cType: 'more', //自定义类型，方便区分
            label: this.formatMoreLabel(this.rawTags, 1),
          })
          this.showList = Object.freeze(showList)
        } else {
          let tmpFullWidth = 0
          let tmpW = 0
          let tmpList = []
          showList.findIndex((ii, index) => {
            let tmpNumWidth = 0
            if (index < showList.length - 1) {
              //如果还不是最后一个
              tmpNumWidth = this.getNumWidth(showList.length - 1 - index)
              tmpFullWidth = fullWidth - tmpNumWidth - W_MAR //数字左侧的margin
            } else {
              tmpFullWidth = fullWidth
            }

            if (tmpW + ii.showWidth + W_MAR < tmpFullWidth) {
              //如果还没超过总宽度（两个标签之间有margin）
              ii.displayedLabel = ii.label
              tmpList.push(ii)
              tmpW += ii.showWidth
              return false
            } else {
              //如果超过了总宽度，则反向计算，根据宽度计算字数，大致计算，不再按照汉字或者非汉字宽度精确计算
              let leftW = tmpFullWidth - tmpW
              let leftNum = Math.floor((leftW - W_PAD * 2) / W_FULL)
              // console.log('【left width】', leftW);
              if (leftNum < 3) {
                //如果已经装不下3个字(包括点点点，则直接舍弃后续)
                tmpNumWidth = this.getNumWidth(showList.length - 1 - index + 1) //此时要算上当前这个index的值，所以+1
                tmpList.push({
                  displayedLabel: `+${showList.length - index}`,
                  color: NUM_COLOR,
                  cWidth: tmpNumWidth + 'px',
                  cType: 'more', //自定义类型，方便区分
                  label: this.formatMoreLabel(showList, index),
                })
                return true
              } else {
                this.fitLabel(ii, leftW - W_PAD * 2)
                tmpList.push(ii)
                if (index != showList.length - 1) {
                  //如果不是最后一个，还要加上+n
                  tmpList.push({
                    displayedLabel: `+${showList.length - 1 - index}`,
                    color: NUM_COLOR,
                    cWidth: tmpNumWidth + 'px',
                    cType: 'more', //自定义类型，方便区分
                    label: this.formatMoreLabel(showList, 1 + index),
                  })
                }
                return true
              }
            }
          })
          this.showList = Object.freeze(tmpList)
        }

        /*** 处理编辑态情况 *****/
        //判断是否有超过总体宽度的单个tag
        editList.forEach((ii) => {
          if (ii.editWidth + W_MAR > editFullWidth) {
            this.fitLabel(ii, editFullWidth - W_MAR - W_PAD * 2 - 6 - W_XX) //x号左侧margin 6px
          } else {
            ii.displayedLabel = ii.label
          }
        })
        this.editList = Object.freeze(editList)
      }
    },
  },
  mounted() {
    const calcWidth = () => {
      let tmpW = this.$refs.tagListModule?.offsetWidth
      // console.log('【fullWidth】', tmpW);
      this.fullWidth = tmpW
      this.formatTags()
    }

    if (!this.containerWidth) {
      this.$nextTick(() => {
        setTimeout(calcWidth, 100)
      })
    }
  },
}
</script>
<style lang="scss">
@import '@/assets/styles/var.scss';
.tag-list-module {
  padding-top: 4px;
  .filter-arrow-icon {
    line-height: 22px;
    font-size: 12px;
    transform: scale(0.73333);
    &::before {
      color: $icon-color-gray2;
    }
  }
  .tag-item {
    display: flex;
    align-items: center;
    margin-right: 4px;
    padding: 0 8px;
    height: 24px;
    line-height: 24px;
    border-radius: 11px;
    white-space: nowrap;
    user-select: none;
    background-color: #eef0f1;
    transition: background-color 0.2s ease-in-out;
    font-weight: normal;
    color: #1f2329;
    &.more {
      justify-content: center;
    }
    .label {
      font-size: 14px;
      font-weight: normal;
      user-select: none;
    }
  }
  .filter-list {
    display: flex;
    flex: 1;
    overflow-y: hidden;
    overflow-x: auto;
    &::-webkit-scrollbar {
      height: 3px;
      display: none;
    }
    &::-webkit-scrollbar-track {
      background: none;
    }
    &:hover {
      &::-webkit-scrollbar {
        display: block;
      }
    }
    .placeholder-text {
      color: #86909c;
    }
    .tag-item {
      // 保持一致 src/views/beforeInv/edit/transaction/form/tagList.vue
      .label {
        font-size: 14px;
      }
    }
  }
  .show-list {
    display: flex;
  }
  .edit-list {
    display: flex;
    align-items: center;
    margin-top: -4px;
    width: 100%;
    .item-list {
      flex: 1;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      overflow: hidden;
      .tag-placeholder {
        color: #86909C;
        margin-top: 8px;
      }
    }
    .tag-item {
      max-width: 100%;
      margin: 4px 4px 4px 0;
      width: fit-content;
      &:nth-child(1) {
        margin-left: 0;
      }
      .label {
        user-select: none;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .icon-del {
        margin-left: 6px;
        // width: 10px;
        height: 20px;
        font-size: 12px;
        line-height: 20px;
        cursor: pointer;
        color: #656a72;
        transform: scale(0.83333);
        transform-origin: center;
        &:hover {
          color: #2c2f35;
        }
      }
    }
    .icon-arrow {
      flex-shrink: 0;
      margin-left: 4px;
      // margin-top: 9px;
      font-size: 12px;
      transform: scale(0.8333);
      transform-origin: center;
      color: #646a73;
    }
  }
}
.tag-list-module.filter {
  display: flex;
  .icon-arrow {
    margin-left: 4px;
    margin-top: 4px;
    font-size: 10px;
  }
}
</style>
