<template>
  <div
    ref="floatDragRef"
    class="float-wrapper"
    :style="style"
    @touchmove.prevent
    @mousemove.prevent
    @mousedown="onMouseDown"
    @mouseup="onMouseUp"
    @touchend="onMouseUp"
  >
    <slot />
  </div>
</template>

<script lang="ts" setup name="floatingWrapper">
import { useResizeObserver, useWindowSize } from '@vueuse/core'
import { computed, nextTick, onMounted, onUnmounted, PropType, reactive, ref, watch } from 'vue'

const props = defineProps({
  distanceRight: {
    type: Number as PropType<number>,
    default: 20,
  },
  distanceBottom: {
    type: Number as PropType<number>,
    default: 20,
  },
  isScrollHidden: Boolean as PropType<boolean>,
  draggable: Boolean as PropType<boolean>,
  zIndex: Number as PropType<number>,
  margin: {
    vertical: Number as PropType<number>,
    horizontal: Number as PropType<number>,
    default: () => ({ vertical: 0, horizontal: 0 }),
  },
})
const emits = defineEmits(['pure-click', 'update:dragging'])

const clickable = ref(false)
const left = ref(0)
const top = ref(0)
const timer = ref<number>()
const currentTop = ref(0)
const mousedownX = ref(0)
const mousedownY = ref(0)
const floatDragRef = ref<HTMLElement>()
const domContentRect = reactive({
  width: 0,
  height: 0,
})
const { width, height } = useWindowSize()
watch(
  () => [width.value, height.value],
  () => {
    reCalcPosition()
  },
  {
    deep: true,
  },
)
useResizeObserver(floatDragRef, (entries) => {
  const entry = entries[0]
  // const { width, height } = entry.contentRect
  const { width, height: h } = entry.target.getBoundingClientRect()
  const isShrink = domContentRect.height > h
  const tmpHeight = isShrink ? domContentRect.height : h
  const isBottom = top.value + tmpHeight >= height.value

  domContentRect.height = h
  domContentRect.width = width

  nextTick(() => reCalcPosition(isBottom))
})

const horizontalMargin = computed(() => {
  const isLeft = left.value < width.value / 2

  return isLeft ? props.margin.horizontal : -props.margin.horizontal
})
const verticalMargin = computed(() => {
  const isTop = top.value === 0
  const isBottom = top.value + domContentRect.height === height.value

  return isTop ? props.margin.vertical : isBottom ? -props.margin.vertical : 0
})
const style = computed(() => {
  return {
    left: `${left.value + horizontalMargin.value}px`,
    top: `${top.value + verticalMargin.value}px`,
    zIndex: props.zIndex ?? undefined,
  }
})

const initDraggable = () => {
  floatDragRef.value?.addEventListener('touchstart', onToucheStart)
  floatDragRef.value?.addEventListener('touchmove', onTouchMove)
  floatDragRef.value?.addEventListener('touchend', onTouchEnd)
}
const onToucheStart = () => {
  clickable.value = false
  floatDragRef.value!.style.transition = 'none'
}
const onTouchMove = (e: TouchEvent) => {
  clickable.value = true
  // 单指拖动
  if (e.targetTouches.length === 1) {
    let touch = e.targetTouches[0]
    left.value = touch.clientX - domContentRect.width / 2
    top.value = touch.clientY - domContentRect.height / 2
  }
}
const onTouchEnd = () => {
  if (!clickable.value) return // 解决点击事件和touch事件冲突的问题

  floatDragRef.value!.style.transition = 'all 0.3s'
  checkDraggablePosition()
}
const checkDraggablePosition = () => {
  // 判断位置是往左往右滑动
  if (left.value + domContentRect.width / 2 >= width.value / 2) {
    left.value = width.value - domContentRect.width
  } else {
    left.value = 0
  }

  // 判断是否超出屏幕上沿
  if (top.value < 0) {
    top.value = 0
  }
  // 判断是否超出屏幕下沿
  if (top.value + domContentRect.height >= height.value) {
    top.value = height.value - domContentRect.height
  }
}
const onScroll = () => {
  timer.value && clearTimeout(timer.value)
  timer.value = setTimeout(() => {
    onScrollEnd()
  }, 200)
  currentTop.value = document.documentElement.scrollTop || document.body.scrollTop

  // 判断元素位置再左侧还是右侧
  if (left.value > width.value / 2) {
    left.value = width.value + domContentRect.width
  } else {
    left.value = -domContentRect.width
  }
}
const onScrollEnd = () => {
  let scrollTop = document.documentElement.scrollTop || document.body.scrollTop

  // 判断元素位置再左侧还是右侧
  if (scrollTop === currentTop.value) {
    if (left.value > width.value / 2) {
      left.value = width.value - domContentRect.width
    } else {
      left.value = 0
    }
    clearTimeout(timer.value)
  }
}
const onMouseDown = (e: MouseEvent) => {
  const event = e || window.event
  mousedownX.value = event.screenX
  mousedownY.value = event.screenY
  let floatDragWidth = domContentRect.width / 2
  let floatDragHeight = domContentRect.height / 2

  event?.preventDefault()

  clickable.value = false
  floatDragRef.value!.style.transition = 'none'
  document.onmousemove = function (moveEvt: MouseEvent) {
    const event = moveEvt || window.event
    left.value = event.clientX - floatDragWidth
    top.value = event.clientY - floatDragHeight
    if (left.value < 0) left.value = 0
    if (top.value < 0) top.value = 0
    if (left.value >= width.value - floatDragWidth * 2) {
      left.value = width.value - floatDragWidth * 2
    }
    if (top.value >= height.value - floatDragHeight * 2) {
      top.value = height.value - floatDragHeight * 2
    }
  }
  emits('update:dragging', true)
}
const onMouseUp = (evt: MouseEvent | TouchEvent) => {
  emits('update:dragging', false)
  const event = evt || window.event
  // 判断只是单纯的点击，没有拖拽
  if (mousedownY.value == event.screenY && mousedownX.value == event.screenX) {
    emits('pure-click')
  }
  document.onmousemove = null
  checkDraggablePosition()
  floatDragRef.value!.style.transition = 'all 0.3s'
}
const reCalcPosition = async (isBottom?: boolean) => {
  if (isBottom) {
    top.value = height.value - domContentRect.height
  }
}

onMounted(() => {
  props.draggable &&
    nextTick(() => {
      left.value = width.value - domContentRect.width - props.distanceRight
      top.value = height.value - domContentRect.height - props.distanceBottom
      initDraggable()
    })
  props.isScrollHidden && window.addEventListener('scroll', onScroll)
  left.value = width.value - 418 // domContentRect.width
  top.value = height.value - domContentRect.height
})
</script>

<style lang="scss" scoped>
.float-wrapper {
  position: absolute;
  z-index: 999;
  right: 0;
  top: 70%;
  // width: 3.6em;
  // height: 3.6em;
  background: deepskyblue;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0px 0px 10px 2px skyblue;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}
</style>
