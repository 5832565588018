<template>
  <div class="empty-content">
    <a-empty style="padding: 24px 0">
      <template #image>
        <slot name="image">
          <img src="@/assets/img/empty.png" alt="" />
        </slot>
      </template>
      <span class="empty-text">
        <slot name="message">
          {{ message ? message : isSearch ? i18n_t('plat_c.app_c.empty_text.weizhaodaoneirong') : i18n_t('plat_c.app_c.empty_text.zanwumeirong') }}
        </slot>
      </span>
    </a-empty>
  </div>
</template>

<script lang="ts" setup>
import { PropType } from 'vue'
import { useI18n } from 'vue-i18n';

const { t:i18n_t } = useI18n()
const props = defineProps({
  isSearch: Boolean as PropType<boolean>,
  message: String as PropType<string>,
})
</script>

<style lang="scss" scoped>
.empty-content {
  min-height: 100px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 60px;
    height: 60px;
    display: block;
    margin: 0 auto;
  }
  .empty-text {
    font-family: PingFangSC;
    color: #C0C4CC;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }
}
</style>
