<template>
  <div :class="['app-main', sidebar ? 'sidebar' : '']">
    <div class="show-btn" @click="showDebugBar"></div>
    <div v-if="showDebug" class="debug-info">
      <a-button @click="hideDebug">隐藏调试</a-button>&nbsp;&nbsp;
      <a-button @click="showNavigator">显示内核信息</a-button>&nbsp;&nbsp;
      <a-button @click="showHash">显示URL</a-button>&nbsp;&nbsp;
      <a-button @click="showToken">显示LocalToken</a-button>&nbsp;&nbsp;
      <a-button @click="removeToken">重刷</a-button>&nbsp;&nbsp;
      <a-button @click="openSidebar">打开侧滑</a-button>&nbsp;&nbsp;
      <a-button @click="shareWebContentHandler">shareWebContent</a-button>&nbsp;&nbsp;
      <a-button @click="shareOut">shareOut</a-button>&nbsp;&nbsp;
      <a-button @click="share">sendMessageCard</a-button>&nbsp;&nbsp;
      <a-button @click="openPreview">{{
        previewDocument ? '关闭文档预览' : '打开文档预览'
      }}</a-button
      >&nbsp;&nbsp;
      <a-button>
        <span class="icon--">
          <span class="path1"></span>
          <span class="path2"></span>
        </span> </a-button
      >&nbsp;&nbsp;
      <!-- <a-button @click="share">分享</a-button>&nbsp;&nbsp; -->
      <div class="token">{{ urlHash }}</div>
      <div class="token">{{ localToken }}</div>
    </div>
    <a-modal v-model:visible="previewDocument" width="70%" :footer="false" class="iframe-modal">
      <div class="preview-iframe">
        <iframe
          v-if="previewDocument"
          width="100%"
          height="100%"
          src="https://test-cgpxsg4rrbd2.feishu.cn/file/boxcnQbgmg2HoO4t3JjPBEpTV4g?hideHeader=1"
          frameborder="0"
        />
        <div class="right-shadow"></div>
      </div>
      <template #footer></template>
    </a-modal>
    <router-view />
    <!-- 文档资料-本地上传 -->
    <!-- <filePop
      :showUploadPop="showUploadPop"
      ref="upLoadPop"
    /> -->
    <FileFloatingWindow ref="fileFloatingWindowRef" />
  </div>
  
</template>

<script>
import { Message } from '@arco-design/web-vue'
import * as appTool from '@/utils/appTool.js'
import { getAppName } from '@/utils/appTool.js'
import FileFloatingWindow from '@/views/components/FileFieldComp/FileFloatingWindow.vue'


export default {
  components: {
    // filePop,
    FileFloatingWindow,
  },
  data() {
    return {
      localToken: '',
      urlHash: '',
      showDebug: false,
      count: 0,
      previewDocument: false
    }
  },
  provide() {
    return {
      fileListUpload: (files, dataId, businessCode, parentId = null, cb) => {
        this.$refs.fileFloatingWindowRef.onUploadFileList(files, dataId, businessCode, parentId, cb)
      },
      fileFolderUpload: (files, dataId, businessCode, parentId = null, cb) => {
        this.$refs.fileFloatingWindowRef.onUploadFileFolder(files, dataId, businessCode, parentId, cb)
      },
    }
  },
  created() {
    if (location.href.indexOf('debug') > -1) {
      this.showDebug = true
      localStorage.setItem('debug', 1)
    }

    //非生产环境都显示debug
    if (localStorage.getItem('debug') == 1) {
      this.showDebug = true
    }
  },
  computed: {
    sidebar() {
      return [
        'setnotify',
        'getmessage',
        'publicSentiment',
        'setproject',
        'noappauth',
        'addvote',
        'addvotenoauth',
        'addvoteurl',
        'nodataauth',
      ].includes(this.$route.name)
    },
    
  },
  // watch: {
  //   showUploadPop(val) {
  //     console.log('======', val);
  //     if (val) {
  //       this.$nextTick(() => {
  //         this.$refs.upLoadPop.showUploadInput()
  //       })
  //     }
  //   }
  // },
  methods: {
    showNavigator() {
      alert(navigator.userAgent)
    },
    openPreview() {
      this.previewDocument = !this.previewDocument
    },
    shareWebContentHandler() {
      alert(tt)
      window.h5sdk.ready(() => {
        Message.warning(window.tt)
        tt.shareWebContent({
          title: '我是分享标题',
          url: 'https://www.feishu.cn/',
          success(res) {
            Message.warning('shareWebContent success')
            console.log(JSON.stringify(res));
          },
          fail(res) {
            Message.warning('shareWebContent fail')
            console.log(`share fail: ${JSON.stringify(res)}`);
          },
          complete(res) {
            Message.warning('shareWebContent complete')
          }
        })
      })
    },
    shareOut() {
      window.h5sdk.ready(() => {
        alert(tt)
        tt.share({
          channelType: [
            "wx",
            "wx_timeline",
            "system"
          ],
          contentType: "url",
          title: "飞书官网",
          url: "https://www.feishu.cn/",
          success(res) {
            Message.warning('share success')
            console.log(JSON.stringify(res));
          },
          fail(res) {
            Message.warning('share fail')
            console.log(`share fail: ${JSON.stringify(res)}`);
          },
          complete(res) {
            Message.warning('share complete')
          }
        });
      })
    },
    openSidebar() {
      // window.open('https://www.baidu.com');
      // window.open('https://applink.feishu.cn/client/web_app/open?appId=cli_a2ab730a1db9900c&mode=sidebar', '_blank');
      window.open(
        'https://applink.feishu.cn/client/web_url/open?mode=sidebar-semi&url=https%3a%2f%2fwww.feishu.cn%2f',
      )
      // window.open('https://applink.feishu.cn/client/todo/view?tab=all&mode=sidebar')
    },
    showDebugBar() {
      this.count++
      if (this.count > 9) {
        localStorage.setItem('debug', 1)
        this.showDebug = true
      }
    },
    share() {
      if (navigator.userAgent.indexOf('Lark') == -1) {
        this.$message.error('请在飞书应用内打开')
      } else {
        window.h5sdk.ready(() => {
          // alert(111)
          tt.sendMessageCard({
            shouldChooseChat: true,
            chooseChatParams: {},
            openChatIDs: [
              // "687485********10402"
            ],
            triggerCode: 'testCode',
            cardContent: {
              msg_type: 'interactive',
              update_multi: false,
              card: {
                header: {
                  title: {
                    content: '查询结果',
                    tag: 'plain_text',
                  },
                  template: 'orange',
                },
                elements: [
                  {
                    content: '**查出基金**',
                    tag: 'markdown',
                  },
                  {
                    fields: [
                      {
                        is_short: true,
                        text: {
                          content: '**项目名称：**\nguo-勿删',
                          tag: 'lark_md',
                        },
                      },
                      {
                        is_short: true,
                        text: {
                          content: '**公司全称：**\n11',
                          tag: 'lark_md',
                        },
                      },
                    ],
                    tag: 'div',
                  },
                  {
                    content:
                      '[查看详情 \u003e\u003e](https://applink.feishu.cn/client/web_app/open?appId\u003dcli_a2962daf93ba500e\u0026dataid\u003d1502203511008395264)',
                    tag: 'markdown',
                  },
                ],
              },
            },
            success(res) {
              console.log(JSON.stringify(res))
            },
            fail(res) {
              console.log(`sendMessageCard fail: ${JSON.stringify(res)}`)
            },
          })
          return

          // tt.chooseChat({
          //   allowCreateGroup: true,
          //   multiSelect: true,
          //   ignoreSelf: false,
          //   ignoreBot: false,
          //   selectType: 0,
          //   confirmTitle: '确认',
          //   confirmDesc: '确认发送？',
          //   externalChat: true,
          //   showMessageInput: true,
          //   success(res) {
          //     this.$message.success(JSON.stringify(res))
          //   },
          //   fail(res) {
          //     this.$message.error()
          //     ;`chooseChat fail: ${JSON.stringify(res)}`
          //   },
          // })
        })
      }
    },
    hideDebug() {
      localStorage.removeItem('debug')
      this.showDebug = false
    },
    showToken() {
      alert(JSON.stringify(localStorage))
    },
    removeToken() {
      appTool.removeToken()
      localStorage.removeItem(getAppName() + '_visited')
      localStorage.clear()
      alert('清除成功，请关闭应用重新打开')
    },
    showHash() {
      this.urlHash = location.href
    },
    
  },
 
}
</script>

<style lang="scss">
.iframe-modal {
  .arco-modal-header {
    display: none;
  }
 
}

.preview-iframe {
  width: 100%;
  height: 70vh;
  padding-left: 44px;
  position: relative;
  background: #f2f3f5;
  .right-shadow {
    width: 44px;
    height: 100%;
    background: #f2f3f5;
    position: absolute;
    top: 0;
    right: 0;
  }
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background: #fff !important;
  .app-main {
    height: 100%;
    width: 100%;
    // min-width: 897px;
    &.sidebar {
      min-width: 350px;
    }
  }
  .token {
    width: 50%;
    padding-bottom: 5px;
    word-break: break-all;
  }
  .show-btn {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 20px;
    height: 20px;
    z-index: 100;
    // cursor: pointer;
  }
}
input[type='number'] {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  -moz-appearance: textfield;
}
.debug-info {
  position: fixed;
  z-index: 9999999;
  left: 0;
  top: 0;
}
</style>
