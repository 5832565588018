// 舆情分析
export default {
  yuqingjiance: '舆情监测',
  wuquanxian: '暂无访问权限，如需请联系管理员',
  meiyougengduo: '没有更多了',
  shijian: '时间',
  laiyuan: '来源',
  shizhi_chenggong: '设置成功',
  bianji_chenggong: '编辑成功',
  guanbiyemian: '关闭页面',
  quxiao: '取消',
  queding: '确定',
  jiancemingcheng: '监测名称',
  qingshurujiancemingcheng: '请输入舆情监测名称',
  bitian: '必填',
  zixunleixing: '资讯类型',
  qingxuanzezixunleixing: '请选择资讯类型',
  zhengmian: '正面',
  jiji: '积极',
  zhongxing: '中性',
  zhongli: '中立',
  fumian: '负面',
  xiaoji: '消极',
  jiankonghangye: '监控行业',
  qingxuanzejiankonghangye: '请选择监控行业',
  jiankongxiangmu: '监控项目',
  qingsousuojiankongxiangmu: '请搜索监控项目',
  kaiguantuisong: '开启/关闭推送',
  tuisongguize: '推送规则',
  yuqingjiance_shezhi: '舆情监测设置',
  xuanzehangyexiangmu: '请选择行业或项目',
  yu: '于',
  tuisong: '推送',
  tianjiashijiandian: '添加时间点',
  weekDay: '周一至周五',
  meitian: '每天',

}