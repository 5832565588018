<template>
  <ul class="emoji-content">
    <li class="emoji-item" v-for="item in list" :key="item.emojiType">
      <img :src="item.value" alt="" class="emoji-img" @click="handleClick(item)">
    </li>
  </ul>
</template>

<script>
import emojiType from "./emojiTypeList.js"
export default {
  name: 'emojiCom',
  data() {
    return {
      list: emojiType,
    }
  },
  methods: {
    handleClick(item) {
      this.$emit('selectEmoji', item)
    },
  }
}
</script>

<style lang="scss" scoped>
.emoji-content {
  width: 272px;
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
  background: #FFFFFF;
  border-radius: 6px;
  padding: 16px 16px 14px 16px;
  box-sizing: border-box;
  border: 1px solid #DEE0E3;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.06);
  .emoji-item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2px;
    .emoji-img {
      width: 28px;
      cursor: pointer;
      transition: .2s;
      &:hover {
        scale: 1.2143;
      }
    }
  }
}
</style>