import { CURRENCY_MAPPING } from '@/assets/constants/mappings.js'
import { i18n_t, getI18nLanguage } from '@/utils/langTool.js';
import { currencyMappingStore } from '@/store/useCurrencyStore'

export function getDecimalPlacesFromScientificNotation (numberOrStr){
  const numberStr =  Number(numberOrStr).toString()
  // 检查字符串是否包含 'e' 或 'E'（科学计数法的标志）
  if (!numberStr.includes('e') && !numberStr.includes('E')) {
    return false;
  }

  // 分割数字和指数部分
  const [numberPart, exponentPart] = numberStr.split('e');
  const [integerPart, decimalPart] = numberPart.split('.');

  // 计算小数部分的长度
  const decimalLength = decimalPart ? decimalPart.length : 0;

  // 计算指数部分表示的小数移动位数
  const exponent = parseInt(exponentPart, 10);

  // 小数位数 = 小数部分长度 + 指数
  return decimalLength + Math.abs(exponent);
}

export function formatCurrency(value, isUnit = false, isSparator = true, split = false, float = 2) {
  if (value) {
    let unit = ''
    let _value
    
    
    const Decimal = getDecimalPlacesFromScientificNotation(value)
    if(Decimal) {
      _value = Number(value).toFixed(Decimal)
    } else {
      _value = Number(value)
    }
    if (isUnit) {
      if (value >= 100000000 || value <= -100000000) {
        unit = i18n_t('plat_c.allTabs.com.yi')
        // _value = Math.round(value / 1000000) / 100
        _value = parseFloat(((value / 1000000) / 100).toFixed(float))
      } else if (value >= 10000 || value <= -10000) {
        unit = i18n_t('plat_c.allTabs.com.wan')
        // _value = Math.round(value / 10000)
        _value = parseFloat((value / 10000).toFixed(float))
      }
    }
    if (isSparator) {
      const newNum = String(_value)
      const values = newNum.split('.');
      values[0] = values[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      _value = values.join('.')
    }
    if (split) {
      return [_value, unit]
    }
    return `${_value}${unit}`
  } else {
    if (split) {
      return [0, '']
    }
    return 0
  }
}
export function formatCurrencyWan(value, isUnit = false, preffix = '', isSparator = true, toFixed = 2) {
  if (value) {
    
    value = String(value)
    let unit = ''
    let tempPref = ''
    if (value.indexOf('-') > -1) {
      value = value.split('-')[1]
      tempPref = '-'
      preffix = preffix + ' ' + tempPref
    } else {
      tempPref = ' '
      preffix = preffix + tempPref
    }
   
    let _value = (value * 1).toFixed(toFixed)
    if (isUnit) {
      if (value >= 50) {
        unit = i18n_t('plat_c.allTabs.com.wan')
        _value =(value / 10000).toFixed(toFixed)
      } else {
        unit = ''
        _value = 0
      }
    }
    if (isSparator) {
      _value = (_value * 1).toFixed(toFixed)
      const newNum = String(_value)
      const values = newNum.split('.');
      values[0] = values[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      _value = values.join('.')
    }

    return `${preffix}${_value}${unit}`
  } else {
    return 0
  }
}

let tmpFn = null
export const mapCurrency = (currency) => {
  if (!tmpFn) {
    const { getCurrencySymbol } = currencyMappingStore()
    tmpFn = getCurrencySymbol
  }

  return tmpFn(currency)
}
// export function mapCurrency(value) {
//   return CURRENCY_MAPPING[value] || value
// }

/**
 * 
 * @param 
 * value // 货币的对象或者字符串, 
 * isTrans // 是否要把币种（CNY）转为币种符号（¥）,
 * isUnit // 是否要单位
 */
export function currencyObj(value, isTrans = true, isUnit = true) {
  if (!value) return {}
  const result = {}
  if (typeof value === 'string') {
    value = JSON.parse(value)
  }
  if (!value) {
    return {}
  }
  result.currency = isTrans ? mapCurrency(value.currency) : value.currency // 币种或币种符号
  const currencyHasUnit = formatCurrency(value.num, true)
  result.num = formatCurrency(value.num) // 金额
  if (!isUnit || result.num === currencyHasUnit) {
    result.unit = '' // 单位
  } else {
    result.unit = currencyHasUnit.slice(-1)
    result.num = formatCurrency(value.num, true).slice(0, -1)
  }
  result.value = result.currency + ' ' + result.num + ' ' + result.unit
  return result
}
// 小数位抹去最后的0，例：1.200 =》 1.2， 500.0000 =》500
export function parseFloatNum(value) {
  value *= 1
  if (value) {
    return parseFloat(value)
  } else {
    return 0
  }
}
export function suffix(number) {
  if (number) {
    number = Math.abs(number)
    number = number + ''
    if (number && number.indexOf('.') > -1) {
      let s = number.split('.')
      const length = s[0].length || 0
      if (length > 4 && length < 9) {
        return i18n_t('plat_c.allTabs.com.wan')
      }
      if (length > 8) {
        return i18n_t('plat_c.allTabs.com.yi')
      } else {
        return ''
      }
    } else {
      const length = number.length || 0
      if (length > 4 && length < 9) {
        return i18n_t('plat_c.allTabs.com.wan')
      }
      if (length > 8) {
        return i18n_t('plat_c.allTabs.com.yi')
      } else {
        return ''
      }
    }
  } else {
    return ''
  }
}
export function currency(number) {
 
  if (number) {
    let isNegative
    if (number < 0) isNegative = true
    number = Math.abs(number)
    const Decimal = getDecimalPlacesFromScientificNotation(number)
    if(Decimal) {
      number = Number(number).toFixed(Decimal)
    } else {
      number = Number(number)
    }
    number = number + ''
    if (number.indexOf('.') > -1) {
      let srr = number.split('.')
      const length = srr[0].length || 0
      if (length > 4 && length < 9) {
        // const num = Math.round(+number / 10000)
        const num = parseFloat((+number / 10000).toFixed(2))
        return isNegative ? '-' + formatCurrency(num) : formatCurrency(num)
      }
      if (length > 8) {
        // const num = Math.round(+number / 1000000) / 100
        const num = parseFloat(((+number / 1000000) / 100).toFixed(2))
        return isNegative ? '-' + formatCurrency(num) : formatCurrency(num)
      } else {
        return isNegative ? '-' + srr[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '.' + srr[1].slice(0, 2) : srr[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '.' + srr[1].slice(0, 2)
      }
    } else {
      const length = number.length || 0
      if (length > 4 && length < 9) {
        // const num = Math.round(+number / 10000)
        const num = parseFloat((+number / 10000).toFixed(2))
        return isNegative ? '-' + formatCurrency(num) : formatCurrency(num)
      }
      if (length > 8) {
        // const num = Math.round(+number / 1000000) / 100
        const num = parseFloat(((+number / 1000000) / 100).toFixed(2))
        return isNegative ? '-' + formatCurrency(num) : formatCurrency(num)
      } else {
        return isNegative ? '-' + formatCurrency(number) : formatCurrency(number)
      }
    }
  } else {
    return 0
  }
}

export function point(number, num = 2) {
  number = Number(number).toFixed(num)
  return parseFloat(number)
}

export const formatData = (num) => {
  if (['',null,undefined].includes(num)) return '-'
  num = num + ''
  if (!num.includes('.')) {
    num += '.'
  }
  return num
    .replace(/(\d)(?=(\d{3})+\.)/g, function ($0, $1) {
      return $1 + ','
    })
    .replace(/\.$/, '')
}

export const formatNumberFloat = (num, float = 2) => {
  if (['',null,undefined].includes(num)) return '-'
  num = (num * 1).toFixed(float) // 利用toFixed实现末尾补零
  let intNum = num.split('.')[0]
  let floatNum = num.split('.')[1]
  intNum = intNum + ''
  if(!!float) {
    return intNum.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '.' + floatNum
  }
  return intNum.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  

  // if (['',null,undefined].includes(num)) return '-'
  // num = (num * 1).toFixed(float)
  // num = num + ''
  
  // // 补零机制
  // let intNum = num.split('.')[0] + '.'
  // let floatNum = num.split('.')[1] || ''
  // const distanceLength = float - floatNum.length
  // if (distanceLength > 0) {
  //   // 小数位少于2位，补零
  //   for (let index = 0; index < distanceLength; index++) {
  //     floatNum+= '0'
  //   }
  // }
  // num = intNum.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '.' + floatNum
  // return num
}

/**
 * 是否与原始值不同
 * @param {原始数字,这里可能值number或者JSON序列化字符串,也有可能是字符串数字} num 
 * @param {小数位} float 
 */
export function differentWithOrigin(num, float = 2) {
  let tempNum = num
  if (typeof(tempNum) === 'string' && tempNum.startsWith('{')) {
    // tempNum = tempNum.replace(/(\d+)/g, '"$&"')
    tempNum = JSON.parse(tempNum)
  }
  if (typeof(tempNum) === 'object') {
    tempNum = tempNum?.num
  }
  tempNum = tempNum + ''
  const floatNum = tempNum.split('.')[1] || ''
  if (floatNum.length <= float) { // 只有超出float位数(四舍五入)的需要需要悬浮显示
    return false
  }

  return true
}

/**
 * 概况显示值是否与原始值不同
 * @param {原始数字,这里可能值number或者JSON序列化字符串,也有可能是字符串数字} num 
 * @param {显示值} showNum  
 * @param {原始值} originNum  
 */
export function gkDifferentWithOrigin(showNum, originNum) {
  const nomalNum = (showNum + '').replaceAll(',', '')
  if (nomalNum * 1 !== originNum * 1 ) {
    return true
  }
  return false
}
