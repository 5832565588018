<template>
  <img
    v-if="fileType == 'xlsx'"
    :class="['file-icon', className]"
    :style="customStyle"
    src="@/assets/img/file/xlsx.svg"
  />
  <img
    v-else-if="fileType == 'word'"
    :class="['file-icon', className]"
    :style="customStyle"
    src="@/assets/img/file/word.svg"
  />
  <img
    v-else-if="fileType == 'pdf'"
    :class="['file-icon', className]"
    :style="customStyle"
    src="@/assets/img/file/pdf.svg"
  />
  <img
    v-else-if="fileType == 'ppt'"
    :class="['file-icon', className]"
    :style="customStyle"
    src="@/assets/img/file/ppt.svg"
  />
  <img
    v-else-if="fileType == 'txt'"
    :class="['file-icon', className]"
    :style="customStyle"
    src="@/assets/img/file/txt.svg"
  />
  <img
    v-else-if="fileType == 'img'"
    :class="['file-icon', className]"
    :style="customStyle"
    src="@/assets/img/file/img.svg"
  />
  <img
    v-else-if="fileType === 'folder'"
    :class="['file-icon', className]"
    :style="customStyle"
    src="@/assets/img/file/folder.svg"
  />
  <!-- 以下飞书文档 -->
  <img
    v-else-if="['doc','docs','docx'].includes(fileType)"
    :class="['file-icon', className]"
    :style="customStyle"
    src="@/assets/img/file/doc.svg"
  />
  <img
    v-else-if="fileType == 'sheet'"
    :class="['file-icon', className]"
    :style="customStyle"
    src="@/assets/img/file/sheet.svg"
  />
  <img
    v-else-if="fileType == 'bitable'"
    :class="['file-icon', className]"
    :style="customStyle"
    src="@/assets/img/file/bitable.svg"
  />
  <img
    v-else-if="fileType == 'mindnote'"
    :class="['file-icon', className]"
    :style="customStyle"
    src="@/assets/img/file/mindnote.svg"
  />
  <img
    v-else-if="fileType == 'unknown'"
    :class="['file-icon', className]"
    :style="customStyle"
    src="@/assets/img/file/unknown.svg"
  />
</template>

<script>
export default {
  name: 'fileIcon',
  props: {
    file: Object,
    className: String,
    size: Number,
  },
  computed: {
    fileType() {
      let tmpFileName = (this.file.fileName ? this.file.fileName : this.file.name) || ''

      if (['doc', 'docx', 'docs', 'sheet', 'bitable', 'mindnote', 'folder', 'wiki'].includes(this.file.fileType)) {
        return this.file.fileType
      }
      if (tmpFileName.indexOf('.') == -1) {
        return 'unknown'
      }
      let tmpArr = tmpFileName.split('.')
      let type = tmpArr[tmpArr.length - 1]

      switch (type) {
        case 'xlsx':
        case 'xls':
          return 'xlsx'
        case '':
        case 'docx':
        case 'docs':
        case 'doc':
          return 'word'
        case 'pdf':
          return 'pdf'
        case 'txt':
          return 'txt'
        case 'png':
        case 'img':
        case 'jpg':
        case 'jpeg':
        case 'gif':
          return 'img'
        case 'ppt':
        case 'pptx':
          return 'ppt'
        default:
          return 'unknown'
      }
    },
    customStyle() {
      return this.$props.size
        ? {
            fontSize: `${this.$props.size}px`,
            width: `${this.$props.size}px`,
            height: `${this.$props.size}px`,
          }
        : {}
    },
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.file-icon {
}
</style>
