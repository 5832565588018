import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Shanghai');
import quarterOfYear from 'dayjs/plugin/quarterOfYear'

export const reportTypeLists = [
  {
    value: 'M1',
    label: '一月报',
  },
  {
    value: 'M2',
    label: '二月报',
  },
  {
    value: 'Q1',
    label: '一季报',
  },
  {
    value: 'M4',
    label: '四月报',
  },
  {
    value: 'M5',
    label: '五月报',
  },
  {
    value: 'halfYear',
    label: '半年报',
  },
  {
    value: 'M7',
    label: '七月报',
  },
  {
    value: 'M8',
    label: '八月报',
  },
  {
    value: '3Q',
    label: '三季报',
  },
  {
    value: 'M10',
    label: '十月报',
  },
  {
    value: 'M11',
    label: '十一月报',
  },
  {
    value: 'year',
    label: '年报',
  },
];
export const reportTypeMap = {
  'M1': 9,
  'M2': 10,
  'Q1': 1,
  'M4': 12,
  'M5': 13,
  'halfYear': 2,
  'M7': 15,
  'M8': 16,
  '3Q': 3,
  'M10': 18,
  'M11': 19,
  'year': 4,
};
// 季度转时间戳 source 2202-Q1、2202-M1（Q 季度，M 月）
export function translformDate(source) {
  const tempArr = source.split('-')
  const year = tempArr[0]
  const jidu = tempArr[1]
  let reportDate = ''
  let reportDateString = ''
  let coverReportDateString = ''
  switch (jidu) {
    case 'M1':
      reportDate = dayjs(year + '-01-02').valueOf()
      reportDateString = year + '年一月报'
      coverReportDateString = year + '年一月报'
      break
    case 'M2':
      reportDate = dayjs(year + '-02-02').valueOf()
      reportDateString = year + '年二月报'
      coverReportDateString = year + '年二月报'
      break
    case 'Q1':
      reportDate = dayjs(year + '-03-31').valueOf()
      reportDateString = year + '一季报'
      coverReportDateString = year + '年一季报'
      break
    case 'M4':
      reportDate = dayjs(year + '-04-02').valueOf()
      reportDateString = year + '年四月报'
      coverReportDateString = year + '年四月报'
      break
    case 'M5':
      reportDate = dayjs(year + '-05-02').valueOf()
      reportDateString = year + '年五月报'
      coverReportDateString = year + '年五月报'
      break
    case 'Q2':
      reportDate = dayjs(year + '-04-01').valueOf()
      reportDateString = year + '第二季度'
      coverReportDateString = year + '年第二季度'
      break
    case 'Q3':
      reportDate = dayjs(year + '-07-01').valueOf()
      reportDateString = year + '第三季度'
      coverReportDateString = year + '年第三季度'
      break
    case 'Q4':
      reportDate = dayjs(year + '-10-01').valueOf()
      reportDateString = year + '第四季度'
      coverReportDateString = year + '年第四季度'
      break
    case 'halfYear':
      reportDate = dayjs(year + '-06-30').valueOf()
      reportDateString = year + '半年报'
      coverReportDateString =
        year + '年半年报'
      break
    case 'M7':
      reportDate = dayjs(year + '-07-02').valueOf()
      reportDateString = year + '年七月报'
      coverReportDateString = year + '年七月报'
      break
    case 'M8':
      reportDate = dayjs(year + '-08-02').valueOf()
      reportDateString = year + '年八月报'
      coverReportDateString = year + '年八月报'
      break
    case '3Q':
      reportDate = dayjs(year + '-09-30').valueOf()
      reportDateString = year + '三季报'
      coverReportDateString =
        year + '年三季报'
      break
    case 'M10':
      reportDate = dayjs(year + '-10-02').valueOf()
      reportDateString = year + '年十月报'
      coverReportDateString = year + '年十月报'
      break
    case 'M11':
      reportDate = dayjs(year + '-11-02').valueOf()
      reportDateString = year + '年十一月报'
      coverReportDateString = year + '年十一月报'
      break
    case 'year':
      reportDate = dayjs(year + '-12-31').valueOf()
      reportDateString = year + '年报'
      coverReportDateString = year + '年年报'
      break
    default:
      break
  }
  return {reportDate, reportDateString, coverReportDateString}
};
// 时间戳转换为季度 source 1577808000000
export function transformTimestamp(source) {
  const date = dayjs.tz(source).format('YYYY/MM-DD')
  const md = date.split('/')[1]
  let quotaString = ''
  let reportQut = ''
  switch (md) {
    case '01-02':
      quotaString = '一月报'
      reportQut = 'M1'
      break
    case '02-02':
      quotaString = '二月报'
      reportQut = 'M2'
      break
    case '03-31':
      quotaString = '一季报'
      reportQut = 'Q1'
      break
    case '04-02':
      quotaString = '四月报'
      reportQut = 'M4'
      break
    case '05-02':
      quotaString = '五月报'
      reportQut = 'M5'
      break
    case '04-01':
      quotaString = '第二季度'
      reportQut = 'Q2'
      break
    case '07-01':
      quotaString = '第三季度'
      reportQut = 'Q3'
      break
    case '10-01':
      quotaString = '第四季度'
      reportQut = 'Q4'
      break
    case '06-30':
      quotaString = '半年报'
      reportQut = 'halfYear'
      break
    case '07-02':
      quotaString = '七月报'
      reportQut = 'M7'
      break
    case '08-02':
      quotaString = '八月报'
      reportQut = 'M8'
      break
    case '09-30':
      quotaString = '三季报'
      reportQut = '3Q'
      break
    case '10-02':
      quotaString = '十月报'
      reportQut = 'M10'
      break
    case '11-02':
      quotaString = '十一月报'
      reportQut = 'M11'
      break
    case '12-31':
      quotaString = '年报'
      reportQut = 'year'
      break

    default:
      break
  }
  return {quotaString, reportQut}
};
