export default {
  fieldManage:{
    "ceshi123": '测试123'
  },
  currencyConfig: {
    tianjiabizhong: '添加币种',
    zidonggengxinhuilv: '每日自动更新汇率',
    bianjibizhong: '编辑币种',
    bizhong: '币种',
    zidingyi: '自定义',
    qingshurubizhong: '请输入币种',
    daima: '代码',
    qingshurudaima: '请输入代码',
    fuhao: '符号',
    qingshurufuhao: '请输入符号',
    huilvgengxin: '汇率更新',
    huilvgengxin_tishi: '多币种汇总数据，将按设置的汇率进行统一换算（选择自动汇率将每日自动更新）',
    zidonggengxin: '自动更新',
    shoudonggengxin: '手动更新',
    daimacuowu_tishi: '代码只允许填写字母',
    baocun: '保存',
    chenggong: '成功',


  }
}