//i18n-setup.js

import zh from '@/subLang/zh/index.js'
import en from '@/subLang/en/index.js'

import axios from 'axios'
import { createI18n } from 'vue-i18n'

// Vue.use(VueI18n)

function add_mark(obj, mark){
  if(!obj) return;
  for(let key in obj){
    if(typeof obj[key] == 'string'){
      obj[key] += mark;
    }else{
      add_mark(obj[key], mark);
    }
  }
}

// 处理飞书右上角浏览器打开页面后#的位置问题 （和飞书人员确认过，这个url格式不会变了，所以直接写死兼容了，但愿真的不会变动了）
let href = location.href
let length = href.length
if (href.indexOf('#/') && href.indexOf('#/') + 2 === length) {
  
  let hash = href.slice(-2)
  href = href.slice(0, length - 2)
  let idx = href.indexOf('&open_in_browser=true')
  if (idx > -1) {
    href = href.replace(/\&open_in_browser=true\//g, hash)
  }
  location.href = href
}
// 处理飞书右上角浏览器打开页面后#的位置问题 end

var show_lang_check = location.hash.split('?')[1]?.match(/langc=([^&]*)(&|$)/g);
if(show_lang_check){
  show_lang_check = show_lang_check[0].split('=')[1];
  add_mark(zh.zh, show_lang_check);
}

export const i18n = createI18n({
   // 设置语言环境
  locale: (() => {
    if (localStorage.getItem('lang')) {
      return localStorage.getItem('lang');
    }
    return 'zh';
  })(),
  // fallbackLocale: 'zh',
  legacy: false,
  messages:{
    zh: zh.zh,
    en: en.en,
  } 
})

window.i18n = i18n;

// const loadedLanguages = ['en'] // 我们的预装默认语言

function setI18nLanguage (lang) {
  i18n.locale = lang
  localStorage.setItem('lang', lang);
  axios.defaults.headers.common['Accept-Language'] = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

// export function loadLanguageAsync(lang) {
//   // 如果语言相同
//   if (i18n.locale === lang) {
//     return Promise.resolve(setI18nLanguage(lang))
//   }

//   // 如果语言已经加载
//   if (loadedLanguages.includes(lang)) {
//     return Promise.resolve(setI18nLanguage(lang))
//   }

//   // 如果尚未加载语言
//   return import(/* webpackChunkName: "lang-[request]" */ `@/i18n/messages/${lang}.js`).then(
//     messages => {
//       i18n.setLocaleMessage(lang, messages.default)
//       loadedLanguages.push(lang)
//       return setI18nLanguage(lang)
//     }
//   )
// }