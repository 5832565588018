<template>
  <renderTags />
</template>

<script lang="tsx" setup>
import { onMounted, ref } from 'vue'
import { useResizeObserver } from '@vueuse/core'
import { Tooltip } from '@arco-design/web-vue'
import FileIcon from '@/components/fileIcon.vue'
import './renderFileTags.module.scss'

const props = defineProps<{ opts: any[]; type: 'user' | 'normal' | 'file' }>()

const wrapperRef = ref()
const containerWidth = ref(0)

const emit = defineEmits(['resize'])

const onPreviewFile = (url: LocalFile['url']) => {
  url && window.open(url)
}

onMounted(() => {
  useResizeObserver(wrapperRef, (entries) => {
    const entry = entries[0]
    const { width, height } = entry.contentRect

    containerWidth.value = width

    emit('resize', containerWidth.value)
  })
})

const renderTags = () => {
  const { opts = [], type } = props || {}
  const isUserTag = type === 'user'
  const isFileTag = type === 'file'

  return (
    <div
      ref={(el) => (wrapperRef.value = el)}
      class={{
        'render-tag-wrapper': true,
        'tag-wrapper--user': isUserTag,
        'tag-wrapper--file': isFileTag,
      }}
    >
      {opts.map((opt) => {
        const isMoreTag = opt.cType === 'more'
        const style = isMoreTag
          ? {}
          : isUserTag || isFileTag
          ? { background: 'transparent' }
          : { background: opt.colour }

        return (
          <span
            class={['file-tag', { more: opt.cType === 'more', clickable: isFileTag }]}
            key={opt.id}
            style={style}
            onClick={() => onPreviewFile(opt.url)}
          >
            {isUserTag &&
              opt.cType !== 'more' &&
              (opt.avatar ? (
                <img class="avatar-img" src={opt.avatar} />
              ) : (
                <span class="avatar-no-img">{opt.label[0]}</span>
              ))}

            {isFileTag && opt.cType !== 'more' && <FileIcon file={opt} size={16} />}

            {opt.displayedLabel !== opt.label ? (
              <Tooltip
                content={opt.label}
                defaultPopupVisible={false}
                contentClass="tags-more-content"
                v-slots={
                  opt.restTags?.length && {
                    content: () => (
                      <div class="content-wrapper">
                        {(opt.restTags ?? []).map((f: any) => {
                          return (
                            <div class="tooltip-file-tag-item" onClick={() => onPreviewFile(f.url)}>
                              <FileIcon file={f} size={16} />
                              <span class="label">{f.fileName}</span>
                            </div>
                          )
                        })}
                      </div>
                    ),
                  }
                }
              >
                <span class="label">{opt.displayedLabel}</span>
              </Tooltip>
            ) : (
              <span class="label">{opt.displayedLabel}</span>
            )}
          </span>
        )
      })}
    </div>
  )
}
</script>

<style lang="scss" scoped></style>

<style lang="scss">
.render-tag-wrapper {
  display: flex;
  align-items: center;

  .file-tag {
    display: flex;
    align-items: center;
    padding: 0 8px;
    height: 20px;
    line-height: 20px;
    border-radius: 10px;
    user-select: none;
    background-color: #eef0f1;
    cursor: pointer;
    &:not(.more) {
      &:hover {
        .label {
          color: #3370ff;
        }
      }
    }
    .label {
      transform: all ease-in-out 300ms;
    }
    &:first-child {
      // padding-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    &.more {
      cursor: initial;
      font-size: 12px;
      font-weight: 500;
      color: #1F2329;
      &:hover {
        background: #CCD1D0;
      }
    }
    // &.clickable {
    //   cursor: pointer;

    //   &:hover {
    //     .label {
    //       text-decoration: underline;
    //     }
    //   }
    // }
    .file-icon {
      margin-right: 6px;
    }
  }
}
.tags-more-content {
  padding: 8px 12px ;

  *::-webkit-scrollbar-track-piece {
    //滚动条凹槽的颜色，还可以设置边框属性
    background: #202328;
  }
  *::-webkit-scrollbar {
    //滚动条的宽度
    width: 6px;
    height: 6px;
  }
  *::-webkit-scrollbar-thumb {
    //滚动条的设置
    background-color: #636569;
    background-clip: padding-box;
  }

  .content-wrapper {
    max-width: 296px;
    max-height: 180px;
    overflow-y: auto;
    padding-right: 4px;
  }
  .tooltip-file-tag-item {
    margin-bottom: 6px;
    &:last-child {
      margin-bottom: 0;
    }
    cursor: pointer;
    &:hover {
      .label {
        text-decoration: underline;
      }
    }

    .label {
      font-family: PingFangSC-Regular;
      font-size: 12px;
      font-weight: normal;
      line-height: 20px;
      letter-spacing: 0px;
      color: white;
      padding-left: 2px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
