/**
 * 投前列表页项目阶段枚举
 * 样式渲染
 */
import { i18n } from '@/i18n-setup.js'
export const STAGE_MAPPING = {
  储备项目: {
    background: '#EAF6FF',
    border: '1px solid #C0DBFF',
  },
  项目立项: {
    background: '#FFF2DB',
    border: '1px solid #F6CCAA',
  },
  项目尽调: {
    background: '#E9F6F4',
    border: '1px solid #3AB7A8',
  },
  项目投决: {
    background: '#F9EBFF',
    border: '1px solid #E5CFFF',
  },
  协议签署: {
    background: '#F9F7D6',
    border: '1px solid #F1E89F',
  },
  项目交割: {
    background: '#DEF9D6',
    border: '1px solid #B3DCA7',
  },
  进入投后: {
    background: '#E9EBFF',
    border: '1px solid #D0CCFF',
  },
  放弃项目: {
    background: '#FFE5DE',
    border: '1px solid #F9BAAE',
  },
  default: {
    background: '#EEF0F1',
    border: '1px solid #EEF0F1'
  }
};
/**
 * 股权类型
 */
export const EQUITY_TYPE_LIST = [
  { label: '新股增发', value: '1' },
  { label: '老股转让', value: '2' },
  // { label: '新股+老股', value: '3' },
]
/**
 * 货币对应关系
 */
export const CURRENCY_MAPPING = {
  CNY: '¥',
  USD: '$'
}
/**
 * 不同类型的icon
 */
export const ICON_BY_TYPE = {
  text: 'icon-6', // 多行
  varchar: 'icon-3', // 单行
  date: 'icon-1',
  select: 'icon-icon-7 iconfont',
  cascader: 'icon-icon-7 iconfont',
  multiSelect: 'icon-8',
  percentage: 'icon-5',
  employee: 'icon--62x',
  number: 'icon-2',
  currency: 'icon-11',
  linkageCategory: 'icon-8',
  linkageArea: 'icon-8',
  file: 'icon-icon_12 iconfont',
}
/**
 * 交易类型 1=收入，2=管理费召款，3=出资召款，4=项目退出收益，5=项目分红收益，6- 其他收入，11=支出，12=退出收益分配，13=分红收益分配，14=其他收益分配，15=项目投资，16=其他支出';
 */
export const CAPITAL_FLOW_TYPE = {
  in: [
    { value: 2, label: '管理费召款', colour: '#FFD2D3' },
    { value: 3, label: '出资召款', colour: '#FFD2D3' },
    { value: 4, label: '项目退出收益', colour: '#FFD2D3' },
    { value: 5, label: '项目分红收益', colour: '#FFD2D3' },
    { value: 6, label: '其他收入', colour: '#FFD2D3' },
  ],
  out: [
    {value: 21, label: '退出分配（成本）', colour: '#D2F7D2'},
    {value: 22, label: '退出分配（收益）', colour: '#D2F7D2'},
    {value: 12, label: '退出分配（总计）', colour: '#D2F7D2'},
    {value: 13, label: '分红分配', colour: '#D2F7D2'},
    {value: 14, label: '其他分配', colour: '#D2F7D2'},
    {value: 15, label: '项目投资', colour: '#D2F7D2'},
    {value: 16, label: '其他支出', colour: '#D2F7D2'}
  ],
}
export const CAPITAL_FLOW_TYPE_MAPS = [
  { 
    value: 2, 
    label: '管理费召款', 
    colour: '#FFD2D3', 
    maps: {
      value: 16, 
      label: '其他支出', 
      colour: '#D2F7D2'
    }},
  { value: 3, label: '出资召款', colour: '#FFD2D3', maps: {value: 15, label: '项目投资', colour: '#D2F7D2'}},
  { value: 4, label: '项目退出收益', colour: '#FFD2D3', maps: {value: 12, label: '退出分配（总计）', colour: '#D2F7D2'}},
  { value: 5, label: '项目分红收益', colour: '#FFD2D3', maps:   {value: 13, label: '分红分配', colour: '#D2F7D2'}},
  { value: 6, label: '其他收入', colour: '#FFD2D3', maps: {value: 16, label: '其他支出', colour: '#D2F7D2'} },
  { value: 21, label: '退出分配（成本）', colour: '#D2F7D2', maps: { value: 4, label: '项目退出收益', colour: '#FFD2D3' }},
  { value: 22, label: '退出分配（收益）', colour: '#D2F7D2', maps: { value: 4, label: '项目退出收益', colour: '#FFD2D3' }},
  { value: 12, label: '退出分配（总计）', colour: '#D2F7D2', maps: { value: 4, label: '项目退出收益', colour: '#FFD2D3' }},
  { value: 13, label: '分红分配', colour: '#D2F7D2', maps: { value: 5, label: '项目分红收益', colour: '#FFD2D3' }},
  { value: 14, label: '其他分配', colour: '#D2F7D2', maps: { value: 6, label: '其他收入', colour: '#FFD2D3' }},
  { value: 15, label: '项目投资', colour: '#D2F7D2', maps: { value: 3, label: '出资召款', colour: '#FFD2D3' }},
  { value: 16, label: '其他支出', colour: '#D2F7D2', maps: { value: 6, label: '其他收入', colour: '#FFD2D3' }}
]
/**
 * 审批状态
 */
// export const APPROVAL_STATUS = {
//   PENDING: '审批中',
//   APPROVED: '已通过',
//   REJECTED: '已拒绝',
//   CANCELED: '已撤销',
//   DELETED: '已撤销',
//   NOTSTARTED: '未发起' // 此状态仅用于前端处理
// }
export const APPROVAL_STATUS = {
  PENDING: i18n.global.t('plat_c.allTabs.com.shenpizhong'),
  APPROVED: i18n.global.t('plat_c.allTabs.com.yitongguo'),
  REJECTED: i18n.global.t('plat_c.allTabs.com.yijujue'),
  CANCELED: i18n.global.t('plat_c.allTabs.com.yichexiao'),
  DELETED: i18n.global.t('plat_c.allTabs.com.yichexiao'),
  NOTSTARTED: i18n.global.t('plat_c.allTabs.com.weifaqi'), // 此状态仅用于前端处理
  MODIFIED: '已通过'
}

// 基金类型
const FUND_TYPE_LIST = [
  {
    "label": "保险公司及其子公司的资产管理计划",
    "value": "1"
  },
  {
    "label": "信托计划",
    "value": "2"
  },
  {
    "label": "债权投资基金",
    "value": "3"
  },
  {
    "label": "其他",
    "value": "4"
  },
  {
    "label": "其他私募投资基金",
    "value": "5"
  },
  {
    "label": "创业投资基金",
    "value": "6"
  },
  {
    "label": "创业投资类FOF基金",
    "value": "7"
  },
  {
    "label": "基金专户",
    "value": "8"
  },
  {
    "label": "基金中的基金",
    "value": "9"
  },
  {
    "label": "基金子公司",
    "value": "10"
  },
  {
    "label": "夹层基金",
    "value": "11"
  },
  {
    "label": "契约型",
    "value": "12"
  },
  {
    "label": "契约型债券投资基金",
    "value": "13"
  },
  {
    "label": "契约型基金",
    "value": "14"
  },
  {
    "label": "契约型基金、REITs基金",
    "value": "15"
  },
  {
    "label": "契约型私募基金",
    "value": "16"
  },
  {
    "label": "契约型私募股权投资基金",
    "value": "17"
  },
  {
    "label": "契约型股权投资基金",
    "value": "18"
  },
  {
    "label": "契约性基金",
    "value": "19"
  },
  {
    "label": "并购基金",
    "value": "20"
  },
  {
    "label": "房地产基金",
    "value": "21"
  },
  {
    "label": "房地产基金(契约型)",
    "value": "22"
  },
  {
    "label": "期货公司及其子公司的资产管理计划",
    "value": "23"
  },
  {
    "label": "母基金",
    "value": "24"
  },
  {
    "label": "私募股权投资基金",
    "value": "25"
  },
  {
    "label": "私募股权投资类FOF基金",
    "value": "26"
  },
  {
    "label": "私募证券投资基金",
    "value": "27"
  },
  {
    "label": "私募资产配置基金",
    "value": "28"
  },
  {
    "label": "联接基金",
    "value": "29"
  },
  {
    "label": "股权与债权混合投资",
    "value": "30"
  },
  {
    "label": "股权债券混合型",
    "value": "31"
  },
  {
    "label": "股权及债权投资",
    "value": "32"
  },
  {
    "label": "股权投资基金",
    "value": "33"
  },
  {
    "label": "证券公司及其子公司的资产管理计划",
    "value": "34"
  },
  {
    "label": "银行理财产品",
    "value": "35"
  }
]

export const FUND_TYPE_OBJ = FUND_TYPE_LIST.reduce((res, item) => {
  res[item.value] = item.label
  return res
}, {})
