<template>
  <render />
</template>

<script lang="tsx" setup>
import { computed, onMounted, ref, watchEffect } from 'vue'
import { onClickOutside } from '@vueuse/core'
import { Tooltip } from '@arco-design/web-vue'
import { CalcWorker } from '@/components/tagUsers/getCalcWorker'
import FileIcon from '@/components/fileIcon.vue'
import AutoTooltip from '@/components/AutoTooltip.vue'
import TagSelect from '@/components/tagSelect/tagSelect.vue'
import Iconfont from '@/components/Iconfont.vue'
import CirclePercent from '@/components/CirclePercent/CirclePercent.vue'
import { useI18n } from 'vue-i18n'

const { t: i18n_t } = useI18n()
const emit = defineEmits(['open', 'delete', 'reupload', 'updateFileTags', 'actionClick'])

const workerInstance = CalcWorker.prototype.getWorkerInstance()
const EMPTY_TYPE = '9999'

const props = defineProps<{
  file: LocalFile | UploadFile
  typeList: any[]
  isTmp?: boolean
  popupContainer?: string
}>()
const showTagList = ref<any[]>([])
const renderEdit = ref(false)
const fileItemRef = ref()

const visibleTypeList = computed(() => {
  return props.typeList
    .filter((type) => type.id !== EMPTY_TYPE) // 处理后端的傻逼设计
    .map((type) => {
      return {
        ...type,
        color: '#DDEAFF',
      }
    })
})
const typeIdNameMap = computed(() => {
  return visibleTypeList.value.reduce((rs, type) => {
    return {
      ...rs,
      [type.id]: type.name,
    }
  }, {})
})

const fileTagList = computed(() => {
  const tags = (props.file.fileTags ?? [])
    .map((id) => {
      const name = typeIdNameMap.value[id]

      return {
        id,
        name,
      }
    })
    .filter((tag) => {
      return tag.name ?? false
    })

  return tags
})
const visibleTags = computed(() => {
  return fileTagList.value.filter((tag) => {
    return tag.id !== EMPTY_TYPE // 处理后端的傻逼设计
  })
})

watchEffect(() => {
  const workerData = JSON.stringify({
    message: 'NormalTag',
    data: {
      tags: visibleTags.value,
      labelKey: 'name',
      containerWidth: 280,
    },
  })

  workerInstance.postMessage(workerData).then((res) => {
    const data = res || {}
    // const { showTagList = [], editTagList = [] } = data
    showTagList.value = data.showTagList || []
  })
})

onMounted(() => {
  onClickOutside(fileItemRef, () => {
    renderEdit.value = false
  })
})

const onOpenFile = (file: LocalFile) => {
  // emit('open', file)
  file.url && window.open(file.url)
}
const onDeleteFile = (file: LocalFile | UploadFile, evt: MouseEvent) => {
  emit('delete', file)
  emit('actionClick', evt)
}
const onReupload = (file: UploadFile, evt: MouseEvent) => {
  emit('reupload', file)
  emit('actionClick', evt)
}

const isTmpFile = (file: LocalFile | UploadFile): file is UploadFile => {
  return Boolean(props.isTmp)
}

const render = () => {
  return (
    <div class="file-item">
      <FileIcon file={props.file} size={24} />
      <div class={['upload-file-info', props.isTmp && 'tmp-file']}>
        <div class="file-item-name">
          <AutoTooltip content={props.file.fileName} />
        </div>
        {!props.isTmp && (
          <div class="file-item-tags" ref={(el) => (fileItemRef.value = el)}>
            {renderEdit.value ? <renderEditTagList /> : <renderTagList />}
          </div>
        )}
      </div>

      <div class="file-action">
        {isTmpFile(props.file) ? (
          <>
            {props.file.state === 'error' ? (
              <>
                <Tooltip content='重试' position="left" popup-container={props.popupContainer}>
                  <Iconfont name="icon_warn"  onClick={(evt: MouseEvent) => onReupload(props.file as UploadFile, evt)} />
                </Tooltip>

                <Tooltip content={i18n_t('plat_c.app_c.general_btn.shanchu')} position="right" popup-container={props.popupContainer}>
                  <Iconfont name="icon_close_med" onClick={(evt: MouseEvent) => onDeleteFile(props.file, evt)} />
                </Tooltip>
              </>
            ) : (
              <CirclePercent
                scale={0.5}
                value={props.file.progress}
                strokeWidth={2}
                size={32}
                color="#3370FF"
                type="left"
              />
            )}
          </>
        ) : (
          <>
            {
              props.file.url ? <Tooltip content={i18n_t('plat_c.app_c.form.dakai')} position="left" popup-container={props.popupContainer}>
                <Iconfont name="icon_search" onClick={() => onOpenFile(props.file)} />
              </Tooltip> : ''
            }
            <Tooltip
              content={i18n_t('plat_c.app_c.general_btn.shanchu')}
              position="right"
              popup-container={props.popupContainer}
              v-slots={{
                content: () => <div style="display: block;width: 24px;">{i18n_t('plat_c.app_c.general_btn.shanchu')}</div>,
              }}
            >
              <Iconfont name="icon_close_med" onClick={(evt: MouseEvent) => onDeleteFile(props.file, evt)} />
            </Tooltip>
          </>
        )}
      </div>
    </div>
  )
}
const handleFileTagUpdate = (v: string[]) => {
  const tmp = v.length ? v.filter((i) => i !== EMPTY_TYPE) : []
  const value = tmp.length ? tmp : [EMPTY_TYPE] // 处理后端傻逼设计
  tmpTagList.value = value

  emit('updateFileTags', props.file, value)
}
const fileTagsValue = computed(() => props.file.fileTags ?? [])
const tmpTagList = ref<string[]>(fileTagsValue.value)
const computedTmpTagList = computed(() => {
  return tmpTagList.value.filter((i) => i !== EMPTY_TYPE)
})

const renderEditTagList = () => {
  return (
    <div class="editable">
      {
        <TagSelect
          modelValue={computedTmpTagList.value}
          options={visibleTypeList.value}
          placeholder={i18n_t('plat_c.app_c.general_btn.qingxuanzebiaoqian')}
          type="multiple"
          from="filter"
          zIndex={9999}
          optionKeys={{
            label: 'name',
            id: 'id',
            color: 'color',
          }}
          defaultPopupVisible={true}
          optionHeight="200px"
          onUpdate:modelValue={handleFileTagUpdate}
        />
      }
    </div>
  )
}

const onSwitchEdit = (v: any) => {
  tmpTagList.value = []
  v.value &&
    v.value.forEach((item: any) => {
      tmpTagList.value.push(item.id)
    })
  renderEdit.value = true
}
const renderTagList = () => {
  return (
    <div
      class="tag-wrapper"
      onClick={() => {
        // onSwitchEdit(showTagList) // showTagList 有问题，当出现+n情况后 它里面不是完整的已选标签项
        onSwitchEdit(visibleTags)
      }}
    >
      {showTagList.value?.length ? (
        showTagList.value.map((tag: any) => {
          return (
            <div class="file-tag">
              {
                // tag.cType !== 'more' && <FileIcon file={tag} size={16} />
              }

              {tag.displayedLabel !== tag.label ? (
                <Tooltip content={tag.label}>
                  <span class="label">{tag.displayedLabel}</span>
                </Tooltip>
              ) : (
                <span class="label">{tag.displayedLabel}</span>
              )}
            </div>
          )
        })
      ) : (
        <div class="empty">{i18n_t('plat_c.app_c.form.tianjiabiaoqian')}</div>
      )}
    </div>
  )
}
</script>

<style lang="scss" scoped>
.file-item {
  height: 58px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.08);
  box-sizing: border-box;
  border: 1px solid #dee0e3;
  padding: 0 16px 0 12px;
  display: flex;
  align-items: center;
  margin-top: 10px;
  position: relative;
  &:first-child {
    margin-top: 0;
  }
  &:hover {
    background: #eceeee;
  }
  &.file-no-select-tag {
    height: 44px;
    :deep() {
      .file-icon {
        width: 20px !important;
        height: 20px !important;
      }
      .upload-file-info {
        justify-content: center;
        padding-top: 0;
        .file-item-name {
          margin-left: 6px;
        }
        .file-item-tags {
          display: none;
        }
      }
      .icon-icon_search {
        display: none !important;
      }
    }
  }
  .file-icon {
    // margin-right: 10px;
  }
  :deep() {
    .upload-file-info {
      width: 290px;
      display: flex;
      flex-direction: column;
      height: 100%;
      padding-top: 4px;
      &.tmp-file {
        padding-top: 0;
        justify-content: center;
      }
      .file-item-name {
        margin-left: 10px;
        height: 20px;
        font-family: PingFangSC-Regular;
        font-size: 14px;
        font-weight: normal;
        line-height: 20px;
        letter-spacing: 0px;
        color: #1f2329;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .file-item-tags {
        font-family: PingFangSC-Regular;
        font-size: 14px;
        font-weight: normal;
        letter-spacing: 0px;
        color: #86909c;
        .editable {
          padding-left: 6px;
          position: relative;
          .custom-tag-list-outer {
            padding: 0;
            width: 280px;
            height: 28px;
            border-radius: 6px;
            background: #ffffff;
            box-sizing: border-box;
            border: 1px solid #3371ff;
            .tag-list-module {
              padding-top: 2px;
            }
            .placeholder {
              .placeholder-text {
                line-height: 24px;
                padding: 0 10px;
              }
            }
            .tag-item {
              background-color: #ddeaff;
            }
            .icon-tmp {
              margin-right: 6px;
            }
            .filter-list {
              padding-left: 6px;
            }
          }
        }
        .tag-wrapper {
          display: flex;
          margin-top: 6px;
          height: 20px;
          line-height: 20px;
          padding-left: 10px;
          cursor: pointer;
          .empty {
          }
          .file-tag {
            border-radius: 10px;
            background: #ddeaff;
            padding: 1px 8px;
            color: #1f2329;
            height: 20px;
            font-family: PingFangSC-Medium;
            font-size: 12px;
            font-weight: 500;
            line-height: 20px;
            margin-left: 4px;
            &:first-child {
              margin-left: 0;
            }
            .label {
              line-height: 18px;
            }
          }
        }
      }
    }
    .file-action {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      flex-direction: row;
      display: flex;
      .iconfont {
        color: #646a73;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        margin-left: 4px;
        border-radius: 6px;
        transition: all ease 300ms;
        cursor: pointer;
        &:hover {
          background: rgba(31, 35, 41, 0.08);
        }
        &.icon-icon_warn {
          color: #F2645E;
          &:hover {
            &:before {
              content: "\e6b7";
              color: #646A73;
            }
          }
        }
      }
    }
  }
}
</style>
