<script lang="tsx">
import { defineComponent } from 'vue'
export default defineComponent({
  name: "CurrencyInput",
  
})
</script>
<template>
<a-trigger
  v-model:popup-visible="popupVisible"
  :click-to-close="false"
  :update-at-scroll="true"
  :disabled="!showInCompanyValuation || !jrgsgzValue"
  class="inCompanyValuation-popup"
  trigger="click"
  :popup-translate="[0, -4]"
  style="width: 240px"
  :click-outside-to-close="false"
  @popup-visible-change="handlerPopVisibleVhange"
>
  <template #content>
    <div class="popover-content" @click="handlerShowPop">
      <label :class="['jzr', 'isRequired']">基准日</label>
      <a-date-picker
        v-model="jjgzDate"
        size="small"
        value-format="timestamp"
        placeholder="请选择"
        :allow-clear="false"
        popup-container=".inCompanyValuation-popup"
        @change="handlerDateChange"
        @popup-visible-change="handlerDateVisibleChange"
      >
        <template #cell="{date}">
          <template v-if="cusDisabledFun(date)">
            <a-tooltip :content="getContent(date)">
              <div :class="['cus-date-picker-cell', 'arco-picker-date', {diabled: cusDisabledFun(date)}]" @click.stop>
                <span class="arco-picker-date-value">{{ dayjs(date).format('DD') }}</span>
              </div>
            </a-tooltip>
          </template>
          <template v-else>
            <div class="cus-date-picker-cell arco-picker-date">
              <span class="arco-picker-date-value">{{ dayjs(date).format('DD') }}</span>
            </div>
          </template>
        </template>
      </a-date-picker>
    </div>
  </template>
  <a-tooltip v-model:popup-visible="tooltipShow" :disabled="!jrgsgzValue || popupVisible || !showInCompanyValuation" :content="`${jjgzDate}` ? '基准日' + `${dayjs(jjgzDate).format('YYYY-MM-DD')}` : '未选择基准日'">
  <LocaleStringInput
    ref="localeStringInputRef"
    :class="['currency-suffix', switchable ? '' : 'disabled']"
    :id="`currency-${_uuid}`"
    :model-value="tmp?.num"
    :element-props="elementProps"
    :display-formatted-value="displayFormattedValue"
    :outer-ctrl="outerCtrl"
    :placeholder="placeholder"
    :disabled="disabled"
    :is-allow-negative="isAllowNegative"
    :showCusValid="showCusValid"
    @focus="onFocus"
    @blur="onBlur"
    @update:model-value="handleUpdateNum"
  >
    <template #suffix v-if="!hideSuffix">
      <SwitchCurrencyPopover
        ref="switchCurrency"
        v-model:visible="switcherVisible"
        :contentClassName="contentClassName"
        :currency="tmp.currency"
        :disabled="disabled"
        :switchable="switchable"
        :renderToBody="isPopupContainer"
        :popup-container="isPopupContainer? null :`#currency-${_uuid}`"
        :show-c-n-name="false"
        :customCurrencyLists="customCurrencyLists"
        position="br"
        @update-currency="handleSwitchCurrency"
      />
      <!-- <template v-if="isCurrency">
      </template>
      <template v-else-if="isRatio"> % </template>

      <template v-else-if="$slots.suffix">
        <slot name="suffix" />
      </template> -->
    </template>
  </LocaleStringInput>
  </a-tooltip>
</a-trigger>
</template>

<script lang="tsx" setup name="CurrencyInput">
import {
  computed,
  nextTick,
  onMounted,
  PropType,
  ref,
  watchEffect,
  watch,
  useSlots,
  reactive,
} from 'vue'
import { currencyMappingStore, ICurrency } from '@/store/useCurrencyStore'
import uuid from '@/utils/uuid'
import Iconfont from '../Iconfont.vue'
import LocaleStringInput from '../LocaleStringInput.vue'
import SwitchCurrencyPopover from './switchCurrencyPopover.vue'
import {
  mapCurrency,
  formatData,
} from '@/utils/currency.js'
import dayjs from 'dayjs'
import { Message } from '@arco-design/web-vue'

const { getCurrencySymbol, currencySymbolList } = currencyMappingStore()

const emit = defineEmits(['update:model-value', 'update:currency', 'focus', 'blur', 'value-change', 'updateJzrValue'])
const slots = useSlots()
const props = defineProps({
  contentClassName: String as PropType<string>,
  elementProps: Object as PropType<object>,
  modelValue: Object as PropType<{ num: string; currency: string }>,
  currency: String as PropType<string | null>,
  isPopupContainer:Boolean as PropType<boolean>,
  displayFormattedValue: Boolean as PropType<boolean>,
  outerCtrl: Boolean as PropType<boolean>,
  placeholder: String as PropType<string>,
  disabled: Boolean as PropType<boolean>,
  showInCompanyValuation: Boolean as PropType<boolean>,
  isRequired: Boolean as PropType<boolean>,
  jrgsgzValue: Boolean as PropType<boolean>,
  jzrDateValue: [String, Number] as PropType<string | number>,
  isAllowNegative: {
    type: Boolean as PropType<boolean>,
    default: true,
  },
  // isRatio: Boolean as PropType<boolean>,
  // isCurrency: Boolean as PropType<boolean>,
  switchable: Boolean as PropType<boolean>, // 币种是否支持切换
  hideSuffix: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  isFocus: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  showCusValid: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  allDisabledDateList: {
    type: Array,
    default: () => []
  },
  customCurrencyLists: {
    type: Array,
    default: () => []
  }
})

const _uuid = uuid()
const localeStringInputRef = ref()
const blur = () => {
  localeStringInputRef.value?.blur()
}
defineExpose({ localeStringInputRef, blur })

const jjgzDate = ref<string | number>('')
watch(
  ()=> props.jzrDateValue,
  (v) => {
    if (v) {
      jjgzDate.value = Number(v)
    } else {
      jjgzDate.value = ''
    }
  },
  {
    immediate: true
  }
)
const popupVisible = ref(false)
const tooltipShow = ref(false)
const cusDisabledFun = (cur: string | number) => {
  let disabledList: Array<string | number> = []
  props.allDisabledDateList?.forEach((item: any) => {
    disabledList.push(dayjs(Number(item.date)).format('YYYY-MM-DD'))
  })
  return disabledList.includes(dayjs(cur).format('YYYY-MM-DD'))
}
const getContent = (cur: string | number) => {
  const date = dayjs(dayjs(cur).startOf('day')).valueOf()
  const curObj: any = props.allDisabledDateList?.find((item: any) => item.date == date)
  if (!curObj) return ''
  const value = JSON.parse(curObj?.value)
  const content = `公司估值：${mapCurrency(value?.currency)}${formatData(value.num)}`
  return content
}
const handlerPopVisibleVhange = (val: boolean) => {
  popupVisible.value = val
}
const handlerDateChange = (v: string) => {
  popupVisible.value = false
  emit('updateJzrValue', v)
}

const valueChange =()=>{
  emit('value-change')
}
const tmp = reactive<{ num: string; currency: string }>({
  num: '',
  currency: props.currency ?? '',
})
const handleUpdateNum = (value: number) => {
  tmp.num = (value ?? '').toString()
  handleUpdateValue()
}

const handleSwitchCurrency = (en: string) => {
  tmp.currency = en
  handleUpdateValue()
  emit('value-change', tmp)
  hideSwitchCurrencyPop()
}

watchEffect(() => {
  Object.assign(tmp, props.modelValue)
})

watch(
  () => props.currency,
  (n, o) => {
    if (n && n !== o) {
      handleSwitchCurrency(n)
    }
  },
)

const inputFocus = () => {
  popupVisible.value = true
  localeStringInputRef.value?.focus()
}
watch(
  () => props.isFocus,
  (v) => {
    v ? inputFocus() : popupVisible.value = false
  }
)

const switcherVisible = ref(false)
const isFocusing = ref(false)
const onFocus = (v: any) => {
  tooltipShow.value = false
  isFocusing.value = true
  emit('focus', v)
}
let time: any = null
const handlerSetPopVisible = () => {
  const dom = document.querySelector('.inCompanyValuation-popup')?.querySelector('.arco-picker')
  if (props.jrgsgzValue && props.jzrDateValue) {
    time = setTimeout(() => {
      popupVisible.value = false
      dom?.classList.remove('required')
    }, 200);
  }
  if (props.jrgsgzValue && !props.jzrDateValue) {
    nextTick(() => {
      dom?.classList.add('required')
    })
  }
}
const onBlur = async (v: any) => {
  handlerSetPopVisible()

  isFocusing.value = false
  await nextTick()
  emit('blur')
}
const handlerShowPop = () => {
  time && clearTimeout(time)
  time = null
  popupVisible.value = true
}
const handlerDateVisibleChange = (v: boolean) => {
  !v && handlerSetPopVisible()
}

const switchCurrency = ref()
const hideSwitchCurrencyPop = () => {
  switchCurrency?.value && switchCurrency.value.handleUpdatePopoverVisible(false)
}

watch(
  () => [isFocusing.value, props.switchable ? switcherVisible.value : false],
  (n) => {
    if (!n[0] && !n[1]) {
      handleRealBlur()
    }
    if (!n[1]) {
      hideSwitchCurrencyPop()
    }
  },
)


const handleRealBlur = () => {
  handleUpdateValue()
  emit('value-change')
  emit('blur')
}

const handleUpdateValue = (v?: string | number) => {
  emit('update:model-value', tmp)
}
</script>

<style lang="scss">
.inCompanyValuation-popup {
  // box-shadow: 0px 0px 10px #c7c7c7;
  box-shadow: 0px 2px 16px 0px rgb(0 0 0 / 6%);
  margin-top: 10px;
  .arco-picker-footer {
    display: none;
  }
  // border-radius: 4px;
  .popover-content {
    display: flex;
    align-items: center;
    width: 240px;
    padding: 12px;
    border-radius: 6px;
    background: #FFFFFF;
    box-sizing: border-box;
    border: 1px solid #DEE0E3;
    box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.06);
    .jzr {
      flex-shrink: 0;
      font-family: Source Han Sans CN;
      font-size: 14px;
      font-weight: normal;
      line-height: 20px;
      color: #1F2329;
      margin-right: 8px;
      position: relative;
      &.isRequired {
        padding-right: 10px;
        &::after {
          content: '*';
          color: red;
          position: absolute;
          top: 2px;
          right: 0;
        }
      }
    }
    .arco-picker {
      flex-grow: 1;
      &.required {
        border-color: red;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.currency-suffix {
  :deep(.arco-input-suffix) {
    padding-left: 0px;
    border-left: 1px solid #DEE0E3;
    transition: all 200ms ease;
    padding-right: 8px;
    margin-right: -7px;
    border-radius: 0 3px 3px 0 ;
    &:hover,
    &.focus {
      background: #ECEEEE;
    }
  }
  &.disabled {
    :deep(.arco-input-suffix) {
      &:hover,
      &.focus {
        background: none;
      }
    }
  }
}
</style>
