<template>
  <div ref="tagListModule" :class="['tag-user-list-module', from]">
    <!-- 筛选设置中只有一种状态 -->
    <template v-if="from == 'filter'">
      <div
        :class="{
          'filter-list': true,
          placeholder: !filterList?.length,
        }"
      >
        <span v-if="!filterList?.length" class="placeholder-text">
          {{ placeholder || $t('plat_c.app_c.form.qingxuanze') }}
        </span>
        <span
          v-for="(item, index) in filterList"
          :class="['tag-items', { more: item.cType == 'more' }, { user: meta?.showAvatar }]"
          :key="item.id"
          :style="
            'background:' + item.color + (item.cType == 'more' ? ';width:' + item.cWidth : '')
          "
        >
          <template v-if="item.cType != 'more' && meta?.showAvatar">
            <img v-if="item.avatar" class="avatar-img" :src="item.avatar" />
            <span v-else class="avatar-no-img">{{ item.label[0] }}</span>
          </template>
          <a-tooltip v-if="item.displayedLabel != item.label" :content="item.label">
            <span class="label">{{ item.displayedLabel }}</span>
          </a-tooltip>
          <span v-else class="label">{{ item.displayedLabel }}</span>
          <i
            v-if="item.cType != 'more'"
            class="icon-del iconfont icon-icon_close_med"
            @click.stop="handleDel(index)"
          />
        </span>
      </div>
      <!-- <i class="icon-arrow iconfont icon-icon_d_arrow"/> -->
      <i class="icon-tmp icon-2x10 filter-arrow-icon" />
    </template>
    <template v-else-if="from ==='indicator'">
      <div class="show-list">
        <span
          v-for="(item, index) in computedShowList"
          :class="['tag-items', { more: item.cType == 'more' }, customClass, {user: meta.showAvatar}]"
          :key="item.id"
          :style="
            'background:' + item.color + (item.cType == 'more' ? ';width:' + item.cWidth : '')
          "
        >
          <a-tooltip v-if="item.displayedLabel != item.label">
            <template #content>
              <template v-if="item.cType =='more'">
                <div class="cur-pointer" v-for="(child,k) in item.label" :key="k" @click.stop="tofileUrl(child)">
                  <fileIcon style="vertical-align: middle;" v-if="child.fileType" :file="child" size="16"></fileIcon>
                  {{child.label}}
                </div>
              </template>
              <template v-else>
                <div>{{item.label}}</div>
              </template>
            </template>
            <span @click.stop="tofileUrl(item)" class="label" :style="{'max-width':item.maxWidth}">
              <fileIcon style="vertical-align: middle;margin-right:4px" v-if="item.fileType" :file="item" size="16"></fileIcon>
              <span class="hovers">{{ item.displayedLabel }}</span>
            </span>
          </a-tooltip>
          <span @click.stop="tofileUrl(item)" v-else class="label" :style="{'max-width':item.maxWidth}">
            <fileIcon style="vertical-align: middle;" v-if="item.fileType" :file="item" size="16"></fileIcon>
            {{ item.displayedLabel }}
          </span>
        </span>
      </div>
    </template>
    <template v-else-if="state == 0">
      <div :class="[ meta?.showDelete ? 'edit-list' : 'show-list']">
        <span
          v-for="(item, index) in computedShowList"
          :class="['tag-items', { more: item.cType == 'more' }, customClass, {user: meta?.showAvatar}]"
          :key="item.id"
          :style="
            'background:' + (item.color || item?.colour || '#EFF0F1') + (item.cType == 'more' ? ';width:' + item.cWidth : '')
          "
          @click="handlerTagClick(item)"
        >
          <template v-if="item.cType != 'more' && meta?.showAvatar">
            <template v-if="!isFile">
              <img v-if="item.avatar" class="avatar-img" :src="item.avatar" />
              <span v-else class="avatar-no-img">{{ item.label[0] }}</span>
            </template>
            <fileIcon class="avatar-img" v-else :file="item" size="16" />
          </template>
          <a-tooltip v-if="item.displayedLabel != item.label" :content="item.label">
            <span class="label">
              {{ item.displayedLabel || item.label }}
            </span>
          </a-tooltip>
          <span v-else class="label" :style="{'max-width':item.maxWidth}">
            {{ item.displayedLabel || item.label }}
          </span>
          <i
            v-if="item.cType != 'more' && meta?.showDelete"
            class="icon-del iconfont icon-icon_close_med"
            @click.stop="handleDel(index)"
          />
        </span>
      </div>
    </template>
    <template v-else>
      <div class="edit-list">
        <div class="item-list">
          <div
            :class="['tag-items', { user: meta?.showAvatar }]"
            v-for="(item, index) in editList"
            :key="item.id"
            :style="'background:' + item.color"
          >
            <template v-if="item.cType != 'more' && meta?.showAvatar">
              <img v-if="item.avatar" class="avatar-img" :src="item.avatar" />
              <span v-else class="avatar-no-img">{{ item.label[0] }}</span>
            </template>
            <a-tooltip v-if="item.displayedLabel != item.label" :content="item.label">
              <span class="label">{{ item.displayedLabel }}</span>
            </a-tooltip>
            <span v-else class="label">
              {{ item.displayedLabel }}
              </span>
            <i
              class="icon-del iconfont icon-icon_close_med"
              @click.stop.prevent="handleDel(index)"
            />
          </div>
        </div>
        <i class="icon-arrow iconfont icon-icon_d_arrow" />
      </div>
    </template>
  </div>
</template>
<script>
import fileIcon from '@/components/fileIcon.vue'
import _, { debounce } from 'lodash'
import { useResizeObserver } from '@vueuse/core'

const W_AVATAR = 20 //头像宽高
const W_AVATAR_MAR = 6 //头像右侧间距
// const W_FULL = 12 //汉字宽度，跟font-size大小一样
const W_HALF = 10 //字母数字符号宽度, 字符从7~10不等，字母约为8.7，数字约为7.2 不再精确计算
const W_PLUS = 9 //+号宽度7.2
const W_PAD = 8 //颜色块左右padding宽度，通用
const W_PAD_L = 2 //颜色块左右padding宽度，头像左侧
const W_PAD_R = 8 //颜色块左右padding宽度, 数字左右宽度都是8
const W_MAR = 4 //tag之间宽度
const W_DOT = 10 //3个点省略号宽度
const W_XX = 10 //x号宽度
const W_ARROW = 10 //下拉箭头宽度
const NUM_COLOR = '#eef0f1'
const DEL_ICON_WIDRH = 18 // 删除 icon 的宽度 12 + 左边距 6

export default {
  name: 'UserTagList',
  components: {
    fileIcon,
  },
  props: {
    from: {
      type: String,
      default: 'normal',
    }, //组件使用来源，filter筛选，normal //file 列表展示文件 // indicator 关键指标
    tags: Array,
    state: Number, //0为展示态，1为编辑态
    containerWidth: {
      type: Number,
      default: null,
    },
    workerComputedData: {
      type: Object,
      default: () => ({}),
    },
    meta: {
      type: Object,
      default: () => ({}),
    },
    labelKey: {
      type: String,
      default: 'label',
    },
    placeholder: {
      type: String,
      default: '',
    },
    customClass: {
      type: String,
      default: '',
    },
    isFile: {
      type: Boolean,
      default: false,
    },
    fontSize: {
      type: Number,
      default: 12,
    }
  },
  data() {
    return {
      fullWidth: 0,
      rawTags: [], //存储原始数组
      filterList: [], //筛选类型的选项列表
      showList: [], //展示态的选项列表
      editList: [], //编辑态选项列表
    }
  },
  watch: {
    tags: {
      immediate: true,
      deep: true,
      handler(v) {
        if (this.workerComputedData?.showTagList?.length) {
          // this.showList = this.workerComputedData.showTagList || []
          // this.editList = this.workerComputedData.editTagList || []
        } else {
          this.rawTags = _.cloneDeep(v) || []
          this.formatTags()
        }
      },
    },
  },
  computed: {
    W_FULL() {
      // return this.fontSize
      return this.fontSize == 12 ? this.fontSize : this.fontSize + 2; // font-size !== 12 时候计算有问题,所以+2
    },
    computedShowList() {
      if (this.workerComputedData?.showTagList?.length) {
        return this.workerComputedData.showTagList
      } else {
        return this.showList || []
      }
    },
  },
  methods: {
    handlerTagClick(item) {
      this.$emit('handlerTagClick', item)
    },
    tofileUrl(rows){
      if(!rows.url) return
      window.open(rows.url)
    },
    handleDel(index) {
      this.$emit('delVal', index)
    },
    //添加宽度数组
    addWidth(tag) {
      let str = tag.label || ''
      //添加3个辅助key
      tag.charArr = Array.from(str)
      tag.widthArr = Array.from(str).map((cur) => {
        if (/[\u4e00-\u9fa5]/.test(cur)) {
          return this.W_FULL
        } else {
          return W_HALF
        }
      })
      let allLen = tag.widthArr.reduce((pre, cur) => {
        return pre + cur
      }, 0)
      if(this.meta.showAvatar){
        tag.showWidth = W_PAD_L + W_AVATAR + W_AVATAR_MAR + allLen + W_PAD_R
        tag.editWidth = W_PAD_L + W_AVATAR + W_AVATAR_MAR + allLen + 6 + W_XX + W_PAD_R //8为tag颜色padding, 4为tag之间的间距
      } else if (this.meta.showDelete) {
        tag.showWidth = W_PAD_L + allLen + W_PAD_R + 6 + DEL_ICON_WIDRH
        tag.editWidth = W_PAD_L + allLen + 6 + W_XX + W_PAD_R + DEL_ICON_WIDRH
      } else {
        tag.showWidth = W_PAD_L + allLen + W_PAD_R + 6
        tag.editWidth = W_PAD_L + allLen + 6 + W_XX + W_PAD_R //8为tag颜色padding, 4为tag之间的间距
      }

      
      
    },
    //获取+num这个tag的宽度, 样式上同样做个矫正
    getNumWidth(len) {
      //暂时不考虑大于100个选项
      let width
      if (len < 10) {
        width = W_PAD_R + W_PLUS * 2 + W_PAD_R
      } else {
        width = W_PAD_R + W_PLUS * 3 + W_PAD_R
      }
      // console.log('【NumWidth】', width);
      return width
    },
    //添加点点点, fWidth只算文字+点点点长度，其他padding, icon宽度等都不算
    fitLabel(tag, fWidth) {
      // console.log('【FitLabelWidth】', fWidth - W_DOT);
      let str = ''
      let curW = 0
      tag.widthArr.find((ii, index) => {
        if (curW + ii <= fWidth - W_DOT) {
          //如果宽度小于总宽度-3个点省略号宽度
          str += tag.charArr[index]
          curW += ii
          return false
        } else {
          return true
        }
      })
      tag.displayedLabel = str + '...' //重新拼凑
    },
    //获得数字标签的label, 从index开始算label
    formatMoreLabel(list, index) {
      let str = []
      if(this.from ==='indicator'){
        // return list.map(ele=>{
        //   return {
        //     fileType:ele.fileType,
        //     label:ele.label,
        //     url:ele.url,
        //     fileName:ele.label
        //   }
        // })
        const tempArr = []
        for (let len = list.length; index < len; index++) {
          tempArr.push({
            fileType:list[index].fileType,
            label:list[index].label,
            url:list[index].url,
            fileName:list[index].label
          })
        }
        return tempArr
      }else{
        for (let len = list.length; index < len; index++) {
          str.push(list[index].label)
        }
        return str.join('、')
      }
    },
    formatTags() {
      if (this.fullWidth == 0) return
      if (this.rawTags.length < 1) {
        this.filterList = []
        this.editList = []
        this.showList = []
        return
      }
      let fullWidth = this.fullWidth
      let editFullWidth = fullWidth - W_ARROW - W_MAR
      // console.log('【editFullWidth】', editFullWidth, fullWidth);
      this.rawTags.forEach((ii) => {
        this.addWidth(ii) //先计算每个tag整体宽度
        // console.log(ii.name, ii.showWidth);
      })

      //如果是筛选弹窗里的选项,筛选里始终有箭头
      if (this.from == 'filter') {
        // console.log(this.rawTags)
        let filterList = _.cloneDeep(this.rawTags)
        filterList.forEach((ii) => {
          ii.displayedLabel = ii.label
        })
        this.filterList = Object.freeze(filterList)
        return

        /***  先不展示数字   ****/
        //如果只有单个选项
        if (this.rawTags.length == 1) {
          if (filterList[0].editWidth > editFullWidth) {
            //如果超出总长度
            let tag = filterList[0]
            this.fitLabel(tag, editFullWidth - W_PAD_L - W_PAD_R - 6 - W_XX) //x号左侧margin 6px
          } else {
            filterList[0].displayedLabel = filterList[0].label
          }
          this.filterList = Object.freeze(filterList)
        } else {
          //大于1个选项的情况
          let allNumWidth = this.getNumWidth(filterList.length - 1)
          /*** 处理展示态情况 *****/
          //如果第一个选项宽度就大于总宽度(总宽度-数字宽度)
          if (filterList[0].editWidth > editFullWidth - allNumWidth - W_MAR) {
            let tag = filterList[0]
            this.fitLabel(tag, editFullWidth - allNumWidth - W_MAR - W_PAD_L - W_PAD_R - 6 - W_XX)
            filterList = filterList.slice(0, 1)
            filterList.push({
              displayedLabel: `+${this.rawTags.length - 1}`,
              color: NUM_COLOR,
              cWidth: allNumWidth + 'px',
              cType: 'more', //自定义类型，方便区分
              label: this.formatMoreLabel(this.rawTags, 1),
            })
            this.filterList = Object.freeze(filterList)
          } else {
            let tmpFullWidth = 0
            let tmpW = 0
            let tmpList = []
            filterList.findIndex((ii, index) => {
              let tmpNumWidth = 0
              if (index < filterList.length - 1) {
                //如果还不是最后一个
                tmpNumWidth = this.getNumWidth(filterList.length - 1 - index)
                tmpFullWidth = editFullWidth - tmpNumWidth - W_MAR //数字左侧的margin
              } else {
                tmpFullWidth = editFullWidth
              }

              if (tmpW + ii.editWidth + W_MAR < tmpFullWidth) {
                //如果还没超过总宽度（两个标签之间有margin）
                ii.displayedLabel = ii.label
                tmpList.push(ii)
                tmpW += ii.editWidth
                return false
              } else {
                //如果超过了总宽度，不再精确计算，直接隐藏最后一个
                tmpNumWidth = this.getNumWidth(filterList.length - 1 - index + 1) //此时要算上当前这个index的值，所以+1
                tmpList.push({
                  displayedLabel: `+${filterList.length - index}`,
                  color: NUM_COLOR,
                  cWidth: tmpNumWidth + 'px',
                  cType: 'more', //自定义类型，方便区分
                  label: this.formatMoreLabel(filterList, index),
                })
                return true
              }
            })
            this.filterList = Object.freeze(tmpList)
          }
        }
        return
      }

      let showList = _.cloneDeep(this.rawTags) //展示态列表
      let editList = _.cloneDeep(this.rawTags) //编辑态列表

      //如果只有单个选项
      if (this.rawTags.length == 1) {
        //先处理展示态情况
        if (showList[0].showWidth > fullWidth) {
          //如果超出总长度
          let tag = showList[0]

          this.fitLabel(tag, fullWidth - W_PAD_L - W_PAD_R * 2, true)
        } else {
          showList[0].displayedLabel = showList[0].label
        }
        this.showList = Object.freeze(showList)

        //处理编辑态情况
        if (editList[0].editWidth > editFullWidth) {
          //如果大于整个宽度
          let tag = editList[0]
          // this.fitLabel(tag, editFullWidth - W_DOT - W_PAD - W_XX);
          this.fitLabel(tag, editFullWidth - W_PAD_L - W_PAD_R - 6 - W_XX) //x号左侧margin 6px
        } else {
          editList[0].displayedLabel = editList[0].label
        }
        this.editList = Object.freeze(editList)
      } else {
        
        //大于1个选项的情况
        let allNumWidth = this.getNumWidth(showList.length - 1)
        // console.log(showList,'fullWidth-->', fullWidth,  'allNumWidth:', allNumWidth)
        /*** 处理展示态情况 *****/
        //如果第一个选项宽度就大于总宽度(总宽度-数字宽度)
        if (showList[0].showWidth > fullWidth - allNumWidth - W_MAR) {
          let tag = showList[0]
          this.fitLabel(tag, fullWidth - allNumWidth - W_MAR - W_PAD_L - W_PAD_R*4)
          showList = showList.slice(0, 1)
          showList.push({
            displayedLabel: `+${this.rawTags.length - 1}`,
            color: NUM_COLOR,
            cWidth: allNumWidth + 'px',
            cType: 'more', //自定义类型，方便区分
            label: this.formatMoreLabel(this.rawTags, 1),
          })
          this.showList = Object.freeze(showList)
        } else {
          let tmpFullWidth = 0
          let tmpW = 0
          let tmpList = []
          // console.log(showList)
          showList.findIndex((ii, index) => {
            let tmpNumWidth = 0
            // console.log(index);
            if (index < showList.length - 1) {
              //如果还不是最后一个
              tmpNumWidth = this.getNumWidth(showList.length - 1 - index)
              tmpFullWidth = fullWidth - tmpNumWidth - W_MAR //数字左侧的margin
            } else {
              tmpFullWidth = fullWidth
            }
            // console.log('tmpFullWidth',tmpFullWidth, index, 'index', W_MAR * (index - 1))
            // console.log(tmpFullWidth, 'tmpFullWidth')
            // showWidth还要加上W_MAR的宽度才行
            if (tmpW + ii.showWidth + W_MAR * index <= tmpFullWidth) {
              //如果还没超过总宽度（两个标签之间有margin）
              ii.displayedLabel = ii.label
              tmpList.push(ii)
              tmpW += ii.showWidth
              return false
            } else {
              // 如果超过了总宽度，不再精确计算，直接隐藏最后一个
              //如果超过了总宽度，则反向计算，根据宽度计算字数，大致计算，不再按照汉字或者非汉字宽度精确计算
              let leftW = tmpFullWidth - tmpW;
              let leftNum = Math.floor((leftW - W_PAD * 2) / this.W_FULL);
              // console.log('leftW:', leftW, 'leftNum:->', leftNum)
              if(leftNum < 3){  //如果已经装不下3个字(包括点点点，则直接舍弃后续)
                tmpNumWidth = this.getNumWidth(showList.length - 1 - index + 1);  //此时要算上当前这个index的值，所以+1
                tmpList.push({
                  displayedLabel: `+${showList.length - index}`,
                  color: NUM_COLOR,
                  cWidth: tmpNumWidth + 'px',
                  cType: 'more',  //自定义类型，方便区分
                  label: this.formatMoreLabel(showList, index)
                })
                return true;
              }else{
                let fitWidth = leftW - W_PAD * 2 - W_PAD_L;
                if(this.meta?.showAvatar) {
                  fitWidth = fitWidth - W_AVATAR - W_AVATAR_MAR;
                }
                
                this.fitLabel(ii, fitWidth);
                tmpList.push(ii);
                if(index != showList.length - 1){ //如果不是最后一个，还要加上+n
                  tmpList.push({
                    displayedLabel: `+${showList.length - 1 - index}`,
                    color: NUM_COLOR,
                    cWidth: tmpNumWidth + 'px',
                    cType: 'more',  //自定义类型，方便区分
                    label: this.formatMoreLabel(showList, 1 + index)
                  })
                }
                return true;
              }

              // tmpNumWidth = this.getNumWidth(showList.length - 1 - index + 1) //此时要算上当前这个index的值，所以+1
              // tmpList.push({
              //   displayedLabel: `+${showList.length - index}`,
              //   color: NUM_COLOR,
              //   cWidth: tmpNumWidth + 'px',
              //   cType: 'more', //自定义类型，方便区分
              //   label: this.formatMoreLabel(showList, index),
              // })
              // return true
            }
          })
          this.showList = Object.freeze(tmpList)
        }
        // console.log(this.showList , 'this.showList ')

        /*** 处理编辑态情况 *****/
        //判断是否有超过总体宽度的单个tag
        editList.forEach((ii) => {
          if (ii.editWidth + W_MAR > editFullWidth) {
            // this.fitLabel(ii, editFullWidth - W_MAR - W_DOT - W_PAD - W_XX);
            this.fitLabel(ii, editFullWidth - W_PAD_L - W_PAD_R - 6 - W_XX) //x号左侧margin 6px
          } else {
            ii.displayedLabel = ii.label
          }
        })
        this.editList = Object.freeze(editList)
      }
    },
  },
  mounted() {
    const calcWidth = () => {
      let mappingwid = this.from == 'filter' ? 250 : this.$refs.tagListModule?.offsetWidth
      let tmpW = this.$refs.tagListModule?.offsetWidth || mappingwid
      // console.log('【fullWidth】', tmpW);
      this.fullWidth = tmpW
      this.formatTags()
    }

    if (this.containerWidth) {
      // this.doCalcTags(this.containerWidth)
    } else {
      this.$nextTick(() => {
        setTimeout(calcWidth, 100)
      })
    }
    this.debouncedCalcHandler = debounce(calcWidth, 300)
    useResizeObserver(this.$refs.tagListModule, (entries) => {
      this.debouncedCalcHandler()
    })
    // this.debouncedCalcHandler = debounce(calcWidth, 300)
    // window.addEventListener('resize', this.debouncedCalcHandler)
  },
  beforeUnmount() {
    // window.removeEventListener('resize', this.debouncedCalcHandler)
  },
}
</script>
<style scoped lang="scss">
.cur-pointer{
    cursor: pointer;
    &:hover{
      text-decoration:underline
    }
  }
</style>
<style lang="scss">
@import '@/assets/styles/var.scss';
.tag-user-list-module {
  .filter-arrow-icon {
    line-height: 22px;
    font-size: 12px;
    transform: scale(0.73333);
    &::before {
      color: $icon-color-gray2;
    }
  }
  .tag-items {
    font-weight: 500;
    display: flex;
    align-items: center;
    margin-right: 4px;
    padding: 0 8px;
    // max-width: 200px;
    height: 20px;
    line-height: 20px;
    border-radius: 12px;
    white-space: nowrap;
    user-select: none;
    box-sizing: border-box;
    font-weight: 500;
    cursor: pointer;
    flex-shrink: 0;
    &.user {
      height: 24px;
      line-height: 24px;
      padding: 0 8px 0 2px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    &:last-child {
      margin-right: 0;
    }
    .icon-del {
      font-size: 12px;
    }
    &:hover {
      background: #bbcefc;
    }
    .avatar-img {
      width: 20px;
      height: 20px;
      border-radius: 10px;
      margin-right: 6px;
    }
    .avatar-no-img {
      width: 20px;
      height: 20px;
      border-radius: 10px;
      margin-right: 6px;
      background: #99adff;
      font-size: 12px;
      color: white;
      line-height: 20px;
      text-align: center;
    }
    &.more {
      justify-content: center;
      padding: 0 8px;
    }
    .label {
      display: block;
      font-size: 12px;
      // font-weight: 500;
      user-select: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      .hovers:hover{
        color: #3370ff;
      }
      // display: -webkit-box; /**对象作为伸缩盒子模型展示**/
      // -webkit-box-orient: vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
      // -webkit-line-clamp: 1; /**显示的行数**/
    }
  }
  .filter-list {
    display: flex;
    flex: 1;
    align-items: center;
    overflow: hidden;
    .placeholder-text {
      color: #86909c;
    }
  }
  .show-list {
    display: flex;
    align-items: center;
  }
  .edit-list {
    display: flex;
    width: 100%;
    margin-top: -4px;
    .item-list {
      flex: 1;
      // padding-left: 11px;
      display: flex;
      flex-wrap: wrap;
    }
    .tag-items {
      margin: 6px 4px 4px 0;
      width: fit-content;
      // max-width: 150px;
      white-space: nowrap;
      &:nth-child(1) {
        margin-left: 0;
      }
      .label {
        user-select: none;
      }
      .icon-del {
        margin-left: 6px;
        // width: 10px;
        height: 20px;
        font-size: 12px;
        line-height: 20px;
        cursor: pointer;
      }
    }
    .icon-arrow {
      margin-left: 4px;
      margin-top: 5px;
      font-size: 10px;
    }
  }
}
.tag-user-list-module.filter {
  display: flex;
  .icon-arrow {
    margin-left: 4px;
    margin-top: 2px;
    font-size: 10px;
  }
}
</style>
