import { ajax } from '@/utils/request'
// 获取开启使用的币种列表
export async function fetchUseableCurrencyList() {
  const result = await ajax(
    {
      method: 'get',
      url: `/currency/enable/list`,
    },
    [],
    [],
  )
  return result
}
// 币种管理-获取所有币种的列表
export async function fetchCurrencyList() {
  const result = await ajax(
    {
      method: 'get',
      url: `/currency/list`,
    },
    [],
    [],
  )
  return result
}
// 币种管理-获取所有币种的列表 免登录
export async function fetchCurrencyListLoginSkip(tenantId) {
  const result = await ajax(
    {
      method: 'get',
      url: `/currency/listLoginSkip?tenantId=${tenantId}`,
    },
    [],
    [],
  )
  return result
}

// 币种管理-历史汇率
export async function fetchHistoryRate(extra) {
  const result = await ajax(
    {
      method: 'get',
      url: `/currency/history/rate/${extra}`,
    },
    [],
    [],
  )
  return result
}

// 币种管理-更新汇率
export async function updateRate(extra) {
  const result = await ajax(
    {
      method: 'post',
      url: `/currency/update/rate`,
      ...extra,
    },
    [],
    [],
  )
  return result
}

// 币种管理-开启币种
export async function enableCurrency(extra) {
  const result = await ajax(
    {
      method: 'post',
      url: `/currency/update`,
      ...extra,
    },
    [],
    [],
  )
  return result
}
// 币种管理 - 预置币种列表
export async function fetchPresetCurrencyList() {
  const result = await ajax(
    {
      method: 'get',
      url: `/currency/preset/list`,
    },
    [],
    [],
  )
  return result
}
// 币种管理 - 添加币种
export async function saveCurrency(extra) {
  const result = await ajax(
    {
      method: 'post',
      url: `/currency/save`,
      ...extra,
    },
    [],
    [],
  )
}