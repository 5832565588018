import { ref } from 'vue'
import { ajax } from '@/utils/request'
import { useUploadFileList } from '@/store/useUploadFileList'
import { getSignTenantAccessToken, getSignTenantAccessTokenLoginSkip } from '@/services/login'

type PreUploadInfo = { block_num: number; block_size: number; upload_id: string }

const accessToken = ref()
const parentNode = ref()
const partFnMap = new Map<
  string,
  {
    list: (() => Promise<number>)[]
    status: 'pre' | 'uploading' | 'succeed' | 'failed' | 'cancelled'
  }
>()

const sleep = (time: number = 300) => {
  return new Promise((resolve) => {
    setTimeout(resolve, time)
  })
}

const decrypt = (pwd: string) => {
  var encrypt = new JSEncrypt()
  encrypt.setPrivateKey(
    '-----BEGIN RSA PRIVATE KEY-----' +
      `MIICeAIBADANBgkqhkiG9w0BAQEFAASCAmIwggJeAgEAAoGBALEdu6XuX6fx6TWHtuUsQsE6lFCG
    zxA9L1k8mDW9T0Bcw8DM5l1JgcqrVj9GupbvpW3pyM+/G+WtZ3CRIcaeRccRwNrxcm/5/Asr+XWe
    aOFX6crJak11lJZoZ8ygHqiFKuuDxUF96HU9YLeBtvlnRYcwRC+yFHxvH8uRzwIsbIFvAgMBAAEC
    gYEAnC6q7Sxhv5gr3N9bIYXBK+F7LfNinmBF43vygNj6DLiAigBlV3fdcxlZiZzWJwkWKPFR+hf9
    ArDBmVrxnNKuldvPby3CqCCnlkFxn4YYJJwjVSVG+sLMz/DS1vJGVeR+DxeQrr2SeZVCuzJEpSdt
    vYR5WU4TBLIsZKLtddHDePkCQQD3NOlAhp9ud1H6AaOixT/HljpsUPFxWOchkfuLqF6tXHqfmDON
    PV6nWq+Cdrh4rixK3yCDbdCNiVrYJWpDVBojAkEAt2qSBDFCqbjHIq+Y//WtCRzhuyxYXy/QZ24q
    d6tsMSwpxNGZzdhVQ4ki/fQqkfzXS8M4G1uosOCvEpgEapkSRQJAGxRNtMbKciRZ84P4c90dlnRr
    Y/Ad8qelcfT21B6WeHEX8aGPjBo74yM49JaisH2L1ovImP0PimGQdPrsQAHXdwJBAK1NSagib010
    JNwWNKdsl1Fr87FqBhnyUKTZx9CIrVKM2u536S5BANcuJVz0rqB/2WFhlIrtASgXM/SKpGmgNk0C
    QQDw6Gn1zUQN+I6BpZKoDtUyHg4iYD9BvIfAcUubf3LJkUPlpk0WDczzirEpFCq+4qbM9MVadE+z
    DdgJSLemrbm4` +
      '-----END RSA PRIVATE KEY-----',
  )
  return encrypt.decrypt(pwd)
}
const getFolderToken = (accessToken: string) => {
  return ajax(
    {
      url: '/open-apis/drive/explorer/v2/root_folder/meta',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    },
    { noPrefix: true },
  ) as Promise<Record<string, any>>
}
const getUploadPrepare = async (name: string, size: number, tenantId?: string) => {
  if (!accessToken.value || !parentNode.value) {
    const { tenantAccessToken, userAccessToken, parentToken } = !tenantId ? await getSignTenantAccessToken() : await getSignTenantAccessTokenLoginSkip(tenantId)
    accessToken.value = decrypt(!tenantId ? userAccessToken : tenantAccessToken)
    // parentNode.value = parentToken
  }
  const { token } = await getFolderToken(accessToken.value)

  return ajax(
    {
      url: '/open-apis/drive/v1/files/upload_prepare',
      method: 'post',
      data: {
        file_name: name,
        parent_type: 'explorer',
        parent_node: token,
        size,
      },
      headers: {
        Authorization: `Bearer ${accessToken.value}`,
        'Content-Type': 'application/json; charset=utf-8',
      },
    },
    // { getContext: true, noPrefix: true },
    { noPrefix: true },
  ) as Promise<PreUploadInfo>
}
const uploadPart = (data: FormData) => {
  return ajax(
    {
      url: '/open-apis/drive/v1/files/upload_part',
      method: 'post',
      headers: {
        Authorization: `Bearer ${accessToken.value}`,
      },
      data,
    },
    { noPrefix: true },
  )
}
const uploadFinish = (data: {
  upload_id: string
  block_num: number
}): Promise<{ file_token: string, code?: number }> => {
  return ajax(
    {
      url: '/open-apis/drive/v1/files/upload_finish',
      method: 'post',
      headers: {
        Authorization: `Bearer ${accessToken.value}`,
        'Content-Type': 'application/json; charset=utf-8',
      },
      data,
    },
    { noPrefix: true },
  )
}

const isUploading = ref(false)
export const useLarkUpload = (tenantId?: string) => {
  const uploadFileListStore = useUploadFileList()

  const preUpload = async (file: UploadFile) => {
    partFnMap.set(file.uuid, {
      list: [],
      status: 'pre',
    })

    return getUploadPrepare(file.fileName, file.file!.size, tenantId)
  }

  const doUpload = (
    file: UploadFile,
    info: { block_num: number; block_size: number; upload_id: string },
  ) => {
    let times = 0
    uploadFileListStore.updateFileState(file, 'uploading')

    const fnList = [...Array(info.block_num)].map((i, idx) => {
      const start = idx * info.block_size
      const end = start + info.block_size

      const block = file.file!.slice(start, end)

      return async () => {
        return new Promise<number>(async (resolve, reject) => {
          const data = {
            upload_id: info.upload_id,
            seq: idx,
            size: block.size,
            file: block,
          }
          const formData = new FormData()
          Object.keys(data).forEach((k) => {
            formData.append(k, data[k] as string | Blob)
          })

          try {
            const res = await uploadPart(formData)

            if (res === null) {
              resolve(idx)
            } else {
              reject(`分片${idx}上传失败`)
            }
          } catch (e) {
            reject(`分片${idx}上传失败`)
          }
        })
      }
    })

    partFnMap.set(file.uuid, {
      list: fnList,
      status: 'uploading',
    })

    return new Promise(async (resolve, reject) => {

      while (partFnMap.get(file.uuid)?.list.length && partFnMap.get(file.uuid)?.status !== 'cancelled' && times < 3) {
        const item = partFnMap.get(file.uuid)!
        const list = item.list
        const uploadFn = list.shift()!

        try {
          await uploadFn()
        } catch (e) {
          // await sleep(5000)
          // 上传出错了就放回头部，重新上传
          times += 1
          list.unshift(uploadFn)
        }
        const progress = (info.block_num - list.length) / info.block_num
        uploadFileListStore.updateFileProgress(file, progress > 0.99 ? 0.99 : progress)
      }

      if (partFnMap.get(file.uuid)?.status === 'cancelled') {
        // 已取消的
        partFnMap.delete(file.uuid)

        reject(new Error('cancelled'))
      } else if (times < 3) {
        // 最多重试 3 次，超过直接 reject
        // uploadFileListStore.updateFileState(file, 'success')
        resolve('succeed')
      } else {
        reject(new Error('failed'))
      }
    })
  }

  const doFinished = async (file: UploadFile, preInfo: PreUploadInfo): Promise<{ file_token: string, code?: number }> => {
    const res = await uploadFinish({
      upload_id: preInfo.upload_id,
      block_num: preInfo.block_num,
    })

    if (res.code) {
      sleep(1000)

      return doFinished(file, preInfo)
    }

    partFnMap.delete(file.uuid)

    return res
  }

  const uploadLargeFile = async (
    file: UploadFile,
    formData: object,
    headers: object,
    transformLocalFile: boolean,
  ): Promise<{ fileName: string; fileToken: string; fileType: string; errorMsg?: string; }> => {
    try {
      isUploading.value = true
      const preInfo = await preUpload(file)
      console.log('file', file);

      // 本地文件预上传失败处理
      if (!preInfo) {
        uploadFileListStore.updateFileProgress(file, 0)
        uploadFileListStore.updateFileState(file, 'error')
        isUploading.value = false
        return Promise.reject({
          ...file,
          state: 'error',
        })
      }
      // 本地文件预上传失败处理
      // if (response?.data?.code !== 0) {
      //   console.log('222', response);
      //   const codeMap :any = {
      //     1061004: '无对应上传节点的的权限',
      //     1061043: '文件超出大小限制，请上传 20M 以内文件',
      //     1061101: '租户容量超限，请确保租户有足够容量进行上传。',
      //     1061061: '个人容量超限，请确保个人有足够容量进行上传。',
      //     1061073: '没有申请接口权限。',
      //   }
      //   return Promise.resolve({
      //     ...file,
      //     state: 'error',
      //     errorMsg: codeMap[response.data.code] || response?.data?.msg
      //   })
      //   // return Promise.reject()
      // }

      // const preInfo = response.data.data

      await doUpload(file, preInfo)
      let { file_token: fileToken } = await doFinished(file, preInfo)

      // 本地上传文件转换云文档 start
      let fileType = 'file'
      const oldToken = fileToken
      let fileExtension = file.fileName.substring(file.fileName.lastIndexOf('.') + 1)
      
      if (['docx', 'xlsx', 'csv', 'doc'].includes(fileExtension) && fileToken && transformLocalFile) {
        const typeMap: any = {
          docx: 'docx',
          xlsx: 'sheet',
          csv: 'sheet',
          doc: 'docx',
        }
        let type = typeMap[fileExtension]
        const importFile = {
          file_extension: fileExtension,
          file_token: oldToken,
          type: type,
          point: {
            mount_type: 1,
            mount_key: ''
          }
        }
        const { ticket } = await doCreateImport(importFile)
        await new Promise((resolve) => {
          setTimeout(async () => {
            let time = 20
            while (time) {
              const res: any = await selectImportResult(ticket)
              if (res.result.job_status == 0) {
                fileToken = res.result.token
                fileType = res.result.type
                break
              }
              time--
            }
            resolve('')       
          }, 1500);
        })
      }
      isUploading.value = false
      return Promise.resolve({ fileName: file.fileName, fileToken, fileType: fileType })
      // 本地上传文件转换云文档 end

      // return Promise.resolve({ fileName: file.fileName, fileToken, fileType: 'file' })
    } catch ({ message }) {
      const state = message === 'failed' ? 'error' : 'cancel'

      uploadFileListStore.updateFileProgress(file, 0)
      uploadFileListStore.updateFileState(file, state)
      isUploading.value = false

      return Promise.reject({
        ...file,
        state,
      })
    } finally {
      // isUploading.value = false
    }
  }

  // 本地上传文件转换云文档 start
  // 创建导入任务
  const createImportTasks = (data: {
    file_extension: string
    file_token: string
    type: string
    file_name?: string
    point: Point
  }): Promise<{ ticket: string }> => {
    return ajax(
      {
        url: '/open-apis/drive/v1/import_tasks',
        method: 'post',
        headers: {
          Authorization: `Bearer ${accessToken.value}`,
          'Content-Type': 'application/json; charset=utf-8',
        },
        data,
      },
      { noPrefix: true },
    )
  }
  // 查询导入结果
  const selectImportResult = (ticket: string): Promise<{ result: object }> => {
    return ajax(
      {
        url: `/open-apis/drive/v1/import_tasks/${ticket}`,
        method: 'get',
        headers: {
          Authorization: `Bearer ${accessToken.value}`,
          // 'Content-Type': 'application/json; charset=utf-8',
        },
      },
      { noPrefix: true },
    )
  }
  // 执行创建导入任务
  const doCreateImport = async (importFile: ImportFile) => {
    const res = await createImportTasks({
      file_extension: importFile.file_extension,
      file_token: importFile.file_token,
      type: importFile.type,
      point: importFile.point,
    })

    return res
  }
  // 本地上传文件转换云文档 end

  const abortFileUpload = async (file: UploadFile) => {
    partFnMap.set(file.uuid, {
      list: [],
      status: 'cancelled',
    })
  }

  return {
    isUploading,
    uploadLargeFile,
    abortFileUpload,
  }
}
