<template>
  <FloatingWrapper
    v-show="visible"
    v-model:dragging="isDragging"
    :margin="{ horizontal: 10, vertical: 10 }"
    class="floating-window-wrapper"
  >
    <div
      class="content"
      :class="{
        dragging: isDragging,
      }"
      @click.prevent.stop
    >
      <renderHeader />
      <transition>
        <renderBody v-if="isExpanded" />
      </transition>
    </div>
    <renderConfirmDialog v-if="confirmDialogVisible" />
  </FloatingWrapper>
</template>

<script lang="tsx" setup>
import { computed, reactive, ref, watch } from 'vue'
import { Message } from '@arco-design/web-vue'
import { debounce } from 'lodash'
import Iconfont from '@/components/Iconfont.vue'
import FloatingWrapper from '@/components/floatingWrapper'
import { CalcWorker } from '@/components/tagUsers/getCalcWorker'
import { useUploadFileList } from '@/store/useUploadFileList'
import { getAppName, getToken } from '@/utils/appTool.js'
import {
  getFileBusiType,
  batchSaveFile,
  batchSaveFileUp,
  editFileBusinessType,
} from '@/services/investment/document.js'
import { BusinessCode } from '@/type'
import { useUploader, FileState } from './useUploader'
import FileItem from './fileItem.vue'
import { getBigTypeByCode } from '@/utils/enums.js'

const STATE_ICON_MAPPING: Record<FileState, { icon: string; label: string }> = {
  prepare: {
    icon: 'icon_update',
    label: '准备上传',
  },
  uploading: {
    icon: 'icon_update',
    label: '上传中',
  },
  success: {
    icon: 'icon_5_1',
    label: '全部上传成功',
  },
  error: {
    icon: 'icon_error',
    label: '文件上传失败',
  },
  cancel: {
    icon: 'icon_error',
    label: '文件已取消上传',
  },
}
const SOURCE_KEY = 'FLOATING_WINDOW'
const {
  uploadSingleFile,
  appendMultipleFileUpload,
  clearFileList,
  removeFile,
  reuploadFile,
  uploadFileFolder,
} = useUploader()

const doUpdateFn = ref((...rest: any[]): any => ({}))

const onUploadFileFolder = (
  files: LocalFile[] = [],
  dataId: string,
  businessCode: string,
  parentId: string,
  fn: (...rest: any[]) => any,
) => {
  files = Array.from(files)

  if (!files?.length) {
    return
  }

  if (fn) {
    doUpdateFn.value = debounce(fn, 500)
  }
  visible.value = true

  uploadFileFolder(
    files,
    {
      dataId,
      businessCode,
      fileTags: ['9999'],
      parentId,
    },
    {
      'login-token': getToken(),
    },
    (res: { fileName: string; fileToken: string; fileType: string }, file: UploadFile) => {
      return saveFile(false)(res, file, file.parentId ?? parentId)
    },
    SOURCE_KEY,
  )
}

const onUploadFileList = (
  files: LocalFile[] = [],
  dataId: string,
  businessCode: string,
  parentId: string,
  fn: (...rest: any[]) => any,
) => {
  files = Array.from(files)
  if (fn) {
    doUpdateFn.value = debounce(fn, 500)
  }

  if (!files?.length) {
    return
  }
  visible.value = true

  appendMultipleFileUpload(
    files,
    {
      dataId,
      businessCode,
      fileTags: ['9999'],
      parentId,
    },
    {
      'login-token': getToken(),
    },
    saveFile(true),
    SOURCE_KEY,
  )
}
defineExpose({
  onUploadFileList,
  onUploadFileFolder,
})

const workerInstance = CalcWorker.prototype.getWorkerInstance()
const uploadFileListStore = useUploadFileList()

// const props = defineProps<{ code: BusinessCode }>()

const isDragging = ref(false)
const visible = ref(false)
const curFileList = ref<LocalFile[]>([])
const fileBusinessTypeObj = reactive<Record<string, { id: string; name: string }[]>>({})

const curCode = computed<BusinessCode>(() => {
  const code = getAppName() as string

  return (code === 'lpinvestor' ? 'lpInvestor' : code) as BusinessCode
})
const currentState = computed<FileState>(() => {
  return errorCounts.value ? 'error' : tmpFileList.value.length ? 'uploading' : 'success'
})
const errorCounts = computed(() => {
  return tmpFileList.value.filter((f) => f.state === 'error').length
})
const dataIdList = computed(() => {
  const ids = curFileList.value.map((f) => f.dataId!)
  return Array.from(new Set(ids))
})

const tmpFileList = computed(() =>
  (uploadFileListStore.fileList ?? [])
    .filter((i) => {
      return i.sourceKey === SOURCE_KEY
    })
    .map((i) => i)
    .reverse(),
)

const isUploadFileFn = (file: LocalFile | UploadFile, isTmp: boolean): file is UploadFile => {
  return isTmp
}
const handleOpenFile = (file: LocalFile) => {
  window.open(file.url)
}
const handleDeleteFile = (file: LocalFile | UploadFile, isTmp: boolean) => {
  const isUploadFile = isUploadFileFn(file, isTmp)
  if (isUploadFile) {
    uploadFileListStore.fileList = uploadFileListStore.fileList.filter((f) => f.uuid !== file.uuid)
  } else {
    if (file.id) {
      curFileList.value = curFileList.value.filter((f) => f.id !== file.id)
    } else if (file.fileKey) {
      curFileList.value = curFileList.value.filter((f) => f.fileKey !== file.fileKey)
    } else {
      curFileList.value = curFileList.value.filter((f) => f.fileToken !== file.fileToken)
    }
  }
}

const saveFile = (send: boolean) => (res: any, uploadFile: UploadFile, parentId?: string) => {
  if (!visible.value) return Promise.resolve()

  return batchSaveFileUp({
    data: {
      fileList: [
        {
          businessCode: uploadFile.businessCode,
          dataId: uploadFile.dataId,
          fileTags: ['9999'],
          fileName: res.fileName,
          fileToken: res.fileToken,
          fileType: res.fileType,
          parentId,
        },
      ],
      sendMsg: Number(send),
    },
  }).then((res) => {
    // Message.success(`${uploadFile.fileName}上传成功`)

    removeFile(uploadFile)
    curFileList.value = [...res, ...curFileList.value]

    // doUpdateFn.value && doUpdateFn.value()
    currentState.value !== 'uploading' && doUpdateFn.value && doUpdateFn.value()

    return res
  })
}
const handleReuploadFile = (file: UploadFile, parentId?: string) => {
  const dataId = file.dataId
  const businessCode = file.businessCode
  const isSendMsg = !file.isFolderFile

  reuploadFile(
    file,
    {
      dataId,
      businessCode, //其实不传也可以，因为file中已经添加
      fileTags: ['9999'],
      parentId,
    },
    {
      'login-token': getToken(),
    },
    saveFile(isSendMsg),
    SOURCE_KEY,
  )
}
const handleUpdateFileTags = (file: LocalFile, tags: LocalFile['fileTags'] = []) => {
  return editFileBusinessType({
    data: {
      id: file.id,
      fileTags: tags,
    },
  }).then(() => {
    curFileList.value = curFileList.value.map((f) => {
      if (file.id === f.id) {
        return {
          ...f,
          fileTags: tags,
        }
      } else {
        return f
      }
    })
  })
}
const fetchAllDataBusinessType = debounce((ids: string[]) => {
  const promises = ids
    .filter((id) => {
      const isTypeListExisted = fileBusinessTypeObj[id]
      if (!isTypeListExisted) {
        fileBusinessTypeObj[id] = []
      }

      return !isTypeListExisted?.length
    })
    .map((id) => {
      return fetchBusinessType(id)
    })

  return Promise.all(promises)
}, 500)
const fetchBusinessType = async (dataId: string) => {
  return await getFileBusiType({
    data: {
      dataId,
      dataType: getBigTypeByCode(curCode.value),
    },
  }).then((res: { typeVOList: any[]; total: number }) => {
    const typeList = res.typeVOList.map(({ id, name }: { id: string; name: string }) => {
      return {
        id,
        name,
      }
    })

    fileBusinessTypeObj[dataId] = typeList
  })
}

const isExpanded = ref(true)
const onToggleExpandWrapper = (evt: Event) => {
  stopEvent(evt)
  isExpanded.value = !isExpanded.value
}
const onPreCloseWrapper = (evt: Event) => {
  stopEvent(evt)

  if (currentState.value !== 'success') {
    confirmDialogVisible.value = true
  } else {
    onCloseWrapper()
  }
}
const onCloseWrapper = () => {
  clearState()
  confirmDialogVisible.value && doUpdateFn.value && doUpdateFn.value() && doUpdateFn.value && doUpdateFn.value()
  visible.value = false
}
const stopEvent = (evt: Event) => {
  evt.preventDefault()
  evt.stopPropagation()
}
const clearState = () => {
  curFileList.value = []
  clearFileList(SOURCE_KEY)
}

watch(
  () => dataIdList.value,
  (v: string[]) => {
    fetchAllDataBusinessType(v)
    // fetchBusinessType()
  },
  {
    immediate: true,
    deep: true,
  },
)

const renderHeader = () => {
  const total = curFileList.value.length + tmpFileList.value.length
  const doneCount = curFileList.value.length

  return (
    <div class="file-wrapper-header">
      <span
        class={{
          title: true,
          normal: currentState.value === 'uploading',
          success: currentState.value === 'success',
          error: currentState.value === 'error',
        }}
      >
        <Iconfont size={28} name={STATE_ICON_MAPPING[currentState.value].icon} />
        {currentState.value === 'error' && <>{errorCounts.value} 个</>}
        {STATE_ICON_MAPPING[currentState.value].label}
        {currentState.value === 'uploading' && <span>{`${doneCount}/${total}`}</span>}
      </span>

      <span class="action">
        <Iconfont
          class={{ expanded: isExpanded.value }}
          name="arrow-left"
          onClick={onToggleExpandWrapper}
          onMousedown={stopEvent}
        />
        <Iconfont name="icon_close_med" onClick={onPreCloseWrapper} onMousedown={stopEvent} />
      </span>
    </div>
  )
}
const renderBody = () => {
  return (
    <div class="file-wrapper-body">
      {tmpFileList.value.map((file) => {
        return (
          <div
            onMousedown={(evt: MouseEvent) => stopEvent(evt)}
            onMouseup={(evt: MouseEvent) => stopEvent(evt)}
          >
            <FileItem
              key={`${file.uuid}`}
              isTmp={true}
              popupContainer=".floating-window-wrapper"
              file={file}
              typeList={fileBusinessTypeObj[file.dataId!] ?? []}
              onDelete={(file: UploadFile) => handleDeleteFile(file, true)}
              onReupload={handleReuploadFile}
              onActionClick={(evt: MouseEvent) => stopEvent(evt)}
            />
          </div>
        )
      })}

      {curFileList.value.map((file) => {
        return (
          <div
            onMousedown={(evt: MouseEvent) => stopEvent(evt)}
            onMouseup={(evt: MouseEvent) => stopEvent(evt)}
          >
            <FileItem
              key={file.id}
              file={file}
              typeList={fileBusinessTypeObj[file.dataId!] ?? []}
              popupContainer=".floating-window-wrapper"
              onOpen={handleOpenFile}
              onDelete={(file: LocalFile) => handleDeleteFile(file, false)}
              onUpdateFileTags={handleUpdateFileTags}
              onReupload={handleReuploadFile}
            />
          </div>
        )
      })}
    </div>
  )
}
const confirmDialogVisible = ref(false)
const renderConfirmDialog = () => {
  return (
    <a-modal
      v-model:visible={confirmDialogVisible.value}
      title="取消确认"
      class="public-modal high-zindex"
      onOk={onCloseWrapper}
      onCancel={() => (confirmDialogVisible.value = false)}
    >
      <div>上传尚未完成，要取消所有正在进行的上传吗？</div>
    </a-modal>
  )
}
</script>

<style lang="scss">
.high-zindex {
  z-index: 9999 !important;
}
</style>
<style lang="scss" scoped>
.floating-window-wrapper {
  width: 420px;
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid #dee0e3;
  background-color: white;
  box-shadow: 0px 6px 24px 0px rgba(31, 35, 41, 0.08);
  overflow: initial;
  z-index: 9999;
  .content {
    width: 100%;
    &.dragging {
      :deep(.file-wrapper-body) {
        // pointer-events: none;
      }
    }
    .file-wrapper-header {
      height: 68px;
      display: flex;
      align-items: center;
      padding: 0 20px;
      justify-content: space-between;
      :deep() {
        .title {
          display: flex;
          align-items: center;
          font-size: 20px;
          font-weight: 500;
          line-height: 24px;
          &.normal {
            .iconfont {
              color: #3272fe;
            }
          }
          &.success {
            .iconfont {
              color: #34c724;
            }
          }
          &.error {
            .iconfont {
              color: #f6c944;
            }
          }
          .iconfont {
            margin-right: 16px;
          }
        }
        .action {
          .iconfont {
            width: 28px;
            height: 28px;
            border-radius: 6px;
            display: inline-block;
            text-align: center;
            line-height: 28px;
            color: #646a73;
            transition: all ease-in-out 300ms;
            cursor: pointer;
            &:hover {
              background: rgba(31, 35, 41, 0.1);
            }
            & + .iconfont {
              margin-left: 12px;
            }
          }
          .icon-arrow-left {
            transform: rotate(-270deg);
            &.expanded {
              transform: rotate(-90deg);
            }
          }
        }
      }
    }
    .file-wrapper-body {
      padding: 4px;
      max-height: 328px;
      overflow: auto;
      :deep() {
        .file-item {
          border: none;
        }
        .file-item + .file-item {
          margin-top: 4px;
        }
      }
    }
  }
}
</style>
