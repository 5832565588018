// 基金分析
export default {
  module: 'fundAnalyze',
  jijinfenxi: '基金分析',
  tuijianmuban: '推荐模板',
  jijiangshangxian: '即将上线',
  zongjiazhibiandongfenxi: '总价值变动分析',
  zongjiazhibiandong: '总价值变动情况对比及分析',
  IRRbiandongfenxi: 'IRR变动分析',
  IRRbiandong: 'IRR变动情况对比及分析',
  huibaobeishubiandongfenxi: '回报倍数变动分析',
  xiangmuhuibaobiandong: '项目回报变动情况对比及分析',
  touzihangyefenxi: '投资行业分析',
  touzihangyefenbutongji: '投资行业分布统计',
  touzidiqufenxi: '投资地区分析',
  touzidiqufenbutongji: '投资地区分布统计',

  touzijine: '投资金额',
  xiangmushuliang: '项目数量',
  quanbudiqu: '全部地区',
  quanbujijin: '全部基金',
  quanbuhangye: '全部行业',
  

  daochu: '导出',
  daochuzhong: '导出中',
  guanbi: '关闭',

  jiduleiji: '季度累计',
  yueduleiji: '月度累计',
  nianduleiji: '年度累计',
  

  chakanshuju: '查看数据',
  dianjichakan_zhuzhuang: '点击柱状图，可查看该月份的详细信息',
  dianjichakan_zhexian: '点击折线图，可查看该月份的详细信息',
  dianjichakanshengfen_zhuzhuang: '点击柱状图，可查看该省份的详细信息',

  zhidaole: '知道了',
  xiangguanzhibiao: '相关指标',
  zongjiazhi: '总价值',
  yishixianjiazhi: '已实现价值',
  weishixianjiazhi: '未实现价值',
  jinxianshibiandong: '仅显示变动项目',

  tongbi: '同比',
  huanbi: '环比',
  duibi: '对比',
  wanyuan: '万元',
  yuan: '元',
  wan: '万',

  heji: '合计',
  leijishijiaojine: '累计实缴金额',
  leijitouzijine: '累计投资金额',
  yixuan: '已选',
  jiezhizhi: '截止至',

  yijihangye: '一级行业',
  erjihangye: '二级行业',
  dianjiyijichakan: '点击一级行业，可查看该二级行业的详细信息',

  // IRR

  zijinliuru: '资金流入',
  zijinliuchu: '资金流出',
  chiyoujiazhi: '持有价值',

  chazhi: '差值',


}