
import { i18n }  from '@/i18n-setup.js';

let localLang = localStorage.getItem('lang');

export function setI18nLanguage(lang){
  localStorage.setItem('lang', lang);
  localLang = lang;
  i18n.locale = lang;
  document.querySelector('html').setAttribute('lang', lang);
}

export function getI18nLanguage(){
  if(localLang) return localLang;
  localLang = localStorage.getItem('lang');
  if(!localLang){ //如果local也没存
    if (i18n.mode === 'legacy') {
      localLang = i18n.global.locale;
    } else {
      localLang = i18n.global.locale.value
    }
    localStorage.setItem('lang', localLang);
  }
  return localLang;
}

export function i18n_t(){
  return i18n.global.t(...arguments);
}

