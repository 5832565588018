const data: Record<string, number> = {}
export const useTimerSummary = () => {
  const tmp: Record<string, number> = {}
  const addTime = (key: string, time: number) => {
    data[key] = (data[key] ?? 0) + time
  }
  const getSummary = (key?: string) => {
    const rs = key ? { [key]: data[key] } : data
    
    return rs
  }
  const timeStart = (key: string) => {
    tmp[key] = window.performance.now()
  }
  const timeEnd = (key: string, summaryKey: string) => {
    const start = tmp[key]
    const end = window.performance.now()

    if (start === undefined) {
      throw new Error(`${key} hasn't started yet`)
    }
    const delta = (end - start) / 1000

    addTime(summaryKey, delta)
  }

  ;(window as any)._getTimerSummary = getSummary

  return {
    data,
    timeStart,
    timeEnd,
    getSummary,
  }
}
