import { ajax } from '@/utils/request';

// 检查是否管理员
export async function checkIsAdmin(extra) {
  const result = await ajax({
    method: 'post',
    url: '/user/isAdmin',
    ...extra,
  });
  return result;
}

// 获取资产类型
export async function fetchAssetType(extra) {
  const result = await ajax(
    {
      method: 'get',
      url: `/assetType/queryAllBaseList`,
      ...extra,
    }
  )
  return result
}

// 布局管理-根据标签ID获取标签布局信息
export async function fetchLayoutByTabId(extra) {
  const result = await ajax({
    method: 'get',
    url: '/layout/queryByTabId',
    ...extra,
  }, [], []);
  return result;
}

//  获取新功能是否已经弹过窗

export async function fetchTipsList(extra) {
  const result = await ajax({
    method: 'get',
    url: '/tips/self/list',
    ...extra,
  }, [], []);
  return result;
}

// 新功能弹窗点击确认
export async function TipsConfirm(extra) {
  const result = await ajax({
    method: 'post',
    url: `/tips/confirm/${extra.id}`,
  });
  return result;
}

// baseLink相关
// 查询是否开通baseLink  /baseLink/isActivate
export async function checkBaseLinkActive(extra) {
  const result = await ajax({
    method: 'get',
    url: '/baseLink/isActivate',
    ...extra,
  }, [], []);
  return result;
}
// 查询baseLink 文档生成状态
export async function getBaseLinkStatus(extra) {
  const result = await ajax({
    method: 'get',
    url: '/baseLink/query',
    ...extra,
  }, [], []);
  return result;
}
// 根据模板导出baseLink
export async function openBaseLink(extra) {
  const result = await ajax({
    method: 'post',
    url: '/baseLink/export',
    ...extra,
  }, [], []);
  return result;
}
// 同步baseLink
export async function syncbaseLink(extra) {
  const result = await ajax({
    method: 'get',
    url: '/baseLink/sync',
    ...extra,
  }, [], []);
  return result;
}

// 分类管理 - 投资机构库
// 获取机构列表 & 搜索
export async function fetchPartnerLists(extra) {
  const result = await ajax({
    method: 'get',
    url: '/partner/list',
    ...extra,
  }, [], []);
  return result;
}
// 新建机构
export async function partnerCreate(extra) {
  const result = await ajax({
    method: 'post',
    url: '/partner/create',
    ...extra,
  }, [], []);
  return result;
}
// 编辑
export async function partnerUpdate(extra) {
  const result = await ajax({
    method: 'post',
    url: '/partner/update',
    ...extra,
  }, [], []);
  return result;
}
// 删除
export async function partnerDelete(extra) {
  const result = await ajax({
    method: 'post',
    url: `/partner/delete?id=${extra.id}`,
  }, [], []);
  return result;
}

// 导入投资机构时下载的模板
export async function downloadPartnerTemplate(extra) {
  const result = await ajax({
    method: 'post',
    url: `/partner/template/download`,
  }, [], []);
  return result;
}


// 分类管理 - 资金流水 - 交易分类
export async function fetchTradeTypeTree(extra) {
  const result = await ajax({
    method: 'get',
    url: '/capital/flow/type/tree',
    ...extra,
  }, [], []);
  return result;
}

// 添加流水交易分类
export async function AddTradeType(extra) {
  const result = await ajax({
    method: 'post',
    url: `/capital/flow/type/create`,
    ...extra
  }, [], []);
  return result;
}

// 删除流水交易分类
export async function DeleteTradeType(extra) {
  const result = await ajax({
    method: 'get',
    url: `/capital/flow/type/delete`,
    ...extra
  }, [], []);
  return result;
}
// 更新流水交易分类
export async function UpdateTradeType(extra) {
  const result = await ajax({
    method: 'post',
    url: `/capital/flow/type/update`,
    ...extra
  }, [], []);
  return result;
}
// 删除前检查
export async function DeleteCheckTradeType(extra) {
  const result = await ajax({
    method: 'get',
    url: `/capital/flow/type/delete/check`,
    ...extra
  }, [], []);
  return result;
}
// 分类排序
export async function OrderTradeType(extra) {
  const result = await ajax({
    method: 'post',
    url: `/capital/flow/type/changeOrder`,
    ...extra
  },[],[],);
  return result;
}