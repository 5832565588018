// 后台管理
export default {
  common: {
    houtaishezhi: '后台设置',
    ziduanguanli: '字段管理',
    jibenxinxi: '基本信息',
    touziliucheng: '投资流程',
    shenpiguanli: '审批管理',
    biaoqianyeguanli: '标签页管理',
    zhibiaoku: '指标库',
    fenleishezhi: '分类设置',
    bizhongshezhi: '币种设置',
    assetCategory: '资产类型',
    youjianshezhi: '邮件设置',
    peizhiyingyong: '配置应用',
    yonghuguanli: '用户与角色',
    quxiao: '取消',
    queding: '确定',
    queren: '确认',

    xingming: '姓名',
    sousuoxingming: '搜索姓名',
    shuruxingming: '输入姓名',
    tianjia: '添加',
    guanliyuan: '管理员',
    putongyonghu: '普通用户',
    juese: '角色',
    yuyan: '语言',
    yichu: '移除',
    shanchu: '删除',
    shanchu_chenggong: '删除成功',
    guanbi: '关闭',
    chaxun: '查询',
    guanlian: '关联',
    zhidaole: '知道了',
    guanlian_chenggong: '关联成功',
    yishanchu: '已删除',
    tianxiemingcheng: '请填写名称',
    qingtianxie: '请填写',
    qingshuru: '请输入',
    qingxuanze: '请选择',
    tishi: '提示',
    yingyongtixing: '当前页面变更了设置，是否应用',
    buyingyong: '不应用',
    yingyong: '应用',
    shanchutishi_LP: '当前状态"{currentLabel}"下存在以下投资者，无法删除，如需删除请先调整对应当前状态',
    shanchutishi_Invest: '"{currentLabel}"阶段下存在以下项目，无法删除，如需删除请先调整对应项目阶段',
    shanchutishi_Todo: '以下进行中项目"{currentLabel}"事项已完成，是否继续删除？（确认并保存配置后所有进行中项目将删除该待办事项）',
    touzizhejiancheng: '投资者简称',
    fuzeren: '负责人',
    xiangmu: '项目',
    dangqianjieduan: '当前阶段',
    shanchubukehuifu: '删除后将不可恢复。是否确认删除？',
    xiayibu: '下一步',
    shangyibu: '上一步',
    chuangjian: '创建',
    chuangjian_chenggong: '创建成功',
    bitian: '必填',
    changduxianzhi: '长度不能超过{number}个字符',
    zifuxianzhi: '不能包含以下特殊符号{text}',
    qingxuanzeziduan: '请选择字段',
    xiugai_chenggong: '修改成功',
    jinggao: '警告',
    baocun_chenggong: '保存成功',
    baocun_shibai: '保存失败',
    fasong: '发送',
    fasong_chenggong: '发送成功',
    yulan: '预览',
    chongmingming: '重命名',
    shi: '是',
    fou: '否',
    bianji: '编辑',
    caozuochenggong: '操作成功',
    quanbu: '全部',
    bianji_chenggong: '编辑成功',
    xinjian_chenggong: '新建成功',
    tianjiaziduan: '添加字段',
    sousuoziduan: '搜索:字段',
    meiyoujieguo: '未能找到相关结果',
    ziduan_xinjian: '新建字段',
    touqian: '投前',
    touhou: '投后'
  },
  // 用户管理页面
  admin: {
    // index
    yonghuliebiao: '用户列表',
    shengyuxiwei: '企业剩余席位{number}人',
    zengjiayonghuxiwei: '增加企业用户席位',
    // addAdministrator
    tianjiayonghu: '添加用户',
    shuruxingming: '输入姓名',
    guanliyuan_quanxian: '管理员用户可以查看和编辑所有业务数据',
    putongyonghu_quanxian: '普通用户只能查看/编辑自己作为协作成员的业务数据',
    // addUserSeats
    daoqi_shijian: '{time}到期',
    zengjiaxiweitishi: '感谢您使用鲸准投资云，如需增加企业用户席位或续费，请拨打客服电话',
    //administratorList
    yonghuzongshu: '共{total}名用户',
    // delAdministrator.
    yichuguanliyuan: '移除管理员',
    yichuqueding: '确定将{name}从用户中移除吗？',

    zh: '中文',
    en: 'English',
  },
  // 审批管理
  approvalManage: {
    guanlianshenpi_tishi: '关联飞书审批后，可在投资云中发起对应审批',
    shenpimingcheng: '审批名称',
    feishushenpi_dingyicode: '飞书审批定义Code',
    guanlianfeishu_shenpi: '关联飞书审批',
    ruhehuoqu: '如何获取',
    geshiru: '格式如：：E3254848-D172-4169-B03E-744E7CD1',
    shenpihuoqu_fangfa: '审批定义对应的唯一编码，可在编辑审批时从浏览器 url 中找到。点击以下链接可以进入管理后台',
    shenpihuoqu_caozuo: '点击上图中的编辑按钮，可以在URL中找到Approval Code，格式为',
    yichushenpi: '移除审批',
    tongbushanchu_tishi: '该审批在以下投资流程中被使用，将同步删除',
    yichushenpi_tishi: '移除后将不能在投资云中发起对应审批',
    yifaqishenpiwuyingxiang: '已发起的审批不受影响',
    chaxunshenpi_chenggong: '查询到飞书审批',
    chaxunshenpi_shibai: '未查询到对应飞书审批，请确认code是否正确',
  },
  // 分类设置
  classification: {
    genjinjilu: '跟进记录',
    wendangbiaoqian: '文档标签',
    zijinliushui: '资金流水',
    shanchu_tishi: '删除后，该分类下的跟进记录将自动变为“未分类”，是否确认删除？',
    shanchu_biaoqiantishi: '删除后，已使用该标签的文件将被去除该标识，是否确认删除？',
    mingchengchongfu_fenlei: '分类名称重复',
    mingchengchongfu_biaoqian: '标签名称重复'
  },
  // 配置应用
  configApp: {
    chuangjianduoweibiaoge: '创建多维表格',
    duoweibiaoge_tishi1: '每个多维表格文档都包含{title}数据表，系统将自动更新该数据表',
    duoweibiaoge_tishi2: '最多创建3个文档',
    duoweibiaoge_tishi3: '最多创建3个多维表格',
    chuangjianbiaoge_tishi: '根据设置的条件和字段，自动同步“{text}”数据；注意：创建后不能修改同步字段',
    zhengzaichushihua: '正在初始化',
    suoyouziduan: '所有字段',
    zhidingziduan: '指定字段',
    wendangbiaoti: '文档标题',
    wendangbiaoti_bitian: '文档标题必填',
    qingshuruwendangbiaoti: '请输入文档标题',
    suoyouzhe: '所有者',
    tongbuziduan: '同步字段',
    shujubiaomingcheng: '数据表名称',
    qingshurumingcheng: '请输入数据表名称',
    shujubiaoxianzhi: '数据表名称必填',
    shezhitongbuziduan: '设置同步字段',
    wendangxinxi: '文档信息',
  },
  // 币种设置
  currencyConfig: {
    bizhongshezhi: '币种设置',
    benweibi: '本位币',
    zidonghuilv: '自动汇率',
    shoudonghuilv: '手动汇率',
    gengxinhuilv: '更新汇率',
    lishihuilv: '历史汇率',
    huobi: '货币',
    huilv: '汇率',
    renminbi: '人民币',
    huilv_tishi: '多币种汇总数据，将按设置的汇率进行统一换算（选择自动汇率将获取最新汇率进行换算）',
    shoudonghuilv_buweikong: '手动汇率不可为空',
    zi: '自'
  },
  // 资产类型
  assetCategory: {
    assetCategory: '资产类型',
    remark: '备注',
    minLenMsg: '至少需要开启1个资产类型',
  },
  // 邮件设置
  emailConfig: {
    youxiang: '邮箱',
    youxiang_muban: '邮件模板',
    youxiang_shezhi: '邮箱设置',
    fasongceshiyoujian: '发送测试邮件',
    fasongyoujian_shezhitishi: '设置发件邮箱账号后，系统将通过您设置的账号发送邮件',
    fuwuqidizhi: 'SMTP服务器地址',
    duankou: '端口',
    mima: '密码',
    fasongyouxiang: '发送邮箱',
    shoujianyouxiang: '收件邮箱',
    shuruceshiyouxiang: '请输入接受测试邮件的邮箱',
    jinggao_tixing: '有尚未保存的内容，确认要切换吗？',
    tianxieshoujian: '请填写收件地址',

    youjianmuban_tishi: '在文本框中输入"#变量名称#",可以引用变量参数,具体包括:#机构名称#, #报告期#, #填报链接#, #驳回原因#',
    shujushoujiyoujianmuban: '数据收集邮件模板',
    youjianzhuti: '邮件主题',
    youjianneirong: '邮件内容',
    youjianmuban_xinxituihui: '信息退回邮件模板',
    xiaoguoshili: '效果示例',
  },
  // 字段管理
  fieldManage: {
    qingxuanze: '请选择',
    shifoubitian: '是否必填',
    ziduan_bianji: '编辑字段',
    ziduan_xinjian: '新建字段',
    ziduan_shanchu: '删除字段',
    ziduan_shanchu_queren: '确认删除字段吗',
    ziduan_xitong: '系统字段',
    ziduan_mingcheng: '字段名称',
    ziduan_leixing: '字段类型',
    xinjianxiangmu_bitian: '新建项目时是否填写该字段',
    morenbiaoqian: '默认标签',
    tianjiawenjianhou_biaoqian: '添加文件后将自动添加默认标签',
    xuanxiang: '选项',
    xuanxiangzhiyiyong: '项目管理/已投公司共用该选项值',
    yijihangye: '一级行业',
    erjihangye: '二级行业',
    peizhigongyong: '项目管理/已投公司/投资者管理共用该配置',
    tianjiaguojia: '添加国家',
    wufashanchuzhuangtai: '无法删除当前状态',
    wenben: '文本',
    duohangwenben: '多行文本',
    danxuan: '单选',
    duoxuan: '多选',
    shuzi: '数字',
    huobi: '货币',
    baifenbi: '百分比',
    riqi: '日期',
    renyuan: '人员',
    wenjian: '文件',
    hangyebuweikong: '行业不能为空',
    xuanxiangbuweikong: '选项不能为空',
    ziduanleixing_wenben: '字段类型只能更改为文本或多行文本',
    ziduanleixing_danxuan: '字段类型只能更改为单选或多选',
    ziduanleixinh_bunenggenggai: '字段类型不能更改',
    qiangtianjiaxuanxiang: '请添加选项',
    guojiapeizhi: '国家配置',
    xitong: '系统',
    leixing: '类型',
    xinjiantianxie: '新建时填写',
    yingyongweizhi: '应用位置',
    ziduanshanchu_tishi: '该字段应用于以下位置，将同步删除',
    xitongziduan_bukebianji: '该系统字段不可编辑',
  },
  // 基本信息 filedbaseInfor文件夹
  baseInfo: {
    tianjiaziduan: '添加字段',
    tianjiaquyu: '添加区域',
    sousuoziduan: '搜索:字段',
    weizhaodaojieguo: '未能找到相关结果',
    qingshuruquyu: '请输入区域名称',
    xiangmuxinxi: '项目信息',
    jijinxinxi: '基金信息',
    jibenxinxi: '基本信息',
  },
  // 指标库
  indicatorBank: {
    fenleiguanli: '分类管理',
    shanchuqueren: '删除确认',
    shanchuqueren_wenan: {
      info: '确定删除 {name} 吗？删除后无法恢复',
    },
    bianjizhibao: '编辑指标',
    xinjianzhibao: '新建指标',
    zhibiaomingcheng: '指标名称',
    suoshufenlei: '所属分类',
    zhibiaoshuxing: '指标属性',
    huobi: '货币',
    shuzi: '数字',
    wenben: '文本',
    wenjian: '文件',
    tianjiawenjianhou_biaoqian: '添加文件后将自动默认标签',
    morenwenjianbiaoqian: '默认文件标签',
    xuanzewenjianbiaoqian: '请选择文件标签',
    morenzhibiao: '默认使用',
    xuanzefenlei: '请选择所属分类',
    shuruzhibiao: '请输入指标名称',
    zifuxianzhi50: '请勿超出50个字符',
    bukechongfu: '指标名称不可重复',
    morenqiyongzhibiao: '新建后默认启用该指标',
    mingchengchongfu_fenlei: '分类名称重复',
    zhibiaofenleibukeikong: '指标分类不可为空',
  },
  // 投资流程
  investmentProcess: {
    peizhishuoming: '配置说明',
    peizhishuomingtishi_1: '流程调整将影响所有进行中(未进入投后或放弃的)的项目',
    peizhishuomingtishi_add: '新增：该阶段将增加对应内容；',
    peizhishuomingtishi_edit: '修改：该阶段对应内容将被修改（内容及是否必要），其中任务 的完成状态不会变化；',
    peizhishuomingtishi_del: '删除：该阶段将删除对应内容（已有审批、投票仅解除关联关系，不会删除）；',
    peizhishuomingtishi_biyao: '是否必要：本阶段必须完成的内容，未完成则无法变更阶段。',
    shanchujieduan_wufa: '无法删除阶段',
    jinrutouhou: '进入投后',
    fangqixiangmu: '放弃项目',
    mingchengchongfu_liucheng: '流程名称重复',
    tianxieliuchengmingcheng: '请填写流程名称',
    baoliuzidingyijieduan: '至少保留一个自定义的阶段',
    shenpi: '审批',
    shenpi_miaoshu: '关联飞书审批定义至本阶段，必要审批未通过时则不能推进阶段',
    biyao: '必要',
    yiguanliandeshenpi: '以下为"审批管理"中已经关联的飞书审批',
    qingxuanzeshenpi: '请选择审批',
    tianjiashenpi: '添加审批',
    jieduannxinxi: '阶段信息',
    jieduanxinxi_miaoshu: '本阶段需要填写的信息，必要字段未填写时则不能推进阶段',
    renwu: '任务',
    renwu_miaoshu: '本阶段需要完成的任务，必要任务未完成时则不能推进阶段',
    tianjiarenwu: '添加任务',
    bianjirenwu: '编辑任务',
    renwubiaoti: '任务标题',
    shururenwubiaoti: '请输入任务标题',
    renwushanchuqueren: '以下进行中项目"{name}"任务已完成，是否继续删除？（确认并保存配置后所有进行中项目将删除该待办任务）',
    toupiao: '投票',
    toupiao_miaoshu: '在本阶段发起投票并查看结果',
    toupiaoxuanze_miaoshu: '必须发起投票，且所有投票全部完成后可推进阶段',
    toupiaotuijin_miaoshu: '未发起投票时，或已发起的投票还未完成(所有参与人均已投票)，则无法推进阶段',
  },
  // 标签页管理
  tabsManage: {
    zidingyibianqian_weitianjia: '尚未添加自定义标签',
    lijitianjia: '立即添加',
    wenndangquanxian_shuoming: '建议将文档权限设置为“组织内可阅读”',
    xiangmugaikuang: '项目概况',
    touziliucheng: '投资流程',
    guanjianzhibiao: '关键指标',
    touzijihua: '投资计划',
    genjinjilu: '跟进记录',
    wendangziliao: '文档资料',
    gongkaixinxi: '公开信息',
    shanchuqueren: '删除确认',
    shanchuqueren_wenan: '确定删除【{name}】吗?',
    kaiqiyigebiaoqianye: '至少应开启一个标签页',
    gongsixinxi: '公司信息',
  },
  // 指标库 - old
  targetLib: {
    zhibiaoku: '指标库',
    zidingyifenlei: '自定义分类',
    quanbu: '全部',
    caiwu: '财务',
  },
}