const DATA_TYPE_MAP = {
  'beforeProject':    1,
  'investedCompany':  2,
  'fund':             3,
  'lpInvestor':       4,
  'beforeCoin':       5,
  'afterCoin':        6,
  'beforeFof':        7,
  'afterFof':         8,
}

const RECORD_TYPE = {
  '1':'VC/PE',
  '2':'企业风险投资',
  '3':'市场化母基金',
  '4':'政府引导基金',
  '5':'其他类型'
}
export function getRecordType(type){
  if(!type) return
  let val = JSON.parse(type)
  return RECORD_TYPE[val];
}
// businessCode和数据类型dataType对应值，后端接口使用。ugly
export function getDataTypeByBusinessCode(businessCode){
  return DATA_TYPE_MAP[businessCode];
}

// 根据dataType反转查询businessCode，后端接口使用。ugly
export function getBusinessCodeByDataType(dataType){
  for(let i in DATA_TYPE_MAP){
    if(DATA_TYPE_MAP[i] == dataType){
      return i;
    }
  }
}

//根据当前应用的code 获取对应的大类(文件标签 获取时使用)
export function getBigTypeByCode(bCode){
  switch (bCode) {
    case 'fund':
    case 'lpInvestor':
      return bCode
    default:
      return 'project'
  }
}

// 