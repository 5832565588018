<template>
  <div class="circle-main-box" :style="`transform: scale(${scale})`">
    <div :style="[{ width: size + 'px', height: size + 'px' }]">
      <svg :width="size" :height="size">
        <circle
          :r="radius"
          :cx="cx"
          :cy="cy"
          fill="transparent"
          stroke="#EEEEEE"
          :stroke-width="strokeWidth"
        />
        <circle
          class="progress"
          :r="radius"
          :cx="cx"
          :cy="cy"
          fill="transparent"
          :stroke="color"
          :stroke-width="strokeWidth"
          stroke-linecap="round"
          :stroke-dasharray="circumference"
          :stroke-dashoffset="progress"
        />
      </svg>
      <span v-if="type === 'center'" class="text" :style="{ 'font-size': size * 0.3 + 'px' }">{{ percentage }}</span>
    </div>
    <span v-if="type === 'left'" class="text text--left">{{ percentage }}</span>
  </div>
</template>

<script>
export default {
  name: 'CircleProgress',
  props: {
    // 进度值
    value: { type: Number, default: 0 },
    // 尺寸
    size: { type: Number, default: 120 },
    // 边框粗细
    strokeWidth: { type: Number, default: 10 },
    // 进度条颜色
    color: { type: String, default: 'rgba(153,202,251,1)' },
    // 动画执行时间
    duration: { type: Number, default: 1000 },
    // 缩放
    scale: { type: Number, default: 1 },
    type: {
      type: String,
      default: 'center',
    },
  },
  computed: {
    percentage() {
      return `${Number((this.value * 100).toFixed(2))}%`
    },
    // 圆心x轴坐标
    cx() {
      return this.size / 2
    }, // 圆心y轴坐标
    cy() {
      return this.size / 2
    }, // 半径
    radius() {
      return (this.size - this.strokeWidth) / 2
    }, // 圆周长
    circumference() {
      return 2 * Math.PI * this.radius
    }, // 进度长度
    progress() {
      return (1 - this.value) * this.circumference
    },
  },
}
</script>

<style lang="scss" scoped>
.circle-main-box {
  position: relative;
  display: block;
  margin: 0 auto;
  transform-origin: center;
}
.text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 30px;
  color: #333;
  user-select: none;
  user-select: none;
  &.text--left {
    transform: translate(-100%, -50%);
    left: 0;
    margin-left: -16px;
    font-size: 24px;
  }
}
.progress {
  transform: rotate(-90deg);
  transform-origin: center;
}
</style>
