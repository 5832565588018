export default {
  jiazaiwanbi: '全部数据加载完毕',
  jijinguimo: '基金规模',
  sougongsi: '搜公司 查项目',
  soujijin: '搜基金',
  soutouzizhe: '搜索投资者简称、全称',
  ruoweigeren: '若投资者为个人，请直接点击',
  touzizhe_empty: '没有找到您查找的投资者主体，您也可以根据输入的内容创建投资者主体全称',
  xiangmu_placeholder: '请输入您想查找的公司，或根据输入的内容创建主体公司',
  xiangmu_empty: '没有找到您查找的公司，您也可以根据输入的内容创建主体公司',
  zuzijiegou: '组织结构',
  zhankai: '展开',
  qingxuanzehangye: '请选择行业',
  qingxuanzelianxiren: '请选择联系人',
  bukexiugai: '不可修改',
  qingxuanze_rongzilunci: '请选择融资轮次',
  tianjiaxuanxiang: '添加选项',
  sousuowendang: '搜索文档',
  xinjiandianzibiaoge: '新建电子表格',
  wendangbiaoti_placeholder: '请输入文档标题',
  biaogebiaoti_placeholder: '请输入表格标题',
  duoweibiaoge_placeholder: '请输入多维表格标题',
  caozuoquanxian: '您没有操作权限',
  wenjiandaxiao_limit: '文件大小不能超过 50M',
  shangchuanzhong: '上传中...',
  bukexiugai: '不可修改',
  weipipei: '未匹配-{tabName}'
}