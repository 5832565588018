import { ajax } from '@/utils/request'

// 查询全部人员

// 列表人员筛选需要展示所有人（包括离职人员）
export const fetchFindAllUserDetail = (name) => {
  return ajax({
    url: '/user/findAllUserDetails',
    method: 'post',
    data: {
      name,
    },
  })
}


// 管理员权限-获取列表 获取当前用户管理内的全部人员
export async function fetchValidUsers(extra) {
  const result = await ajax({
    method: 'post',
    url: '/user/findValidUsers',
    ...extra
  }, [], []);
  return result;
}
// 获取所有角色列表
export async function fetchRoleList() {
  const result = await ajax({
    method: 'post',
    url: '/role/getAllRoles',
  }, [], []);
  return result;
}

// 创建角色
export async function createRole(data) {
  const result = await ajax({
    method: 'post',
    url: '/role/create',
    data
  }, [], []);
  return result;
}
// 编辑角色
export async function updateRole(data) {
  const result = await ajax({
    method: 'post',
    url: '/role/update',
    data,
  }, [], []);
  return result;
}
// 删除角色
export async function deleteRole(data) {
  const result = await ajax({
    method: 'post',
    url: '/role/delete',
    data,
  });
  return result;
}
// 查询角色权限
export async function fetchRolePermission(id) {
  const result = await ajax({
    method: 'post',
    url: `/role/${id}`,
  }, [], []);
  return result;
}


// 管理员权限-查询全部部门用户数据
export async function fetchUnOfficialUser(name = '', deptId = 0) {
  const result = await ajax({
    method: 'post',
    url: `/user/selectUnActiveUser?name=${name}&deptId=${deptId}`,
  }, [], []);
  return result;
}

// 管理员权限-批量用户权限
export async function changeAdminList(extra) {
  const result = await ajax({
    method: 'post',
    url: `/user/batchUpdateRole`,
    ...extra
  },{
    getContext: true,
  });
  return result;
}

// 管理员权限-查询本租户信息
export async function getTenantInfo() {
  const result = await ajax({
    method: 'post',
    url: `/tenant/getTenantInfo`,
  }, [], []);
  return result;
}

// 角色增加用户
export async function addRolelUser() {
  const result = await ajax({
    method: 'post',
    url: `/role/addUser`,
  }, [], []);
  return result;
}
// 角色移除用户
export async function removeRolelUser() {
  const result = await ajax({
    method: 'post',
    url: `/role/removeUser`,
  }, [], []);
  return result;
}
