<template>
  <teleport to='body'>
    <div class="img-preview">
      <a-image-preview
        v-if="imgUrls.length == 1"
        v-model:visible="showPreview"
        :src="imgUrls[0]"
      />
      <a-image-preview-group
        v-else-if="imgUrls.length > 1"
        v-model:visible="showPreview"
        v-model:current="localIndex"
        :srcList="imgUrls"
      />
    </div>
  </teleport>
</template>
<script>
export default {
  name: 'imgPreview',
  props:{
    imgUrls: Array,
    currIndex: Number
  },
  data(){
    return {
      showPreview: false,
      localIndex:  -1
    }
  },
  watch:{
    showPreview:{
      handler(v){
        if(!v){
          this.$emit('cancel', v)
        }
      }
    },
    currIndex:{
      immediate: true,
      handler(val){
        this.localIndex = val;
        if(this.imgUrls?.length > 0 && val > -1){
          this.showPreview = true;
        }
      }
    },
    imgUrls:{
      immediate: true,
      deep: true,
      handler(v){
        if(v && v.length > 0 && this.localIndex > -1){
          this.showPreview = true;
        }
      }
    }
  },
  computed:{
    currImgUrl(){
      if(this.imgUrls && this.imgUrls[this.currIndex]){
        return this.imgUrls[this.currIndex];
      }
    }
  },
}
</script>
<style lang="scss">
  .img-preview-dialog{
    .arco-modal-header{
      display: flex;
      .arco-modal-title{
        flex: 1;
        justify-content: center !important;
      }
      .header-area{
        display: flex;
        .btn-area{
          display: flex;
          .icon-arrow-left{
            padding: 2px;
            line-height: 20px;
            margin: 0 20px;
            cursor: pointer;
            &.disable{
              color: gray;
              cursor: default;
              &:hover{
                background: white;
              }
            }
            &:hover{
              background: rgb(243, 243, 243);
              border-radius: 4px;
            }
          }
          .reverse{
            transform: rotateY(180deg);
          }
        }
      }
      .arco-icon-hover{
        font-size: 16px;
      }
    }
  }
</style>