import { defineStore } from 'pinia'
import { reactive } from 'vue'

type StoreFile = UploadFile & { sourceKey?: string }

const initState = (): { fileList: StoreFile[] } =>
  reactive({
    fileList: [] as StoreFile[],
  })

export const useUploadFileList = defineStore({
  id: 'uploadFileList',
  state: () => {
    return initState()
  },
  actions: {
    filterFileByCondition(conditionFn: (file: StoreFile, idx: number) => boolean) {
      return this.fileList.filter(conditionFn)
    },
    addFile(file: UploadFile, sourceKey?: string) {
      this.fileList.push({
        ...file,
        sourceKey,
      })
    },
    updateFileProgress(file: UploadFile, progress: number) {
      this.fileList = this.fileList.map((item) => {
        if (item.uuid === file.uuid) {
          return { ...item, progress }
        }
        return item
      })
    },
    updateFileState(file: UploadFile, state: FileState) {
      const index = this.fileList.findIndex((item) => item.uuid === file.uuid)
      if (index !== -1) {
        this.fileList[index].state = state
      }
    },
    removeFile(file: UploadFile) {
      const index = this.fileList.findIndex((item) => item.uuid === file.uuid)
      if (index !== -1) {
        this.fileList.splice(index, 1)
      }
    },
    getFileByUuid(uuid: string) {
      return this.fileList.find((item) => item.uuid === uuid)
    },
    updateFileByUuid(uuid: string, file: File) {
      this.fileList = this.fileList.map((f) => {
        if (uuid === f.uuid) {
          return {
            ...f,
            ...file,
          }
        } else {
          return f
        }
      })
    },
    clearFileStore(sourceKey: string) {
      this.fileList = this.fileList.filter((f) => f.sourceKey !== sourceKey)
    },
  },
})
