// 准备翻译的语言环境信息
export default {
  en: {
    message: {
      hello: '你好'
    },
    test:{
      msg: '这是个测试---en',
      param_msg: '确定删除【{p1}】吗？----en',
      func:{
        sentence: '如果没有找到需要的指标,请在后台{markA}中创建',
        name: '指标库'
      },
      name: 'name'
    },
  }
}