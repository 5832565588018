<template>
  <render />
</template>

<script lang="tsx" setup>
import AutoTooltip from '../AutoTooltip.vue'

const props = defineProps<{ option: any, labelKey?: string, renderFn?: (...opts: any[]) => JSX.Element }>()

const render = () => {
  const { option, labelKey, renderFn } = props

  if (typeof renderFn === 'function') {
    return renderFn(option)
  } else if(labelKey) {
    return <AutoTooltip content={option[labelKey]}>
      { option[labelKey] }
    </AutoTooltip>
  }
}
</script>