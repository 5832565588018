const list=[
    {
        emojiType: "THUMBSUP",
        value: "https://sf3-ttcdn-tos.pstatp.com/obj/lark-reaction-cn/emoji_thumbsup_v2.png?height=96&lazyload=true&width=28",
        text: '赞',
    },
    {
        emojiType: "OK",
        value: "https://sf3-ttcdn-tos.pstatp.com/obj/lark-reaction-cn/emoji_ok_v2.png?height=96&lazyload=true&width=104",
        text: 'OK',
    },
    {
        emojiType: "Yes",
        value: "https://sf3-ttcdn-tos.pstatp.com/obj/lark-reaction-cn/emoji_yes_v2.png?height=96&lazyload=true&width=104",
        text: 'Yes',
    },
    {
        emojiType: "JIAYI",
        value: "https://sf3-ttcdn-tos.pstatp.com/obj/lark-reaction-cn/emoji_plusone.png?height=96&lazyload=true&width=28",
        text: '+1',
    },
    {
        emojiType: "Get",
        value: "https://sf3-ttcdn-tos.pstatp.com/obj/lark-reaction-cn/emoji_get_v3.png?height=96&lazyload=true&width=28",
        text: '了解',
    },
    {
        emojiType: "DONE",
        value: "https://sf3-ttcdn-tos.pstatp.com/obj/lark-reaction-cn/emoji_done.png?height=96&lazyload=true&width=28",
        text: '完成',
    },
    {
        emojiType: "FINGERHEART",
        value: "https://sf3-ttcdn-tos.pstatp.com/obj/lark-reaction-cn/emoji_fingerheart_v2.png?height=96&lazyload=true&width=28",
        text: '比心',
    },
    {
        emojiType: "PARTY",
        value: "https://sf3-ttcdn-tos.pstatp.com/obj/lark-reaction-cn/emoji_party.png?height=96&lazyload=true&width=28",
        text: '撒花',
    },
    {
        emojiType: "YouAreTheBest",
        value: "https://sf3-ttcdn-tos.pstatp.com/obj/lark-reaction-cn/emoji_youarethebest.png?height=96&lazyload=true&width=28",
        text: '送你小红花',
    },
    {
        emojiType: "FISTBUMP",
        value: "https://sf3-ttcdn-tos.pstatp.com/obj/lark-reaction-cn/emoji_fistbump_v2.png?height=96&lazyload=true&width=28",
        text: '碰拳',
    },
    {
        emojiType: "ThumbsDown",
        value: "https://sf3-ttcdn-tos.pstatp.com/obj/lark-reaction-cn/emoji_thumbsdown_v2.png?height=96&lazyload=true&width=28",
        text: '踩',
    },
    {
        emojiType: "CrossMark",
        value: "https://sf3-ttcdn-tos.pstatp.com/obj/lark-reaction-cn/emoji_crossmark.png?height=96&lazyload=true&width=28",
        text: '叉号',
    },
]
export default list
