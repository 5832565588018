<template>
  <i
    v-if="isFontClass"
    :class="`${className} ${$slots.default ? 'icon-wrapper-flex' : ''}`"
    :style="style"
  >
    <span class="text" v-if="$slots.default">
      <slot />
    </span>
  </i>
  <svg v-else class="icon" aria-hidden="true" style="style">
    <use :xlink:href="icon" />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    name: {
      type: String,
      required: true,
    },
    useSymbol: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: '',
    },
    size: {
      type: Number,
      default: null,
    },
  },
  computed: {
    icon() {
      return this.useSymbol ? `#icon-${this.name}` : `icon-${this.name}`
    },
    className() {
      return `iconfont ${this.icon}`
    },
    isFontClass() {
      return !this.useSymbol
    },
    style() {
      return {
        color: this.color,
        currentColor: this.color,
        fontSize: this.size ? `${this.size}px` : '',
      }
    },
  },
})
</script>

<style scoped lang="scss">
.icon-wrapper-flex {
  display: flex;
  align-items: center;
}
.text {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  vertical-align: baseline;
  margin-left: 4px;
}
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>
