import {ajax} from '@/utils/request';

// 根据id查询用户
export async function getUserById(extra) {
  const result = await ajax({
    method: 'get',
    url: `/user/getById?id=${extra}`,
  }, [], []);
  return result;
}
// 查询全部部门用户数据
export async function findOrganization(parentId = 0, type = '') {
  const result = await ajax({
    method: 'post',
    url: `/user/findOrganization?parentId=${parentId}&type=${type}`,
  }, [], []);
  return result;
}
// 根据父Id查询全部部门用户数据
export async function findOrganizationByDeptId(extra) {
  const result = await ajax({
    method: 'get',
    url: `/user/findOrganizationByDeptId?parentId=${extra}`,
  }, [], []);
  return result;
}
// 模糊查询所有用户
export async function findUsers(extra) {
  const result = await ajax({
    method: 'post',
    url: '/user/findUsers',
    ...extra,
  }, [], []);
  return result;
}
// 模糊查询所有用户 免登录
export async function findUsersLoginSkip(extra) {
  const result = await ajax({
    method: 'post',
    url: '/user/findUsersLoginSkip',
    ...extra,
  }, [], []);
  return result;
}
// 列表人员筛选需要展示所有人（包括离职人员）
export async function fetchFindAllUser(extra) {
  return ajax({
    url: '/user/findAllUser',
    method: 'post',
    ...extra,
  })
}
// 获取应用信息（主要用来获取每个应用的appId）
export async function quotaQueryAppId() {
  const result = await ajax({
    method: 'post',
    url: '/quota/queryAppId',
  }, [], []);
  return result;
}