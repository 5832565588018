import app_common from './app/common/common'
import before from './app/before.js'
import fundAnalyze from './app/fundAnalyze.js'
import dataCollect from './app/dataCollect.js'
import helper from './app/helper.js'
import sysManage from './app/sysManage.js'
import publicSentiment from './app/publicSentiment.js'
import { allTabs } from '@/subLang/zh/app/common/detail.js'

import h5PublicSentiment from './h5/publicSentiment.js'
import h5Common from './h5/common.js'
import list from './h5/list.js'

import lang_0930 from './0930/index.js';

import _ from 'lodash';

// 准备翻译的语言环境信息
export default {
  zh: {
    test:{
      msg: '这是个测试',
      param_msg: '确定删除【{p1}】吗？',
      func:{
        sentence: '如果没有找到需要的指标,请在后台{markA}中创建',
        name: '指标库'
      },
      name: '名字',
    },
    // 通用平台，主要是web内容
    plat_c:{
      app_c: app_common,
      before: before,
      allTabs: _.merge(allTabs, lang_0930.allTabs),  //所有tabs
      fundAnalyze: fundAnalyze, // 基金分析
      dataCollect: dataCollect, // 数据收集
      helper: helper, // 投资云助手
      sysManage: _.merge(sysManage, lang_0930.sysManage), // 后台管理
      publicSentiment: publicSentiment, //舆情分析
    },
    //H5平台
    plat_h5: {
      publicSentiment: h5PublicSentiment, // 舆情监测
      list: _.merge(list, lang_0930.h5List),
      h5_c: h5Common,
    },
  }
}