import {ajax} from '@/utils/request';

// 新增跟进记录
export async function followRecordInsert(extra) {
  const result = await ajax({
    method: 'post',
    url: '/followRecord/insert',
    headers:{
      "Content-Type": "multipart/form-data"
    },
    ...extra,
  },);
  return result;
}
export async function followRecordUpdate(extra) {
  const result = await ajax({
    method: 'post',
    url: '/followRecord/update',
    ...extra,
  }, [], []);
  return result;
}
export async function followRecordDelete(extra) {
  const result = await ajax({
    method: 'post',
    url: '/followRecord/delete',
    ...extra,
  }, [], []);
  return result;
}
export async function followRecordList(extra) {
  const result = await ajax({
    method: 'post',
    url: '/followRecord/queryByDataId',
    ...extra,
  }, [], []);
  return result;
}
export async function followCommentInsert(extra) {
  const result = await ajax({
    method: 'post',
    url: '/followComment/insert',
    ...extra,
  }, [], []);
  return result;
}
export async function followCommentUpdate(extra) {
  const result = await ajax({
    method: 'post',
    url: '/followComment/update',
    ...extra,
  }, [], []);
  return result;
}
export async function followCommentDelete(extra) {
  const result = await ajax({
    method: 'post',
    url: '/followComment/delete',
    ...extra,
  }, [], []);
  return result;
}
export async function followCommentList(extra) {
  const result = await ajax({
    method: 'post',
    url: '/followComment/queryList',
    ...extra,
  }, [], []);
  return result;
}

// 跟进记录台账
export async function followRecordQueryList(extra) {
  const result = await ajax({
    method: 'post',
    url: '/followRecord/queryList',
    ...extra,
  }, [], []);
  return result;
}

// 跟进记录台账导出
export async function followRecordExport(extra) {
  const result = await ajax({
    method: 'post',
    url: '/followRecord/export',
    ...extra,
  }, [], []);
  return result;
}
// 跟进记录NativeContent为空的数据
export async function handlerNativeContentData(extra) {
  const result = await ajax({
    method: 'post',
    url: '/followRecord/handlerNativeContentData',
    ...extra,
  }, [], []);
  return result;
}
// 更新跟进记录NativeContent为空的数据
export async function updateNativeContentData(extra) {
  const result = await ajax({
    method: 'post',
    url: '/followRecord/updateNativeContentData',
    ...extra,
  }, [], []);
  return result;
}

// 跟进记录选择关联项目列表
export async function followRecordSearchData(extra) {
  const result = await ajax({
    method: 'post',
    url: '/followRecord/searchData',
    ...extra,
  }, [], []);
  return result;
}
