
import PromiseWorker from "promise-worker"
import Worker from "@/workers/calcTagsWorker.ts?worker&inline"

export class CalcWorker {
  private _calcWorkerInstance

  constructor() {
    const worker = new Worker()
    this._calcWorkerInstance = new PromiseWorker(worker)
  }

  getWorkerInstance() {
    if (this._calcWorkerInstance) {
      return this._calcWorkerInstance
    } else {
      const worker = new Worker()
      return this._calcWorkerInstance = new PromiseWorker(worker)
    }
  }
}